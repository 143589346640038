
import {actionSheetController, IonButton} from "@ionic/vue"
import {defineComponent} from "vue"
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome"
import {ResponseInfo} from "@/shared/helpers"

export default defineComponent({
  components: {FontAwesomeIcon, IonButton},
  emits: ["confirmed"],
  props: {
    buttonDisabled: {
      required: false,
      default: false,
      type: Boolean,
    },
    buttonSize: {
      required: false,
      default: "default",
    },
    iconColor: {
      required: true,
      type: String,
    },
    iconType: {
      required: true,
      type: String,
    },
    alertHeaderText: {
      required: true,
      type: String,
    },
    onConfirm: {
      required: true,
      type: Function,
    },
  },
  methods: {
    async trigger() {
      if (!(await this.confirm())) return
      const response: ResponseInfo = await this.onConfirm()
      this.$emit("confirmed", response)
    },
    async confirm() {
      const actionSheet = await actionSheetController.create({
        header: this.alertHeaderText,
        buttons: [
          {
            text: this.$t("button.confirm"),
            role: "destructive",
          },
          {
            text: this.$t("button.cancel"),
            role: "cancel",
          },
        ],
      })
      await actionSheet.present()
      const result = await actionSheet.onDidDismiss()
      return result.role === "destructive"
    },
  },
})
