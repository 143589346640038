
import {IonButton, IonImg, IonItem, IonLabel, IonSpinner, toastController} from "@ionic/vue"
import {defineComponent, PropType} from "vue"
import {Camera, CameraDirection, CameraResultType, CameraSource} from "@capacitor/camera"
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome"
import {useImageStore} from "@/stores/image"
import {useSettingStore} from "@/stores/setting"
import {Image} from "@/gql/types"
import {convertGraphQlErrorToResponseInfo, ResponseInfo} from "@/shared/helpers"

export default defineComponent({
  components: {IonButton, IonImg, IonItem, IonLabel, IonSpinner, FontAwesomeIcon},
  emits: ["image-changed"],
  props: {
    image: {
      required: false,
      default: null,
      type: Object as PropType<Image>,
    },
    imageId: {
      required: false,
      default: null,
      type: String,
    },
  },
  data() {
    return {
      imageSource: "",
      isLoading: false,
    }
  },
  methods: {
    async selectAndUploadImage() {
      try {
        if (this.settingStore.isNative) await Camera.requestPermissions({permissions: ["camera", "photos"]})

        const image = await Camera.getPhoto({
          quality: 85,
          allowEditing: false,
          saveToGallery: false,
          correctOrientation: true,
          direction: CameraDirection.Rear,
          resultType: CameraResultType.DataUrl,
          source: this.settingStore.isAndroid ? CameraSource.Camera : CameraSource.Prompt,
        })

        this.isLoading = true
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const uploadedImage = await this.imageStore.uploadImage(image.dataUrl!)
        this.imageSource = uploadedImage.url
        this.$emit("image-changed", uploadedImage)
        this.isLoading = false
      } catch (e) {
        this.isLoading = false
        console.error("Could not get photo", e)
        await this.presentResponseInfoToast(convertGraphQlErrorToResponseInfo(e))
      }
    },
    deleteCurrentImage() {
      this.imageSource = ""
      this.$emit("image-changed", null)
    },
    async presentResponseInfoToast(responseInfo: ResponseInfo) {
      const toast = await toastController.create({
        message: `${this.$t(responseInfo.messageKey)}!`,
        duration: 5560,
        position: "top",
        buttons: [
          {
            text: this.$t("button.ok"),
            role: "cancel",
          },
        ],
        color: responseInfo.isError ? "danger" : "",
      })
      await toast.present()
    },
  },
  watch: {
    image: {
      immediate: true,
      async handler(image) {
        if (image) this.imageSource = image.url
      },
    },
    imageId: {
      immediate: true,
      async handler(imageId) {
        if (imageId) this.imageSource = (await this.imageStore.getImage(imageId)).url
      },
    },
  },
  setup() {
    return {
      imageStore: useImageStore(),
      settingStore: useSettingStore(),
    }
  },
})
