import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_input = _resolveComponent("ion-input")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_list = _resolveComponent("ion-list")!
  const _component_EditAction = _resolveComponent("EditAction")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_ion_button, {
      size: _ctx.buttonSize,
      color: "success",
      onClick: _ctx.openModal
    }, {
      default: _withCtx(() => [
        _createVNode(_component_font_awesome_icon, { icon: "fa-square-plus" })
      ]),
      _: 1
    }, 8, ["size", "onClick"]),
    _createVNode(_component_EditAction, {
      "modal-active": _ctx.modalActive,
      "on-confirm": _ctx.confirmInventoryCreate,
      "to-render": _ctx.newInventory,
      "top-bar-title": _ctx.$t('action.create'),
      onEditConfirmed: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('created-inventory', $event))),
      onEditCanceled: _ctx.closeModal
    }, {
      default: _withCtx(({active, patchActive}) => [
        _createVNode(_component_ion_list, null, {
          default: _withCtx(() => [
            _createVNode(_component_ion_item, null, {
              default: _withCtx(() => [
                _createVNode(_component_ion_label, { position: "stacked" }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('general.name')), 1)
                  ]),
                  _: 1
                }),
                _createVNode(_component_ion_input, {
                  value: active.name,
                  onIonChange: ($event: any) => (patchActive({name: $event.detail.value}))
                }, null, 8, ["value", "onIonChange"])
              ]),
              _: 2
            }, 1024)
          ]),
          _: 2
        }, 1024)
      ]),
      _: 1
    }, 8, ["modal-active", "on-confirm", "to-render", "top-bar-title", "onEditCanceled"])
  ], 64))
}