import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6efb360d"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "label-text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BarcodePreview = _resolveComponent("BarcodePreview")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_toggle = _resolveComponent("ion-toggle")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_input = _resolveComponent("ion-input")!
  const _component_ion_select_option = _resolveComponent("ion-select-option")!
  const _component_ion_select = _resolveComponent("ion-select")!
  const _component_ion_list = _resolveComponent("ion-list")!
  const _component_ScannerContainer = _resolveComponent("ScannerContainer")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_ion_list, { lines: "full" }, {
      default: _withCtx(() => [
        _createVNode(_component_ion_item, null, {
          default: _withCtx(() => [
            _createVNode(_component_ion_label, { class: "label" }, {
              default: _withCtx(() => [
                _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.$t('entity.barcode.name')), 1),
                (_ctx.rendering.content)
                  ? (_openBlock(), _createBlock(_component_BarcodePreview, {
                      key: 0,
                      barcode: _ctx.rendering,
                      onIsValid: _ctx.handlePreviewChange
                    }, null, 8, ["barcode", "onIsValid"]))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            }),
            (_ctx.requiresConfirmation)
              ? (_openBlock(), _createBlock(_component_ion_button, {
                  key: 0,
                  slot: "end",
                  color: "success",
                  disabled: !_ctx.isValidBarcodeEntry,
                  onClick: _ctx.confirmBarcode
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_font_awesome_icon, { icon: "fa-check" })
                  ]),
                  _: 1
                }, 8, ["disabled", "onClick"]))
              : _createCommentVNode("", true),
            (_ctx.settingStore.supportsScanning)
              ? (_openBlock(), _createBlock(_component_ion_button, {
                  key: 1,
                  slot: "end",
                  disabled: !_ctx.barcodeActive,
                  color: "tertiary",
                  onClick: _ctx.scan
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_font_awesome_icon, { icon: "fa-barcode" })
                  ]),
                  _: 1
                }, 8, ["disabled", "onClick"]))
              : _createCommentVNode("", true),
            _createVNode(_component_ion_toggle, {
              slot: "end",
              checked: _ctx.barcodeActive,
              onIonChange: _ctx.toggleBarcode
            }, null, 8, ["checked", "onIonChange"])
          ]),
          _: 1
        }),
        _createVNode(_component_ion_item, {
          disabled: !_ctx.barcodeActive
        }, {
          default: _withCtx(() => [
            _createVNode(_component_ion_label, { position: "stacked" }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('entity.barcode.content')), 1)
              ]),
              _: 1
            }),
            _createVNode(_component_ion_input, {
              value: _ctx.rendering.content,
              onIonChange: _cache[0] || (_cache[0] = ($event: any) => (_ctx.rendering.content = $event.detail.value)),
              onIonInput: _cache[1] || (_cache[1] = ($event: any) => (_ctx.requiresConfirmation = true))
            }, null, 8, ["value"])
          ]),
          _: 1
        }, 8, ["disabled"]),
        _createVNode(_component_ion_item, {
          disabled: !_ctx.barcodeActive
        }, {
          default: _withCtx(() => [
            _createVNode(_component_ion_label, { position: "stacked" }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('entity.barcode.format')), 1)
              ]),
              _: 1
            }),
            _createVNode(_component_ion_select, {
              "cancel-text": _ctx.$t('button.cancel'),
              interface: _ctx.settingStore.selectOptionInterfaceType,
              "ok-text": _ctx.$t('button.ok'),
              value: _ctx.rendering.format,
              onIonChange: _cache[2] || (_cache[2] = ($event: any) => (_ctx.rendering.format = $event.detail.value))
            }, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.barcodeFormats, (format) => {
                  return (_openBlock(), _createBlock(_component_ion_select_option, {
                    key: format,
                    value: format
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(format.replace("_", " ")), 1)
                    ]),
                    _: 2
                  }, 1032, ["value"]))
                }), 128))
              ]),
              _: 1
            }, 8, ["cancel-text", "interface", "ok-text", "value"])
          ]),
          _: 1
        }, 8, ["disabled"])
      ]),
      _: 1
    }),
    (_ctx.settingStore.supportsScanning)
      ? (_openBlock(), _createBlock(_component_ScannerContainer, {
          key: 0,
          ref: "scannerContainer",
          onScannedBarcode: _ctx.handleScannedBarcode
        }, null, 8, ["onScannedBarcode"]))
      : _createCommentVNode("", true)
  ], 64))
}