
import {IonLabel, IonSelect, IonSelectOption} from "@ionic/vue"
import {defineComponent} from "vue"
import {useSettingStore} from "@/stores/setting"
import {languageNameMapping} from "@/shared/i18n/translations"

export default defineComponent({
  components: {IonLabel, IonSelect, IonSelectOption},
  props: {
    stackedLabel: {
      required: false,
      default: false,
      type: Boolean,
    },
  },
  setup() {
    return {
      settingStore: useSettingStore(),
      languageNameMapping: languageNameMapping,
    }
  },
})
