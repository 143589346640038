
import {defineComponent} from "vue"
import {useAuthenticationStore} from "@/stores/authentication"

export default defineComponent({
  data() {
    return {
      pricingTableId: process.env.VUE_APP_STRIPE_PRICING_TABLE_ID,
      publishableKey: process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY,
    }
  },
  mounted() {
    const pricingTableScript = document.createElement("script")
    pricingTableScript.setAttribute("src", "https://js.stripe.com/v3/pricing-table.js")
    document.head.appendChild(pricingTableScript)
  },
  setup() {
    return {
      authStore: useAuthenticationStore(),
    }
  },
})
