import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createBlock as _createBlock, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-eebd8cdc"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  id: "filter-container",
  slot: "content"
}
const _hoisted_2 = { key: 0 }
const _hoisted_3 = {
  id: "sort-container",
  slot: "content"
}
const _hoisted_4 = { id: "results-text" }
const _hoisted_5 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_refresher_content = _resolveComponent("ion-refresher-content")!
  const _component_ion_refresher = _resolveComponent("ion-refresher")!
  const _component_ion_searchbar = _resolveComponent("ion-searchbar")!
  const _component_ion_col = _resolveComponent("ion-col")!
  const _component_ion_row = _resolveComponent("ion-row")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_CategorySelect = _resolveComponent("CategorySelect")!
  const _component_LocationSelect = _resolveComponent("LocationSelect")!
  const _component_ion_accordion = _resolveComponent("ion-accordion")!
  const _component_SortSelect = _resolveComponent("SortSelect")!
  const _component_ion_accordion_group = _resolveComponent("ion-accordion-group")!
  const _component_ion_skeleton_text = _resolveComponent("ion-skeleton-text")!
  const _component_ion_badge = _resolveComponent("ion-badge")!
  const _component_ion_text = _resolveComponent("ion-text")!
  const _component_ItemTable = _resolveComponent("ItemTable")!
  const _component_ion_grid = _resolveComponent("ion-grid")!
  const _component_SearchFloatingActionButtons = _resolveComponent("SearchFloatingActionButtons")!
  const _component_ion_infinite_scroll_content = _resolveComponent("ion-infinite-scroll-content")!
  const _component_ion_infinite_scroll = _resolveComponent("ion-infinite-scroll")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_ion_refresher, {
      slot: "fixed",
      onIonRefresh: _ctx.handleRefresh
    }, {
      default: _withCtx(() => [
        _createVNode(_component_ion_refresher_content)
      ]),
      _: 1
    }, 8, ["onIonRefresh"]),
    _createVNode(_component_ion_grid, null, {
      default: _withCtx(() => [
        _createVNode(_component_ion_row, { class: "desktop-width-sm" }, {
          default: _withCtx(() => [
            _createVNode(_component_ion_col, null, {
              default: _withCtx(() => [
                _createVNode(_component_ion_searchbar, {
                  modelValue: _ctx.settingStore.persistable.search.query,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.settingStore.persistable.search.query) = $event)),
                  animated: true,
                  debounce: 500,
                  placeholder: _ctx.searchPlaceholder,
                  color: "light",
                  "show-clear-button": "focus"
                }, null, 8, ["modelValue", "placeholder"])
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_component_ion_row, { class: "desktop-width-sm" }, {
          default: _withCtx(() => [
            _createVNode(_component_ion_col, null, {
              default: _withCtx(() => [
                _createVNode(_component_ion_accordion_group, {
                  multiple: true,
                  value: _ctx.filterSortAccordionActive
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_accordion, { value: "filters" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_item, {
                          slot: "header",
                          color: "light"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_ion_label, null, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.$t('search.filters')), 1)
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        }),
                        _createElementVNode("div", _hoisted_1, [
                          _createVNode(_component_CategorySelect, {
                            categories: _ctx.categoryStore.augmentedCategories,
                            "no-side-padding": false,
                            "pre-selected-category": _ctx.settingStore.persistable.search.lastSelection.category,
                            onSelectedCategory: _cache[1] || (_cache[1] = ($event: any) => (_ctx.settingStore.persistable.search.lastSelection.category = $event))
                          }, null, 8, ["categories", "pre-selected-category"]),
                          (_ctx.settingStore.renderMobileLayout)
                            ? (_openBlock(), _createElementBlock("hr", _hoisted_2))
                            : _createCommentVNode("", true),
                          _createVNode(_component_LocationSelect, {
                            locations: _ctx.locationStore.augmentedLocations,
                            "no-side-padding": false,
                            "pre-selected-location": _ctx.settingStore.persistable.search.lastSelection.location,
                            onSelectedLocation: _cache[2] || (_cache[2] = ($event: any) => (_ctx.settingStore.persistable.search.lastSelection.location = $event))
                          }, null, 8, ["locations", "pre-selected-location"])
                        ])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_ion_accordion, { value: "sorting" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_item, {
                          slot: "header",
                          color: "light"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_ion_label, null, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.$t('search.sorting')), 1)
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        }),
                        _createElementVNode("div", _hoisted_3, [
                          _createVNode(_component_SortSelect, { "no-side-padding": false })
                        ])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }, 8, ["value"])
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_component_ion_row, null, {
          default: _withCtx(() => [
            _createVNode(_component_ion_col, null, {
              default: _withCtx(() => [
                _createVNode(_component_ion_text, { id: "results-text-container" }, {
                  default: _withCtx(() => [
                    _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.$t('search.results')), 1),
                    _createVNode(_component_ion_badge, { color: "success" }, {
                      default: _withCtx(() => [
                        (!this.itemStore.isLoading && this.itemStore.count !== undefined)
                          ? (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(this.itemStore.count), 1))
                          : (_openBlock(), _createBlock(_component_ion_skeleton_text, {
                              key: 1,
                              animated: true,
                              style: {"width":"20px"}
                            }))
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_ItemTable, {
                  "is-loading": this.itemStore.isLoading,
                  items: this.itemStore.items,
                  onReload: _ctx.refetchItems
                }, null, 8, ["is-loading", "items", "onReload"])
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_SearchFloatingActionButtons),
    _createVNode(_component_ion_infinite_scroll, { onIonInfinite: _ctx.loadMoreResults }, {
      default: _withCtx(() => [
        _createVNode(_component_ion_infinite_scroll_content)
      ]),
      _: 1
    }, 8, ["onIonInfinite"])
  ], 64))
}