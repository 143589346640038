
import {defineComponent} from "vue"
import WebSubscriptionSelection from "@/components/WebSubscriptionSelection.vue"
import NativeSubscriptionSelection from "@/components/NativeSubscriptionSelection.vue"
import {useSettingStore} from "@/stores/setting"

export default defineComponent({
  components: {WebSubscriptionSelection, NativeSubscriptionSelection},
  setup() {
    return {
      settingStore: useSettingStore(),
    }
  },
})
