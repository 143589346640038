import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-bf5d29da"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ItemNamePopup = _resolveComponent("ItemNamePopup")!
  const _component_ItemImagePopup = _resolveComponent("ItemImagePopup")!
  const _component_ItemNotePopup = _resolveComponent("ItemNotePopup")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_ItemNamePopup, {
      item: _ctx.item,
      class: "name"
    }, null, 8, ["item"]),
    (_ctx.item.image)
      ? (_openBlock(), _createBlock(_component_ItemImagePopup, {
          key: 0,
          item: _ctx.item,
          class: "image"
        }, null, 8, ["item"]))
      : _createCommentVNode("", true),
    (_ctx.item.note)
      ? (_openBlock(), _createBlock(_component_ItemNotePopup, {
          key: 1,
          item: _ctx.item,
          class: "note"
        }, null, 8, ["item"]))
      : _createCommentVNode("", true)
  ]))
}