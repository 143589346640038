
import {IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonItem} from "@ionic/vue"
import {defineComponent} from "vue"
import LanguageSelect from "@/components/LanguageSelect.vue"

export default defineComponent({
  components: {
    LanguageSelect,
    IonCard,
    IonCardHeader,
    IonCardTitle,
    IonCardContent,
    IonItem,
  },
})
