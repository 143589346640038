import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_router_outlet = _resolveComponent("ion-router-outlet")!
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_tab_button = _resolveComponent("ion-tab-button")!
  const _component_ion_tab_bar = _resolveComponent("ion-tab-bar")!
  const _component_ion_tabs = _resolveComponent("ion-tabs")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_tabs, null, {
        default: _withCtx(() => [
          _createVNode(_component_ion_router_outlet),
          _createVNode(_component_ion_tab_bar, { slot: "bottom" }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_tab_button, {
                href: "/search",
                tab: "search"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_font_awesome_icon, {
                    class: "tab-icon",
                    icon: "fa-magnifying-glass",
                    size: "xl"
                  }),
                  _createVNode(_component_ion_label, { class: "tab-label" }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t('general.search')), 1)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_ion_tab_button, {
                href: "/management",
                tab: "management"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_font_awesome_icon, {
                    class: "tab-icon",
                    icon: "fa-list-check",
                    size: "xl"
                  }),
                  _createVNode(_component_ion_label, { class: "tab-label" }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t('general.management')), 1)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_ion_tab_button, {
                href: "/settings",
                tab: "settings"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_font_awesome_icon, {
                    class: "tab-icon",
                    icon: "fa-gear",
                    size: "xl"
                  }),
                  _createVNode(_component_ion_label, { class: "tab-label" }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t('general.settings')), 1)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}