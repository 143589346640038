
import {IonSelect, IonSelectOption} from "@ionic/vue"
import {defineComponent, PropType} from "vue"
import {useSettingStore} from "@/stores/setting"
import {useLocationStore} from "@/stores/location"
import {Location} from "@/gql/types"

export default defineComponent({
  components: {IonSelect, IonSelectOption},
  emits: ["selected-location"],
  props: {
    locations: {
      required: true,
      type: Array as PropType<Array<Location>>,
    },
    preSelectedLocation: {
      required: false,
      default: null,
    },
  },
  data() {
    return {
      selectedLocationId: null as string | null,
    }
  },
  computed: {
    orderedLocations() {
      const locations = this.locations.filter(l => l.name !== "?")
      const lostLocation = this.locations.find(l => l.name === "?")
      if (lostLocation) locations.splice(1, 0, lostLocation)
      return locations
    },
  },
  methods: {
    selectLocation(location: Location) {
      this.$emit("selected-location", location)
    },
    renderLocationName(location: Location) {
      const locationHierarchy = this.locationStore.getLocationHierarchy(location)
      return locationHierarchy.map(l => l.name).join(" → ")
    },
  },
  watch: {
    preSelectedLocation: {
      immediate: true,
      handler(location: Location) {
        this.selectedLocationId = location?.id
      },
    },
    selectedLocationId: {
      handler(locationId) {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        this.selectLocation(this.locations.find(l => l.id === locationId)!)
      },
    },
  },
  setup() {
    return {
      locationStore: useLocationStore(),
      settingStore: useSettingStore(),
    }
  },
})
