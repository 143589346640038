
import {IonContent} from "@ionic/vue"
import {defineComponent} from "vue"

export default defineComponent({
  components: {IonContent},
  props: {
    content: {
      required: true,
      type: String,
    },
  },
})
