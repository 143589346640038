import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, withModifiers as _withModifiers, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0f84ae7a"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  slot: "end",
  class: "button-container"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_textarea = _resolveComponent("ion-textarea")!
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_list = _resolveComponent("ion-list")!
  const _component_ion_popover = _resolveComponent("ion-popover")!

  return (_openBlock(), _createBlock(_component_ion_item, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_label, { position: "stacked" }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('general.name')), 1)
        ]),
        _: 1
      }),
      _createVNode(_component_ion_textarea, {
        ref: "input",
        modelValue: _ctx.inputText,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.inputText) = $event)),
        "auto-grow": true
      }, null, 8, ["modelValue"]),
      _createElementVNode("div", _hoisted_1, [
        (_ctx.suggestions.length > 0)
          ? (_openBlock(), _createBlock(_component_ion_button, {
              key: 0,
              onClick: _withModifiers(_ctx.togglePopover, ["stop"])
            }, {
              default: _withCtx(() => [
                _createVNode(_component_font_awesome_icon, { icon: "fa-clipboard-list" })
              ]),
              _: 1
            }, 8, ["onClick"]))
          : _createCommentVNode("", true)
      ]),
      _createVNode(_component_ion_popover, {
        event: _ctx.popoverEvent,
        "is-open": _ctx.isOpen,
        onIonPopoverDidDismiss: _ctx.popoverDidDismiss
      }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_list, null, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (option, index) => {
                return (_openBlock(), _createBlock(_component_ion_item, {
                  key: index,
                  lines: index === _ctx.options.length - 1 ? 'none' : 'full',
                  onClick: _withModifiers(($event: any) => (_ctx.selectOption(index)), ["stop"])
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(option), 1)
                  ]),
                  _: 2
                }, 1032, ["lines", "onClick"]))
              }), 128))
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["event", "is-open", "onIonPopoverDidDismiss"])
    ]),
    _: 1
  }))
}