
import {
  IonButton,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonSelect,
  IonSelectOption,
  IonToggle,
  toastController,
} from "@ionic/vue"
import {defineComponent, PropType} from "vue"
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome"
import ScannerContainer from "@/components/ScannerContainer.vue"
import BarcodePreview from "@/components/BarcodePreview.vue"
import {useBarcodeStore} from "@/stores/barcode"
import {useSettingStore} from "@/stores/setting"
import {Barcode, BarcodeFormat, ParsedBarcode} from "@/gql/types"

export default defineComponent({
  components: {
    BarcodePreview,
    ScannerContainer,
    IonList,
    IonItem,
    IonLabel,
    IonInput,
    IonSelect,
    IonSelectOption,
    IonToggle,
    IonButton,
    FontAwesomeIcon,
  },
  emits: ["barcode-changed", "barcode-disabled"],
  props: {
    barcode: {
      required: false,
      default: null,
      type: Object as PropType<Barcode>,
    },
  },
  data() {
    return {
      persisted: null as Barcode | null,
      rendering: {content: "", format: BarcodeFormat.QrCode} as ParsedBarcode,
      barcodeActive: false,
      barcodeFormats: Object.values(BarcodeFormat),
      requiresConfirmation: false,
      isValidBarcodeEntry: false,
      invalidBarcodeEntryWarningShown: false,
    }
  },
  methods: {
    toggleBarcode(event: any) {
      this.barcodeActive = event.detail.checked
    },
    async scan() {
      await (this.$refs.scannerContainer as InstanceType<typeof ScannerContainer>).startScan()
    },
    async confirmBarcode() {
      this.requiresConfirmation = false
      this.isValidBarcodeEntry = true
      await this.handleScannedBarcode(this.rendering)
    },
    async handleScannedBarcode(barcode: ParsedBarcode | null) {
      if (barcode) {
        this.requiresConfirmation = false
        this.isValidBarcodeEntry = true
        this.rendering = barcode
        this.persisted = await this.barcodeStore.create(barcode)
      }
    },
    async handlePreviewChange(valid: boolean) {
      if (valid) {
        this.isValidBarcodeEntry = true
      } else {
        this.isValidBarcodeEntry = false
        await this.presentInvalidBarcodeEntryWarning()
      }
    },
    async presentInvalidBarcodeEntryWarning() {
      this.requiresConfirmation = true

      if (!this.invalidBarcodeEntryWarningShown) {
        this.invalidBarcodeEntryWarningShown = true
        const toast = await toastController.create({
          message: this.$t("prompt.invalidBarcodeEntryWarning"),
          duration: 3500,
          position: "top",
          buttons: [
            {
              text: this.$t("button.ok"),
              role: "cancel",
            },
          ],
          color: "warning",
        })
        await toast.present()
        await toast.onDidDismiss()
        this.invalidBarcodeEntryWarningShown = false
      }
    },
  },
  watch: {
    barcode: {
      immediate: true,
      deep: true,
      handler(barcode) {
        if (barcode && barcode.id !== this.persisted?.id) {
          this.persisted = Object.assign({}, barcode)
          this.rendering = {content: barcode.content, format: barcode.format} as ParsedBarcode
        }
        this.barcodeActive = Boolean(barcode)
      },
    },
    persisted: {
      immediate: true,
      deep: true,
      handler(persisted) {
        this.$emit("barcode-changed", persisted)
      },
    },
    barcodeActive: {
      immediate: true,
      handler(barcodeActive) {
        if (!barcodeActive) this.$emit("barcode-disabled")
      },
    },
  },
  setup() {
    return {
      barcodeStore: useBarcodeStore(),
      settingStore: useSettingStore(),
    }
  },
})
