import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ModalAction = _resolveComponent("ModalAction")!

  return (_openBlock(), _createBlock(_component_ModalAction, {
    "is-active": _ctx.modalActive,
    "on-confirm": _ctx.confirmEdit,
    "top-bar-title": _ctx.topBarTitle,
    onCanceled: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('edit-canceled'))),
    onConfirmed: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('edit-confirmed', $event)))
  }, {
    default: _withCtx(() => [
      _renderSlot(_ctx.$slots, "default", {
        active: _ctx.rendering,
        patchActive: _ctx.patchActive
      })
    ]),
    _: 3
  }, 8, ["is-active", "on-confirm", "top-bar-title"]))
}