import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "desktop-width-sm" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_card_title = _resolveComponent("ion-card-title")!
  const _component_ion_card_header = _resolveComponent("ion-card-header")!
  const _component_CategorySelect = _resolveComponent("CategorySelect")!
  const _component_ion_card_content = _resolveComponent("ion-card-content")!
  const _component_ion_card = _resolveComponent("ion-card")!
  const _component_CreateCategoryAction = _resolveComponent("CreateCategoryAction")!
  const _component_ion_col = _resolveComponent("ion-col")!
  const _component_EditCategoryAction = _resolveComponent("EditCategoryAction")!
  const _component_DeleteCategoryAction = _resolveComponent("DeleteCategoryAction")!
  const _component_ion_row = _resolveComponent("ion-row")!
  const _component_ion_grid = _resolveComponent("ion-grid")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_ion_card, null, {
      default: _withCtx(() => [
        _createVNode(_component_ion_card_header, null, {
          default: _withCtx(() => [
            _createVNode(_component_ion_card_title, null, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('general.selection')), 1)
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_component_ion_card_content, null, {
          default: _withCtx(() => [
            _createVNode(_component_CategorySelect, {
              categories: _ctx.categoryStore.categories,
              onSelectedCategory: _cache[0] || (_cache[0] = ($event: any) => (_ctx.selectedCategory = $event))
            }, null, 8, ["categories"])
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_ion_card, null, {
      default: _withCtx(() => [
        _createVNode(_component_ion_card_header, null, {
          default: _withCtx(() => [
            _createVNode(_component_ion_card_title, null, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('action.general')), 1)
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_component_ion_card_content, null, {
          default: _withCtx(() => [
            _createVNode(_component_ion_grid, null, {
              default: _withCtx(() => [
                _createVNode(_component_ion_row, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_col, { class: "ion-text-center" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_CreateCategoryAction, { onCreatedCategory: _ctx.presentResponseInfoToast }, null, 8, ["onCreatedCategory"])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_ion_col, { class: "ion-text-center" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_EditCategoryAction, {
                          category: _ctx.selectedCategory,
                          disabled: !_ctx.categoryStore.isModifiable(_ctx.selectedCategory),
                          onEditedCategory: _ctx.presentResponseInfoToast
                        }, null, 8, ["category", "disabled", "onEditedCategory"])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_ion_col, { class: "ion-text-center" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_DeleteCategoryAction, {
                          category: _ctx.selectedCategory,
                          disabled: !_ctx.categoryStore.isModifiable(_ctx.selectedCategory),
                          onDeletedCategory: _ctx.deletedCategory
                        }, null, 8, ["category", "disabled", "onDeletedCategory"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ]))
}