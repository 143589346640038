/* General */
import {createApp} from "vue"
import App from "@/App.vue"
import {IonicVue} from "@ionic/vue"


/* Plugins */
import router from "@/router"
import {createPinia} from "pinia"
import {createApolloProvider} from "@vue/apollo-option"
import createi18n from "@/shared/i18n/i18n"
import {createAuth0Client} from "@/shared/clients/auth0-client"
import apolloClient from "@/shared/clients/apollo-client"


/* Other */
/* Styling */
/* Core CSS required for Ionic components to work properly */
import "@ionic/vue/css/core.css"

/* Basic CSS for apps built with Ionic */
import "@ionic/vue/css/normalize.css"
import "@ionic/vue/css/structure.css"
import "@ionic/vue/css/typography.css"

/* Optional CSS utils that can be commented out */
import "@ionic/vue/css/padding.css"
import "@ionic/vue/css/float-elements.css"
import "@ionic/vue/css/text-alignment.css"
import "@ionic/vue/css/text-transformation.css"
import "@ionic/vue/css/flex-utils.css"
import "@ionic/vue/css/display.css"

/* Theme variables */
import "@/theme/variables.css"

/* Custom */
import "@/theme/fonts.css"
import "@/theme/table.css"
import "@/theme/custom.css"

/* Icons */
import {library} from "@fortawesome/fontawesome-svg-core"
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome"
import {
  faAngleDown,
  faArrowUpRightFromSquare,
  faBarcode,
  faCamera,
  faCheck,
  faClipboardList,
  faFileImport,
  faGear,
  faGhost,
  faLanguage,
  faListCheck,
  faMagnifyingGlass,
  faMap,
  faPenToSquare,
  faSignature,
  faSort,
  faSquarePlus,
  faStickyNote,
  faTable,
  faTag,
  faTrash,
  faUserPlus,
  faXmark,
} from "@fortawesome/free-solid-svg-icons"
import {defineCustomElements} from "@ionic/pwa-elements/loader"
import {
  getQueryParam,
  getQueryParams,
  removeQueryParam,
  setQueryParam,
  setupSettingsPersistance,
} from "@/shared/helpers"
import {initSentry} from "@/shared/clients/sentry-client"

library.add(faSignature, faMap, faStickyNote, faCamera, faBarcode, faMagnifyingGlass, faFileImport, faTable, faTrash, faArrowUpRightFromSquare, faGhost, faTag, faPenToSquare, faCheck, faXmark, faAngleDown, faSort, faLanguage, faListCheck, faGear, faSquarePlus, faUserPlus, faClipboardList)


/* Setup */
export const app = createApp(App)

/* Plugins */
app.use(IonicVue, {mode: "md"})
app.use(router)
app.use(createPinia())
await setupSettingsPersistance()
app.use(createi18n())
await initSentry()
app.use(createAuth0Client())
app.use(createApolloProvider({defaultClient: apolloClient}))

/* Mount app */
await router.isReady()
await app.mount("#app")

/* Custom elements */
app.component("font-awesome-icon", FontAwesomeIcon)
await defineCustomElements(window)


/* Global functions */
app.config.globalProperties.$getQueryParams = getQueryParams
app.config.globalProperties.$getQueryParam = getQueryParam
app.config.globalProperties.$setQueryParam = setQueryParam
app.config.globalProperties.$removeQueryParam = removeQueryParam
