
import {IonButton, IonItem, IonLabel, IonList} from "@ionic/vue"
import {defineComponent, PropType} from "vue"
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome"
import LocationSelect from "@/components/LocationSelect.vue"
import ModalAction from "@/components/ModalAction.vue"
import {useLocationStore} from "@/stores/location"
import {Location} from "@/gql/types"
import {convertGraphQlErrorToResponseInfo, createSuccessfulResponseInfo} from "@/shared/helpers"

export default defineComponent({
  components: {
    IonButton,
    IonList,
    IonItem,
    IonLabel,
    ModalAction,
    LocationSelect,
    FontAwesomeIcon,
  },
  emits: ["moved-location"],
  props: {
    location: {
      required: true,
      type: Object as PropType<Location>,
    },
    disabled: {
      required: false,
      default: false,
      type: Boolean,
    },
  },
  computed: {
    buttonSize() {
      return window.innerWidth < 1280 ? "default" : "large"
    },
  },
  data() {
    return {
      modalActive: false,
      selectedLocation: this.location.location as Location,
    }
  },
  methods: {
    openModal() {
      this.modalActive = true
    },
    closeModal() {
      this.modalActive = false
    },
    async confirmLocationMove() {
      try {
        await this.locationStore.moveLocation(this.location, this.selectedLocation)
        return createSuccessfulResponseInfo("success.location.move")
      } catch (e) {
        return convertGraphQlErrorToResponseInfo(e)
      } finally {
        this.closeModal()
      }
    },
  },
  setup() {
    return {
      locationStore: useLocationStore(),
    }
  },
})
