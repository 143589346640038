import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_toggle = _resolveComponent("ion-toggle")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_ion_label, null, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.$t('setting.filterSortingPermanentlyActive')), 1)
      ]),
      _: 1
    }),
    _createVNode(_component_ion_toggle, {
      slot: "end",
      checked: _ctx.settingStore.persistable.preferences.keepFilterSortAccordionExpanded,
      onIonChange: _ctx.onToggle
    }, null, 8, ["checked", "onIonChange"])
  ], 64))
}