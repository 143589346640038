import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, withCtx as _withCtx, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_img = _resolveComponent("ion-img")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_popover = _resolveComponent("ion-popover")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.barcodePreviewUri)
      ? (_openBlock(), _createBlock(_component_ion_img, {
          key: 0,
          src: _ctx.barcodePreviewUri,
          class: "small-image",
          onClick: _ctx.open
        }, null, 8, ["src", "onClick"]))
      : _createCommentVNode("", true),
    _createVNode(_component_ion_popover, {
      "is-open": _ctx.popupIsActive,
      onDidDismiss: _ctx.close
    }, {
      default: _withCtx(() => [
        _createVNode(_component_ion_content, { class: "ion-padding" }, {
          default: _withCtx(() => [
            _createVNode(_component_ion_img, {
              src: _ctx.barcodePreviewUri,
              class: "large-image"
            }, null, 8, ["src"])
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["is-open", "onDidDismiss"])
  ], 64))
}