
import {IonButton, IonInput, IonItem, IonLabel, IonList} from "@ionic/vue"
import {defineComponent, PropType} from "vue"
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome"
import EditAction from "@/components/EditAction.vue"
import BarcodeEditor from "@/components/BarcodeEditor.vue"
import {Location} from "@/gql/types"
import {useLocationStore} from "@/stores/location"
import {useBarcodeStore} from "@/stores/barcode"
import {convertGraphQlErrorToResponseInfo, createSuccessfulResponseInfo} from "@/shared/helpers"

export default defineComponent({
  components: {
    BarcodeEditor,
    EditAction,
    IonList,
    IonItem,
    IonLabel,
    IonInput,
    IonButton,
    FontAwesomeIcon,
  },
  emits: ["edited-location"],
  props: {
    location: {
      required: true,
      type: Object as PropType<Location>,
    },
    disabled: {
      required: false,
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {
      modalActive: false,
    }
  },
  computed: {
    buttonSize() {
      return window.innerWidth < 1280 ? "default" : "large"
    },
  },
  methods: {
    openModal() {
      this.modalActive = true
    },
    closeModal() {
      this.modalActive = false
    },
    async confirmLocationEdit(editedLocation: Location) {
      if (!this.barcodeStore.validateBarcode(editedLocation)) {
        return {messageKey: "failure.barcode.create.requiredFields", isError: true}
      }

      try {
        await this.locationStore.editLocation(editedLocation, this.location.barcode !== editedLocation.barcode)
        return createSuccessfulResponseInfo("success.location.edit")
      } catch (e) {
        return convertGraphQlErrorToResponseInfo(e)
      } finally {
        this.closeModal()
      }
    },
  },
  setup() {
    return {
      locationStore: useLocationStore(),
      barcodeStore: useBarcodeStore(),
    }
  },
})
