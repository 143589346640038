
import {IonCol, IonGrid, IonItem, IonLabel, IonRow, IonSelect, IonSelectOption} from "@ionic/vue"
import {defineComponent} from "vue"
import {useSettingStore} from "@/stores/setting"
import {SortBy, SortDirection} from "@/gql/types"

export default defineComponent({
  components: {IonSelect, IonSelectOption, IonGrid, IonRow, IonCol, IonItem, IonLabel},
  emits: ["sort-by", "sort-direction"],
  props: {
    noSidePadding: {
      required: false,
      default: true,
      type: Boolean,
    },
  },
  data() {
    return {
      sortByOptions: Object.values(SortBy),
      sortDirectionOptions: Object.values(SortDirection),
      selectedSortBy: SortBy.Item as SortBy,
      selectedSortDirection: SortDirection.Ascending as SortDirection,
    }
  },
  methods: {
    selectSortBy(option: SortBy) {
      this.settingStore.persistable.search.sorting.by = option
      this.$emit("sort-by", option)
    },
    selectSortDirection(option: SortDirection) {
      this.settingStore.persistable.search.sorting.direction = option
      this.$emit("sort-direction", option)
    },
  },
  watch: {
    selectedSortBy: {
      handler(sortBy) {
        this.selectSortBy(sortBy)
      },
    },
    selectedSortDirection: {
      handler(sortDirection) {
        this.selectSortDirection(sortDirection)
      },
    },
  },
  created() {
    this.selectedSortBy = this.settingStore.persistable.search.sorting.by
    this.selectedSortDirection = this.settingStore.persistable.search.sorting.direction
  },
  setup() {
    return {
      settingStore: useSettingStore(),
    }
  },
})
