import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, normalizeClass as _normalizeClass } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_select_option = _resolveComponent("ion-select-option")!
  const _component_ion_select = _resolveComponent("ion-select")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_col = _resolveComponent("ion-col")!
  const _component_ion_row = _resolveComponent("ion-row")!
  const _component_ion_grid = _resolveComponent("ion-grid")!

  return (_openBlock(), _createBlock(_component_ion_grid, {
    class: _normalizeClass({'no-padding-sides': _ctx.noSidePadding})
  }, {
    default: _withCtx(() => [
      _createVNode(_component_ion_row, null, {
        default: _withCtx(() => [
          _createVNode(_component_ion_col, {
            class: _normalizeClass({'no-padding-sides': _ctx.noSidePadding})
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_item, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_label, { position: "stacked" }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t('search.sortBy')), 1)
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_select, {
                    modelValue: _ctx.selectedSortBy,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedSortBy) = $event)),
                    "cancel-text": _ctx.$t('button.cancel'),
                    interface: _ctx.settingStore.selectOptionInterfaceType,
                    "ok-text": _ctx.$t('button.ok')
                  }, {
                    default: _withCtx(() => [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.sortByOptions, (option) => {
                        return (_openBlock(), _createBlock(_component_ion_select_option, {
                          key: option,
                          value: option
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.$t(`search.${option.toLowerCase()}`)), 1)
                          ]),
                          _: 2
                        }, 1032, ["value"]))
                      }), 128))
                    ]),
                    _: 1
                  }, 8, ["modelValue", "cancel-text", "interface", "ok-text"])
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["class"]),
          _createVNode(_component_ion_col, {
            class: _normalizeClass({'no-padding-sides': _ctx.noSidePadding})
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_item, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_label, { position: "stacked" }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t('search.sortDirection')), 1)
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_select, {
                    modelValue: _ctx.selectedSortDirection,
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.selectedSortDirection) = $event)),
                    "cancel-text": _ctx.$t('button.cancel'),
                    interface: _ctx.settingStore.selectOptionInterfaceType,
                    "ok-text": _ctx.$t('button.ok')
                  }, {
                    default: _withCtx(() => [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.sortDirectionOptions, (option) => {
                        return (_openBlock(), _createBlock(_component_ion_select_option, {
                          key: option,
                          value: option
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.$t(`search.${option.toLowerCase()}`)), 1)
                          ]),
                          _: 2
                        }, 1032, ["value"]))
                      }), 128))
                    ]),
                    _: 1
                  }, 8, ["modelValue", "cancel-text", "interface", "ok-text"])
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["class"])
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["class"]))
}