
import {alertController, IonFab, IonFabButton, toastController} from "@ionic/vue"
import {defineComponent} from "vue"
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome"
import ScannerContainer from "@/components/ScannerContainer.vue"
import CreateItemModal from "@/components/CreateItemModal.vue"
import CreateLocationModal from "@/components/CreateLocationModal.vue"
import {useLocationStore} from "@/stores/location"
import {useCategoryStore} from "@/stores/category"
import {useSettingStore} from "@/stores/setting"
import {useBarcodeStore} from "@/stores/barcode"
import {BarcodeAssociation, ParsedBarcode} from "@/gql/types"
import {ResponseInfo} from "@/shared/helpers"

export default defineComponent({
  components: {CreateLocationModal, CreateItemModal, ScannerContainer, IonFab, IonFabButton, FontAwesomeIcon},
  emits: ["created-item", "created-location", "scanned-barcode"],
  data() {
    return {
      barcode: null as ParsedBarcode | null,
      createItemModalIsActive: false,
      createLocationModalIsActive: false,
    }
  },
  methods: {
    startCreateItem() {
      this.createItemModalIsActive = true
    },
    stopCreateItem() {
      this.createItemModalIsActive = false
    },
    startCreateLocation() {
      this.createLocationModalIsActive = true
    },
    stopCreateLocation() {
      this.createLocationModalIsActive = false
    },
    async startScan() {
      await (this.$refs.scannerContainer as InstanceType<typeof ScannerContainer>).startScan()
    },
    async handleCreatedItem(responseInfo: ResponseInfo) {
      this.stopCreateItem()
      await this.presentResponseInfoToast(responseInfo)
      this.$emit("created-item", responseInfo)
    },
    async handleCreatedLocation(responseInfo: ResponseInfo) {
      this.stopCreateLocation()
      await this.presentResponseInfoToast(responseInfo)
      this.$emit("created-location", responseInfo)
    },
    async handleScannedBarcode(barcode: ParsedBarcode | null) {
      if (barcode) {
        this.barcode = barcode
        switch (await this.barcodeStore.findBarcodeAssociation(barcode)) {
          case BarcodeAssociation.Item: {
            this.settingStore.persistable.search.query = barcode.content
            this.settingStore.persistable.search.lastSelection.location = this.locationStore.allLocations
            await this.presentToast(`${this.$t("success.barcode.association.item")}!`)
            break
          }
          case BarcodeAssociation.Location: {
            const location = (await this.locationStore.findByBarcode(barcode))! // eslint-disable-line @typescript-eslint/no-non-null-assertion
            this.settingStore.persistable.search.lastSelection.location = location ?? this.locationStore.allLocations
            this.settingStore.persistable.search.query = ""
            await this.presentToast(`${this.$t("success.barcode.association.location", {locationName: location.name})}!`)
            break
          }
          default:
            await this.presentToast(`${this.$t("failure.barcode.association", {barcodeContent: barcode.content})}!`)
            await this.promptBarcodeCreation(barcode)
            break
        }
      } else {
        this.barcode = null
      }
      this.$emit("scanned-barcode", barcode)
    },
    async presentToast(message: string) {
      const toast = await toastController.create({
        message: message,
        duration: 3500,
        position: "top",
        buttons: [
          {
            text: this.$t("button.ok"),
            role: "cancel",
          },
        ],
      })
      await toast.present()
    },
    async presentResponseInfoToast(responseInfo: ResponseInfo) {
      const toast = await toastController.create({
        message: `${this.$t(responseInfo.messageKey)}!`,
        duration: 5560,
        position: "top",
        buttons: [
          {
            text: this.$t("button.ok"),
            role: "cancel",
          },
        ],
        color: responseInfo.isError ? "danger" : "",
      })
      await toast.present()
    },
    async promptBarcodeCreation(barcode: ParsedBarcode) {
      const alert = await alertController.create({
        header: this.$t("general.barcodeUnknown"),
        message: this.$t("prompt.createBarcodeConfirm", {barcodeContent: barcode.content}),
        buttons: [
          {
            text: this.$t("button.createItem"),
            role: "item",
          },
          {
            text: this.$t("button.createLocation"),
            role: "location",
          },
          {
            text: this.$t("button.cancel"),
            role: "cancel",
          },
        ],
      })
      await alert.present()
      const result = await alert.onDidDismiss()
      switch (result.role) {
        case "item":
          this.stopCreateLocation()
          this.startCreateItem()
          break
        case "location":
          this.stopCreateItem()
          this.startCreateLocation()
          break
      }
    },
  },
  setup() {
    return {
      locationStore: useLocationStore(),
      categoryStore: useCategoryStore(),
      barcodeStore: useBarcodeStore(),
      settingStore: useSettingStore(),
    }
  },
})
