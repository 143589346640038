import {defineStore} from "pinia"
import {getPlatforms} from "@ionic/vue"
import {useLocationStore} from "@/stores/location"
import {useCategoryStore} from "@/stores/category"
import {Category, Location, SortBy, SortDirection} from "@/gql/types"

export const useSettingStore = defineStore("setting", {
  state: () => ({
    persistable: {
      search: {
        query: "" as string,
        lastSelection: {
          location: useLocationStore().allLocations as Location,
          category: useCategoryStore().allCategories as Category,
        },
        sorting: {
          by: SortBy.Item as SortBy,
          direction: SortDirection.Ascending as SortDirection,
        },
        limit: 25,
      },
      itemCreation: {
        lastSelection: {
          location: null as Location | null,
          category: null as Category | null,
        },
      },
      preferences: {
        language: "en" as string,
        keepFilterSortAccordionExpanded: false,
      },
      miscellaneous: {
        activeInventoryId: null as string | null,
        requestedPath: null as string | null,
        onboardingPresented: false,
        subscriptionsPresented: false,
        retryCount: 0,
      },
    },
    nonPersistable: {
      platforms: getPlatforms(),
      webViewHeight: window.innerHeight,
      webViewWidth: window.innerWidth,
    },
  }),
  getters: {
    isMobile: (state) => state.nonPersistable.platforms.includes("mobile"),
    isNative: (state) => state.nonPersistable.platforms.includes("capacitor"),
    isAndroid: (state) => state.nonPersistable.platforms.includes("capacitor") && state.nonPersistable.platforms.includes("android"),
    isIos: (state) => state.nonPersistable.platforms.includes("capacitor") && state.nonPersistable.platforms.includes("ios"),
    supportsScanning: (state) => state.nonPersistable.platforms.includes("capacitor"),
    renderMobileLayout: (state) => state.nonPersistable.webViewWidth < 576,
    renderTabletMobileLayout: (state) => state.nonPersistable.webViewWidth < 992,
    selectOptionInterfaceType: (state) => state.nonPersistable.platforms.includes("mobile") ? "alert" : "popover",
  },
  actions: {
    resetInventorySpecificSettings() {
      this.persistable.search.lastSelection.location = useLocationStore().allLocations
      this.persistable.search.lastSelection.category = useCategoryStore().allCategories
      this.persistable.itemCreation.lastSelection.location = null
      this.persistable.itemCreation.lastSelection.category = null
    },
  },
})
