
import {IonButton} from "@ionic/vue"
import {defineComponent} from "vue"
import {useAuthenticationStore} from "@/stores/authentication"

export default defineComponent({
  components: {
    IonButton,
  },
  setup() {
    return {
      authStore: useAuthenticationStore(),
    }
  },
})
