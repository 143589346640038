
import {IonInput, IonItem, IonLabel, IonList} from "@ionic/vue"
import {defineComponent, PropType} from "vue"
import EditAction from "@/components/EditAction.vue"
import LocationSelect from "@/components/LocationSelect.vue"
import BarcodeEditor from "@/components/BarcodeEditor.vue"
import {useInventoryStore} from "@/stores/inventory"
import {useLocationStore} from "@/stores/location"
import {useBarcodeStore} from "@/stores/barcode"
import {useSettingStore} from "@/stores/setting"
import {convertGraphQlErrorToResponseInfo, createSuccessfulResponseInfo, ResponseInfo} from "@/shared/helpers"
import {Location, NewLocation, ParsedBarcode} from "@/gql/types"

export default defineComponent({
  components: {
    BarcodeEditor,
    LocationSelect,
    EditAction,
    IonList,
    IonItem,
    IonLabel,
    IonInput,
  },
  emits: ["created-location", "canceled"],
  props: {
    modalActive: {
      required: true,
      default: false,
      type: Boolean,
    },
    parentLocation: {
      required: true,
      type: Object as PropType<Location>,
    },
    barcode: {
      required: false,
      default: null,
      type: Object as PropType<ParsedBarcode>,
    },
  },
  data() {
    return {
      newLocation: this.generateEmptyNewLocation(),
    }
  },
  methods: {
    async confirmLocationCreate(newLocation: NewLocation): Promise<ResponseInfo> {
      if (!this.barcodeStore.validateBarcode(newLocation)) {
        return {messageKey: "failure.barcode.create.requiredFields", isError: true}
      }

      try {
        await this.locationStore.createLocation(newLocation)
        this.newLocation = this.generateEmptyNewLocation()
        return createSuccessfulResponseInfo("success.location.create")
      } catch (e) {
        return convertGraphQlErrorToResponseInfo(e)
      }
    },
    generateEmptyNewLocation(): NewLocation {
      return {
        name: "",
        locationId: this.parentLocation.id,
        barcode: this.barcode as ParsedBarcode | null,
      } as NewLocation
    },
  },
  watch: {
    parentLocation: {
      immediate: true,
      handler(parentLocation) {
        this.newLocation.locationId = parentLocation.id
      },
    },
    barcode: {
      immediate: true,
      handler(barcode) {
        this.newLocation.barcode = barcode
      },
    },
  },
  setup() {
    return {
      inventoryStore: useInventoryStore(),
      locationStore: useLocationStore(),
      barcodeStore: useBarcodeStore(),
      settingStore: useSettingStore(),
    }
  },
})
