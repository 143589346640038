import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "desktop-width-sm" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_card_title = _resolveComponent("ion-card-title")!
  const _component_ion_card_header = _resolveComponent("ion-card-header")!
  const _component_InventorySelect = _resolveComponent("InventorySelect")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_card_content = _resolveComponent("ion-card-content")!
  const _component_ion_card = _resolveComponent("ion-card")!
  const _component_CreateInventoryAction = _resolveComponent("CreateInventoryAction")!
  const _component_ion_col = _resolveComponent("ion-col")!
  const _component_EditInventoryAction = _resolveComponent("EditInventoryAction")!
  const _component_CreateInventoryShareAction = _resolveComponent("CreateInventoryShareAction")!
  const _component_DeleteInventoryAction = _resolveComponent("DeleteInventoryAction")!
  const _component_ion_row = _resolveComponent("ion-row")!
  const _component_ion_grid = _resolveComponent("ion-grid")!
  const _component_SharedWithAction = _resolveComponent("SharedWithAction")!
  const _component_ion_list = _resolveComponent("ion-list")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_ion_card, null, {
      default: _withCtx(() => [
        _createVNode(_component_ion_card_header, null, {
          default: _withCtx(() => [
            _createVNode(_component_ion_card_title, null, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('general.selection')), 1)
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_component_ion_card_content, null, {
          default: _withCtx(() => [
            _createVNode(_component_ion_item, null, {
              default: _withCtx(() => [
                _createVNode(_component_InventorySelect, {
                  inventories: _ctx.inventoryStore.ownedInventories,
                  label: _ctx.$t('entity.inventory.owned'),
                  "pre-selected-inventory": _ctx.inventoryStore.ownedInventories[0],
                  "stacked-label": true,
                  onSelectedInventory: _cache[0] || (_cache[0] = ($event: any) => (_ctx.selectedInventory = $event))
                }, null, 8, ["inventories", "label", "pre-selected-inventory"])
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_ion_card, null, {
      default: _withCtx(() => [
        _createVNode(_component_ion_card_header, null, {
          default: _withCtx(() => [
            _createVNode(_component_ion_card_title, null, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('action.general')), 1)
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_component_ion_card_content, null, {
          default: _withCtx(() => [
            _createVNode(_component_ion_grid, null, {
              default: _withCtx(() => [
                _createVNode(_component_ion_row, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_col, { class: "ion-text-center" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_CreateInventoryAction, { onCreatedInventory: _ctx.presentResponseInfoToast }, null, 8, ["onCreatedInventory"])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_ion_col, { class: "ion-text-center" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_EditInventoryAction, {
                          inventory: _ctx.selectedInventory,
                          onEditedInventory: _ctx.editedInventory
                        }, null, 8, ["inventory", "onEditedInventory"])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_ion_col, { class: "ion-text-center" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_CreateInventoryShareAction, {
                          inventory: _ctx.selectedInventory,
                          onCreatedShare: _ctx.createdShare
                        }, null, 8, ["inventory", "onCreatedShare"])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_ion_col, { class: "ion-text-center" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_DeleteInventoryAction, {
                          inventory: _ctx.selectedInventory,
                          onDeletedInventory: _ctx.editedInventory
                        }, null, 8, ["inventory", "onDeletedInventory"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_ion_card, null, {
      default: _withCtx(() => [
        _createVNode(_component_ion_card_header, null, {
          default: _withCtx(() => [
            _createVNode(_component_ion_card_title, null, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('general.sharedWith')), 1)
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_component_ion_card_content, null, {
          default: _withCtx(() => [
            _createVNode(_component_ion_list, { lines: "full" }, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.sharedWithAccounts, (sharedWith) => {
                  return (_openBlock(), _createBlock(_component_ion_item, {
                    key: sharedWith,
                    value: sharedWith
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_SharedWithAction, {
                        inventory: _ctx.selectedInventory,
                        "shared-with": sharedWith,
                        onDeletedShare: _ctx.deletedShare
                      }, null, 8, ["inventory", "shared-with", "onDeletedShare"])
                    ]),
                    _: 2
                  }, 1032, ["value"]))
                }), 128))
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ]))
}