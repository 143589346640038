
import {defineComponent, PropType} from "vue"
import TooltipBadge from "@/components/TooltipBadge.vue"
import {Category} from "@/gql/types"

export default defineComponent({
  components: {TooltipBadge},
  props: {
    category: {
      required: true,
      type: Object as PropType<Category>,
    },
  },
})
