
import {IonItem, IonLabel, IonList} from "@ionic/vue"
import {defineComponent} from "vue"
import {useSettingStore} from "@/stores/setting"

export default defineComponent({
  components: {IonItem, IonLabel, IonList},
  setup() {
    return {
      settingStore: useSettingStore(),
    }
  },
})
