
import {IonSelect, IonSelectOption} from "@ionic/vue"
import {defineComponent, PropType} from "vue"
import {useSettingStore} from "@/stores/setting"
import {Category} from "@/gql/types"

export default defineComponent({
  components: {IonSelect, IonSelectOption},
  emits: ["selected-category"],
  props: {
    categories: {
      required: true,
      type: Array as PropType<Array<Category>>,
    },
    preSelectedCategory: {
      required: false,
      default: null,
      type: Object as PropType<Category>,
    },
  },
  data() {
    return {
      selectedCategoryId: this.preSelectedCategory?.id ?? this.categories[0].id,
    }
  },
  computed: {
    orderedCategories() {
      const categories = this.categories.filter(l => l.name !== "?")
      const lostCategory = this.categories.find(l => l.name === "?")
      if (lostCategory) categories.splice(1, 0, lostCategory)
      return categories
    },
  },
  methods: {
    selectCategory(category: Category) {
      this.$emit("selected-category", category)
    },
  },
  watch: {
    preSelectedCategory: {
      immediate: true,
      handler(category) {
        if (category) this.selectedCategoryId = category.id
      },
    },
    selectedCategoryId: {
      handler(categoryId) {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        this.selectCategory(this.categories.find(c => c.id === categoryId)!)
      },
    },
  },
  setup() {
    return {
      settingStore: useSettingStore(),
    }
  },
})
