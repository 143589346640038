
import {
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonCol,
  IonGrid,
  IonRow,
  toastController,
} from "@ionic/vue"
import {defineComponent} from "vue"
import {useAuthenticationStore} from "@/stores/authentication"
import {useSettingStore} from "@/stores/setting"
import {useLocationStore} from "@/stores/location"
import LocationSelect from "@/components/LocationSelect.vue"
import EditLocationAction from "@/components/EditLocationAction.vue"
import CreateLocationAction from "@/components/CreateLocationAction.vue"
import DeleteLocationAction from "@/components/DeleteLocationAction.vue"
import MoveLocationAction from "@/components/MoveLocationAction.vue"
import {Location} from "@/gql/types"
import {ResponseInfo} from "@/shared/helpers"

export default defineComponent({
  components: {
    MoveLocationAction,
    DeleteLocationAction,
    CreateLocationAction,
    EditLocationAction,
    LocationSelect,
    IonCard,
    IonCardHeader,
    IonCardTitle,
    IonCardContent,
    IonGrid,
    IonRow,
    IonCol,
  },
  data() {
    return {
      selectedLocation: null as Location | null,
    }
  },
  methods: {
    selectDefaultLocation() {
      this.selectedLocation = this.locationStore.rootLocation
    },
    async presentResponseInfoToast(responseInfo: ResponseInfo) {
      const toast = await toastController.create({
        message: `${this.$t(responseInfo.messageKey)}!`,
        duration: 5560,
        position: "top",
        buttons: [
          {
            text: this.$t("button.ok"),
            role: "cancel",
          },
        ],
        color: responseInfo.isError ? "danger" : "",
      })
      await toast.present()
    },
    async deletedLocation(responseInfo: ResponseInfo) {
      await this.presentResponseInfoToast(responseInfo)
      this.selectDefaultLocation()
    },
  },
  created() {
    this.selectDefaultLocation()
  },
  setup() {
    return {
      authStore: useAuthenticationStore(),
      settingStore: useSettingStore(),
      locationStore: useLocationStore(),
    }
  },
})
