import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_card_title = _resolveComponent("ion-card-title")!
  const _component_ion_card_subtitle = _resolveComponent("ion-card-subtitle")!
  const _component_ion_card_header = _resolveComponent("ion-card-header")!
  const _component_LogoutButton = _resolveComponent("LogoutButton")!
  const _component_ExportDataButton = _resolveComponent("ExportDataButton")!
  const _component_DeleteAccountButton = _resolveComponent("DeleteAccountButton")!
  const _component_ion_card_content = _resolveComponent("ion-card-content")!
  const _component_ion_card = _resolveComponent("ion-card")!

  return (_openBlock(), _createBlock(_component_ion_card, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_card_header, null, {
        default: _withCtx(() => [
          _createVNode(_component_ion_card_title, null, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('general.account')), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_ion_card_subtitle, null, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.authStore.user.email), 1)
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_ion_card_content, null, {
        default: _withCtx(() => [
          _createVNode(_component_LogoutButton),
          (!_ctx.settingStore.isNative && _ctx.accountStore.subscription.tier !== _ctx.SubscriptionTier.Free)
            ? (_openBlock(), _createBlock(_component_ExportDataButton, { key: 0 }))
            : _createCommentVNode("", true),
          _createVNode(_component_DeleteAccountButton)
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}