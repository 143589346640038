
import {
  IonAccordion,
  IonAccordionGroup,
  IonBadge,
  IonCol,
  IonGrid,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonItem,
  IonLabel,
  IonRefresher,
  IonRefresherContent,
  IonRow,
  IonSearchbar,
  IonSkeletonText,
  IonText,
} from "@ionic/vue"
import {defineComponent, watch} from "vue"
import ItemTable from "./ItemTable.vue"
import LocationSelect from "./LocationSelect.vue"
import CategorySelect from "@/components/CategorySelect.vue"
import SortSelect from "@/components/SortSelect.vue"
import SearchFloatingActionButtons from "@/components/SearchFloatingActionButtons.vue"
import {useLocationStore} from "@/stores/location"
import {useCategoryStore} from "@/stores/category"
import {useSettingStore} from "@/stores/setting"
import {useInventoryStore} from "@/stores/inventory"
import {useItemStore} from "@/stores/item"
import {useBarcodeStore} from "@/stores/barcode"

export default defineComponent({
  components: {
    SearchFloatingActionButtons,
    SortSelect,
    CategorySelect,
    LocationSelect,
    ItemTable,
    IonItem,
    IonLabel,
    IonGrid,
    IonCol,
    IonRow,
    IonSearchbar,
    IonAccordion,
    IonAccordionGroup,
    IonBadge,
    IonText,
    IonRefresher,
    IonRefresherContent,
    IonInfiniteScroll,
    IonInfiniteScrollContent,
    IonSkeletonText,
  },
  data() {
    return {
      filterSortAccordionActive: [] as string[],
    }
  },
  computed: {
    searchPlaceholder() {
      const exampleItem = this.itemStore.rotatingItem()
      if (exampleItem) {
        return `${this.$t("general.forExample")} ${exampleItem.name}`
      } else {
        return ""
      }
    },
  },
  methods: {
    async refetchItems() {
      await this.itemStore.refetchItems()
    },
    async fetchMoreItems() {
      await this.itemStore.fetchMoreItems()
    },
    async handleRefresh(event: any) {
      await this.refetchItems()
      event.target.complete()
    },
    async loadMoreResults(event: any) {
      await this.fetchMoreItems()
      event.target.complete()
    },
  },
  created() {
    watch(
      this.settingStore.persistable,
      (persistable) => {
        if (persistable.preferences.keepFilterSortAccordionExpanded) {
          this.filterSortAccordionActive = ["filters", "sorting"]
        }
      },
      {immediate: true},
    )
  },
  setup() {
    return {
      inventoryStore: useInventoryStore(),
      itemStore: useItemStore(),
      categoryStore: useCategoryStore(),
      locationStore: useLocationStore(),
      barcodeStore: useBarcodeStore(),
      settingStore: useSettingStore(),
    }
  },
})
