import {CapacitorConfig} from "@capacitor/cli"

const config: CapacitorConfig = {
  appId: "app.order2chaos.bring",
  appName: "bring.order2chaos",
  webDir: "dist",
  bundledWebRuntime: false,
  plugins: {
    SplashScreen: {
      launchAutoHide: false,
      splashImmersive: true,
    },
  },
  ios: {
    scheme: "bring.order2chaos",
  },
}

export default config
