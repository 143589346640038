
import {defineComponent} from "vue"
import ModalAction from "@/components/ModalAction.vue"
import {ResponseInfo} from "@/shared/helpers"

export default defineComponent({
  components: {ModalAction},
  emits: ["edit-confirmed", "edit-canceled"],
  props: {
    modalActive: {
      required: true,
      default: false,
      type: Boolean,
    },
    toRender: {
      required: false,
      type: Object,
    },
    topBarTitle: {
      required: true,
      type: String,
    },
    onConfirm: {
      required: true,
      type: Function,
    },
  },
  data() {
    return {
      rendering: {},
    }
  },
  methods: {
    patchActive(patch: object) {
      this.rendering = Object.assign(this.rendering, patch)
    },
    async confirmEdit(): Promise<ResponseInfo> {
      return await this.onConfirm(this.rendering)
    },
  },
  watch: {
    toRender: {
      immediate: true,
      deep: true,
      handler(toRender) {
        this.rendering = Object.assign(this.rendering, toRender)
      },
    },
  },
})
