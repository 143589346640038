import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_ion_button = _resolveComponent("ion-button")!

  return (_openBlock(), _createBlock(_component_ion_button, {
    color: _ctx.iconColor,
    disabled: _ctx.buttonDisabled,
    size: _ctx.buttonSize,
    onClick: _ctx.trigger
  }, {
    default: _withCtx(() => [
      _createVNode(_component_font_awesome_icon, { icon: _ctx.iconType }, null, 8, ["icon"])
    ]),
    _: 1
  }, 8, ["color", "disabled", "size", "onClick"]))
}