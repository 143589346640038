import {defineStore} from "pinia"
import apolloClient from "@/shared/clients/apollo-client"
import {useInventoryStore} from "@/stores/inventory"
import {Barcode, BarcodeAssociation, Item, Location, NewLocation, ParsedBarcode} from "@/gql/types"
import gql from "graphql-tag"
import {convertGraphQlErrorToResponseInfo} from "@/shared/helpers"

export const useBarcodeStore = defineStore("barcode", {
  state: () => ({
    _inventoryStore: useInventoryStore(),
  }),
  actions: {
    async create(barcode: ParsedBarcode): Promise<Barcode> {
      const response = await apolloClient.mutate({
        mutation: gql`
            mutation createBarcode($inventoryId: ID!, $barcode: ParsedBarcode!) {
                createBarcode(inventoryId: $inventoryId, barcode: $barcode) {
                    id
                    content
                    format
                    labels
                }
            }
        `,
        variables: {
          inventoryId: this._inventoryStore.inventories.active.id,
          barcode: barcode,
        },
      })
      return response.data["createBarcode"] as Barcode
    },
    async findBarcodeAssociation(barcode: ParsedBarcode): Promise<BarcodeAssociation | null> {
      try {
        const response = await apolloClient.query({
          query: gql`
              query findBarcodeAssociation($inventoryId: ID!, $barcode: ParsedBarcode!) {
                  findBarcodeAssociation(inventoryId: $inventoryId, barcode: $barcode)
              }
          `,
          variables: {
            inventoryId: this._inventoryStore.inventories.active.id,
            barcode: barcode,
          },
          fetchPolicy: "network-only",
        })
        return response.data["findBarcodeAssociation"] as BarcodeAssociation | null
      } catch (e) {
        convertGraphQlErrorToResponseInfo(e)
        return null
      }
    },
    validateBarcode(entity: Item | Location | NewLocation): boolean {
      return !(entity.barcode && (!entity.barcode.content || !entity.barcode.format))
    },
    validateParsedBarcode(barcode: ParsedBarcode): boolean {
      return Boolean(barcode) && Boolean(barcode.content) && Boolean(barcode.format)
    },
  },
})
