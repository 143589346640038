import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ImageCapture = _resolveComponent("ImageCapture")!
  const _component_SuggestionsTextInput = _resolveComponent("SuggestionsTextInput")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_textarea = _resolveComponent("ion-textarea")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_CategorySelect = _resolveComponent("CategorySelect")!
  const _component_ion_list = _resolveComponent("ion-list")!
  const _component_BarcodeEditor = _resolveComponent("BarcodeEditor")!
  const _component_EditAction = _resolveComponent("EditAction")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_ion_button, {
      color: "sunset-1",
      onClick: _ctx.openModal
    }, {
      default: _withCtx(() => [
        _createVNode(_component_font_awesome_icon, { icon: "fa-pen-to-square" })
      ]),
      _: 1
    }, 8, ["onClick"]),
    _createVNode(_component_EditAction, {
      "modal-active": _ctx.modalActive,
      "on-confirm": _ctx.confirmItemEdit,
      "to-render": _ctx.item,
      "top-bar-title": _ctx.$t('action.edit'),
      onEditConfirmed: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('edited-item', $event))),
      onEditCanceled: _ctx.closeModal
    }, {
      default: _withCtx(({active, patchActive}) => [
        _createVNode(_component_ion_list, { lines: "full" }, {
          default: _withCtx(() => [
            _createVNode(_component_ImageCapture, {
              image: active.image,
              onImageChanged: ($event: any) => (patchActive({image: $event}))
            }, null, 8, ["image", "onImageChanged"]),
            _createVNode(_component_SuggestionsTextInput, {
              suggestions: _ctx.suggestedKeywords(active),
              value: active.name,
              onInputChanged: ($event: any) => (patchActive({name: $event}))
            }, null, 8, ["suggestions", "value", "onInputChanged"]),
            _createVNode(_component_ion_item, null, {
              default: _withCtx(() => [
                _createVNode(_component_ion_label, { position: "stacked" }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('entity.item.note')), 1)
                  ]),
                  _: 1
                }),
                _createVNode(_component_ion_textarea, {
                  "auto-grow": true,
                  value: active.note,
                  onIonChange: ($event: any) => (patchActive({note: $event.detail.value}))
                }, null, 8, ["value", "onIonChange"])
              ]),
              _: 2
            }, 1024),
            _createVNode(_component_ion_item, { lines: "full" }, {
              default: _withCtx(() => [
                _createVNode(_component_ion_label, { position: "stacked" }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('entity.category.name')), 1)
                  ]),
                  _: 1
                }),
                _createVNode(_component_CategorySelect, {
                  categories: _ctx.categoryStore.categories,
                  "pre-selected-category": active.category,
                  onSelectedCategory: ($event: any) => (patchActive({category: $event}))
                }, null, 8, ["categories", "pre-selected-category", "onSelectedCategory"])
              ]),
              _: 2
            }, 1024)
          ]),
          _: 2
        }, 1024),
        _createVNode(_component_BarcodeEditor, {
          barcode: active.barcode,
          onBarcodeChanged: ($event: any) => (patchActive({barcode: $event})),
          onBarcodeDisabled: ($event: any) => (patchActive({barcode: null}))
        }, null, 8, ["barcode", "onBarcodeChanged", "onBarcodeDisabled"])
      ]),
      _: 1
    }, 8, ["modal-active", "on-confirm", "to-render", "top-bar-title", "onEditCanceled"])
  ], 64))
}