import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_EditItemAction = _resolveComponent("EditItemAction")!
  const _component_ion_col = _resolveComponent("ion-col")!
  const _component_MoveItemAction = _resolveComponent("MoveItemAction")!
  const _component_LostItemAction = _resolveComponent("LostItemAction")!
  const _component_DeleteItemAction = _resolveComponent("DeleteItemAction")!
  const _component_ion_row = _resolveComponent("ion-row")!
  const _component_ion_grid = _resolveComponent("ion-grid")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_ion_grid, {
      class: _normalizeClass({'no-padding': _ctx.noPadding})
    }, {
      default: _withCtx(() => [
        _createVNode(_component_ion_row, null, {
          default: _withCtx(() => [
            _createVNode(_component_ion_col, {
              class: _normalizeClass({'no-padding': _ctx.noPadding, 'ion-text-center': _ctx.centerIcons})
            }, {
              default: _withCtx(() => [
                _createVNode(_component_EditItemAction, {
                  item: _ctx.item,
                  onEditedItem: _ctx.updatedItem
                }, null, 8, ["item", "onEditedItem"])
              ]),
              _: 1
            }, 8, ["class"]),
            _createVNode(_component_ion_col, {
              class: _normalizeClass({'no-padding': _ctx.noPadding, 'ion-text-center': _ctx.centerIcons})
            }, {
              default: _withCtx(() => [
                _createVNode(_component_MoveItemAction, {
                  item: _ctx.item,
                  onMovedItem: _ctx.updatedItem
                }, null, 8, ["item", "onMovedItem"])
              ]),
              _: 1
            }, 8, ["class"]),
            _createVNode(_component_ion_col, {
              class: _normalizeClass({'no-padding': _ctx.noPadding, 'ion-text-center': _ctx.centerIcons})
            }, {
              default: _withCtx(() => [
                _createVNode(_component_LostItemAction, {
                  disabled: _ctx.isItemLost,
                  item: _ctx.item,
                  onDeclaredItemLost: _ctx.updatedItem
                }, null, 8, ["disabled", "item", "onDeclaredItemLost"])
              ]),
              _: 1
            }, 8, ["class"]),
            _createVNode(_component_ion_col, {
              class: _normalizeClass({'no-padding': _ctx.noPadding, 'ion-text-center': _ctx.centerIcons})
            }, {
              default: _withCtx(() => [
                _createVNode(_component_DeleteItemAction, {
                  item: _ctx.item,
                  onDeletedItem: _ctx.updatedItem
                }, null, 8, ["item", "onDeletedItem"])
              ]),
              _: 1
            }, 8, ["class"])
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["class"])
  ]))
}