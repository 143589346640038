import {ApolloClient, ApolloLink, concat, createHttpLink, InMemoryCache} from "@apollo/client/core"
import {persistCache} from "apollo3-cache-persist"
import {Preferences} from "@capacitor/preferences"
import * as Sentry from "@sentry/capacitor"
import {CapacitorPreferencesWrapper} from "@/shared/misc/capacitor-preferences-wrapper"
import {useAuthenticationStore} from "@/stores/authentication"

const authMiddleware: ApolloLink = new ApolloLink((operation, forward) => {
  const authStore = useAuthenticationStore()

  if (authStore.expiresSoon()) {
    console.log("Authentication token expires soon; requesting new token")
    try {
      (async () => await authStore._auth0.checkSession({cacheMode: "off"}))()
      console.log("Authentication token was successfully refreshed")
    } catch (e: any) {
      console.error("Authentication token could not be refreshed", e)
      Sentry.captureException(e)
      ;(async () => await authStore.login())()
    }
  }
  if (!authStore.jwt) {
    console.error("Authentication token is invalid; logging out")
    ;(async () => await authStore.logout())()
  }

  operation.setContext({
    headers: {
      "Authorization": `Bearer ${authStore.jwt}`,
    },
  })
  return forward(operation)
})

// HTTP connection to the API
const httpLink: ApolloLink = createHttpLink({
  // Needs to be an absolute URL
  uri: `${process.env.VUE_APP_BACKEND_URL}/graphql`,
})

// Cache implementation
const cache: InMemoryCache = new InMemoryCache()
await persistCache({
  cache,
  storage: new CapacitorPreferencesWrapper(Preferences),
})

// Create the apollo client
export default new ApolloClient({
  link: concat(authMiddleware, httpLink),
  cache: cache,
})
