import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_card_title = _resolveComponent("ion-card-title")!
  const _component_ion_card_header = _resolveComponent("ion-card-header")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_list = _resolveComponent("ion-list")!
  const _component_ion_card_content = _resolveComponent("ion-card-content")!
  const _component_ion_card = _resolveComponent("ion-card")!

  return (_openBlock(), _createBlock(_component_ion_card, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_card_header, null, {
        default: _withCtx(() => [
          _createVNode(_component_ion_card_title, null, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('general.links')), 1)
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_ion_card_content, null, {
        default: _withCtx(() => [
          _createVNode(_component_ion_list, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_button, {
                color: "light",
                href: "/onboarding"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('general.tutorial')), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_ion_button, {
                color: "light",
                href: "https://landing.bring.order2chaos.app/support",
                target: "_blank"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('general.feedback')), 1)
                ]),
                _: 1
              }),
              (_ctx.settingStore.isNative && !_ctx.settingStore.isIos)
                ? (_openBlock(), _createBlock(_component_ion_button, {
                    key: 0,
                    color: "light",
                    href: "https://bring.order2chaos.app/",
                    target: "_blank"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t('general.webApp')), 1)
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true),
              (!_ctx.settingStore.isIos)
                ? (_openBlock(), _createBlock(_component_ion_button, {
                    key: 1,
                    color: "light",
                    href: "https://landing.bring.order2chaos.app/",
                    target: "_blank"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t('general.website')), 1)
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true),
              _createVNode(_component_ion_button, {
                color: "light",
                href: "https://landing.bring.order2chaos.app/terms",
                target: "_blank"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('general.termsAndConditions')), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_ion_button, {
                color: "light",
                href: "https://landing.bring.order2chaos.app/privacy",
                target: "_blank"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('general.privacyPolicy')), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_ion_button, {
                color: "light",
                href: "https://landing.bring.order2chaos.app/support",
                target: "_blank"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('general.support')), 1)
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}