
import {IonContent, IonImg, IonPopover} from "@ionic/vue"
import {defineComponent, PropType} from "vue"
import bwipjs from "bwip-js"
import {useSettingStore} from "@/stores/setting"
import {BarcodeFormat, ParsedBarcode} from "@/gql/types"

export default defineComponent({
  components: {IonImg, IonPopover, IonContent},
  emits: ["is-valid"],
  props: {
    barcode: {
      required: true,
      type: Object as PropType<ParsedBarcode>,
    },
  },
  data() {
    return {
      barcodePreviewUri: "",
      popupIsActive: false,
    }
  },
  methods: {
    open() {
      this.popupIsActive = true
    },
    close() {
      this.popupIsActive = false
    },
    generateBarcodePreview(barcode: ParsedBarcode): string {
      const bwipjsOptions = {
        bcid: this.getBwipJsFormat(barcode.format),
        text: barcode.content,
        scaleX: 3,
        scaleY: 3,
      }
      const canvas = document.createElement("canvas")
      bwipjs.toCanvas(canvas, bwipjsOptions)
      return canvas.toDataURL()
    },
    getBwipJsFormat(format: BarcodeFormat): string {
      switch (format) {
        case BarcodeFormat.Aztec:
          return "azteccode"
        case BarcodeFormat.Code_39:
          return "code39"
        case BarcodeFormat.Code_93:
          return "code93"
        case BarcodeFormat.Code_128:
          return "code128"
        case BarcodeFormat.DataMatrix:
          return "datamatrix"
        case BarcodeFormat.Ean_8:
          return "ean8"
        case BarcodeFormat.Ean_13:
          return "ean13"
        case BarcodeFormat.Itf:
          return "interleaved2of5"
        case BarcodeFormat.Pdf_417:
          return "pdf417"
        case BarcodeFormat.QrCode:
          return "qrcode"
        case BarcodeFormat.UpcA:
          return "upca"
        case BarcodeFormat.UpcE:
          return "upce"
        default:
          throw new Error(`Unsupported barcode format: ${format}`)
      }
    },
  },
  watch: {
    barcode: {
      immediate: true,
      deep: true,
      handler(barcode: ParsedBarcode) {
        try {
          this.barcodePreviewUri = this.generateBarcodePreview(barcode)
          this.$emit("is-valid", true)
        } catch (e) {
          console.error(e)
          this.barcodePreviewUri = ""
          this.$emit("is-valid", false)
        }
      },
    },
  },
  mounted() {
    // If the browser is Safari on iOS or desktop, add the CSS rule to render the barcodes not too wide
    const isSafariOnIos = this.settingStore.isIos
    const isSafariOnDesktop = /^((?!chrome|android).)*safari/i.test(navigator.userAgent)
    if (isSafariOnIos || isSafariOnDesktop) {
      const style = document.createElement("style")
      style.innerHTML = ".small-image { flex: 0.1; }"
      document.head.appendChild(style)
    }
  },
  setup() {
    return {
      settingStore: useSettingStore(),
    }
  },
})
