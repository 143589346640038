import {defineStore} from "pinia"
import {useAuth0} from "@auth0/auth0-vue"
import {Browser} from "@capacitor/browser"
import {Preferences} from "@capacitor/preferences"
import {callbackUri} from "@/shared/clients/auth0-client"
import apolloClient from "@/shared/clients/apollo-client"
import {useSettingStore} from "@/stores/setting"
import {getUnixEpoch} from "@/shared/helpers"

export const useAuthenticationStore = defineStore("authentication", {
  state: () => ({
    isLoading: useAuth0().isLoading,
    isAuthenticated: useAuth0().isAuthenticated,
    error: useAuth0().error,
    user: useAuth0().user,
    _auth0: useAuth0(),
  }),
  getters: {
    isReady: (state) => state.isAuthenticated === true && state.isLoading === false,
    requiresLogin: (state) => state.isAuthenticated === false && state.isLoading === false,
    jwt: (state) => state._auth0.idTokenClaims?.__raw,
  },
  actions: {
    async login() {
      const settingStore = useSettingStore()

      if (settingStore.isIos) {
        await Browser.addListener("browserFinished", async () => {
          console.log("Browser was closed by user")
          await this.logout()
        })
      }

      await this._auth0.loginWithRedirect({
        openUrl: (url: string) => {
          console.log(`Attempting login via URL: ${url}`)
          return Browser.open({
            url: url,
            windowName: "_self",
          })
        },
      })
    },
    async logout() {
      await apolloClient.clearStore()
      await Preferences.clear()

      await this._auth0.logout({
        logoutParams: {
          returnTo: callbackUri,
        },
        openUrl: (url: string) => {
          console.log(`Attempting logout via URL: ${url}`)
          return Browser.open({
            url: url,
            windowName: "_self",
          })
        },
      })
    },
    expiresSoon(): boolean {
      return ((this._auth0.idTokenClaims?.exp ?? Infinity) - getUnixEpoch()) <= 60
    },
  },
})
