import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7877cc72"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  id: "container",
  class: "ion-padding"
}
const _hoisted_2 = ["innerHTML"]
const _hoisted_3 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_text = _resolveComponent("ion-text")!
  const _component_ion_card_title = _resolveComponent("ion-card-title")!
  const _component_ion_card_subtitle = _resolveComponent("ion-card-subtitle")!
  const _component_ion_img = _resolveComponent("ion-img")!
  const _component_ion_card_header = _resolveComponent("ion-card-header")!
  const _component_LanguageSelectCard = _resolveComponent("LanguageSelectCard")!
  const _component_ion_card_content = _resolveComponent("ion-card-content")!
  const _component_ion_card = _resolveComponent("ion-card")!
  const _component_swiper_slide = _resolveComponent("swiper-slide")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_list = _resolveComponent("ion-list")!
  const _component_swiper = _resolveComponent("swiper")!
  const _component_ion_button = _resolveComponent("ion-button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_swiper, {
      id: "slides-container",
      "centered-slides": true,
      keyboard: true,
      modules: _ctx.modules,
      navigation: {nextEl: '#continue-button'},
      pagination: {type: 'progressbar'},
      onReachEnd: _cache[0] || (_cache[0] = ($event: any) => (_ctx.reachedEnd = true))
    }, {
      default: _withCtx(() => [
        _createVNode(_component_swiper_slide, {
          id: "first-slide",
          class: "slide"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_ion_card, { class: "card" }, {
              default: _withCtx(() => [
                _createVNode(_component_ion_card_header, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_card_title, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_text, null, {
                          default: _withCtx(() => [
                            _createElementVNode("h3", {
                              innerHTML: _ctx.$t('onboarding[0].heading')
                            }, null, 8, _hoisted_2)
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_ion_card_subtitle, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_text, null, {
                          default: _withCtx(() => [
                            _createElementVNode("p", {
                              innerHTML: _ctx.$t('onboarding[0].subheading')
                            }, null, 8, _hoisted_3)
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_ion_img, {
                      class: "image",
                      src: "../img/onboarding/mobile-five-screens.png"
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_ion_card_content, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_LanguageSelectCard, { id: "language-select" })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.slides, (slide) => {
          return (_openBlock(), _createBlock(_component_swiper_slide, {
            key: slide.heading,
            class: "slide"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_card, { class: "card" }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_card_header, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_card_title, null, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(slide.heading), 1)
                        ]),
                        _: 2
                      }, 1024),
                      _createVNode(_component_ion_card_subtitle, null, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(slide.subheading), 1)
                        ]),
                        _: 2
                      }, 1024),
                      _createVNode(_component_ion_img, {
                        src: slide.image,
                        class: "image"
                      }, null, 8, ["src"])
                    ]),
                    _: 2
                  }, 1024),
                  _createVNode(_component_ion_card_content, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_list, { lines: "full" }, {
                        default: _withCtx(() => [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(slide.instructions, (instruction) => {
                            return (_openBlock(), _createBlock(_component_ion_item, {
                              key: instruction,
                              class: "instruction"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_ion_label, null, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(instruction), 1)
                                  ]),
                                  _: 2
                                }, 1024)
                              ]),
                              _: 2
                            }, 1024))
                          }), 128))
                        ]),
                        _: 2
                      }, 1024)
                    ]),
                    _: 2
                  }, 1024)
                ]),
                _: 2
              }, 1024)
            ]),
            _: 2
          }, 1024))
        }), 128))
      ]),
      _: 1
    }, 8, ["modules"]),
    _createVNode(_component_ion_button, {
      id: "continue-button",
      onClick: _ctx.continueButtonClicked
    }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.$t("button.continue")), 1)
      ]),
      _: 1
    }, 8, ["onClick"])
  ]))
}