import {defineStore} from "pinia"
import {markRaw} from "vue"
import apolloClient from "@/shared/clients/apollo-client"
import {ObservableQuery} from "@apollo/client/core"
import {CapacitorPurchases} from "@capgo/capacitor-purchases"
import gql from "graphql-tag"
import {Subscription, SubscriptionTier} from "@/gql/types"
import {useAuthenticationStore} from "@/stores/authentication"
import {useSettingStore} from "@/stores/setting"
import {convertGraphQlErrorToResponseInfo} from "@/shared/helpers"

export const useAccountStore = defineStore("account", {
  state: () => ({
    subscription: undefined as unknown as Subscription,
    _observableQuery: undefined as unknown as ObservableQuery<any, any>,
  }),
  getters: {
    isReady: (state) => state.subscription !== undefined,
    accountId: (state) => state.subscription?.accountId,
    isAllowedToUpgrade: (state) => state.subscription?.tier === SubscriptionTier.Free,
  },
  actions: {
    async refetchSubscription() {
      await this._observableQuery?.refetch()
    },
    async watchSubscription() {
      // Required to do this since `watchQuery` cannot properly be awaited
      this.subscription = (await apolloClient.query({
        query: gql`
            query getSubscription {
                getSubscription {
                    accountId
                    tier
                    validUntil
                    billingProvider
                }
            }
        `,
        fetchPolicy: "network-only",
      })).data["getSubscription"]

      const observableQuery = apolloClient.watchQuery({
        query: gql`
            query getSubscription {
                getSubscription {
                    accountId
                    tier
                    validUntil
                    billingProvider
                }
            }
        `,
        fetchPolicy: "cache-and-network",
        pollInterval: (1000 * 60 * 5),
      })
      observableQuery.subscribe({
        next: ({data}) => this.subscription = data["getSubscription"],
        error: (e) => convertGraphQlErrorToResponseInfo(e),
      })
      this._observableQuery = markRaw(observableQuery)

      if (useSettingStore().isNative) await this.initializeInAppPurchases()
    },
    async getDataExport() {
      const response = await apolloClient.query({
        query: gql`
            query getDataExport {
                getDataExport
            }
        `,
        fetchPolicy: "network-only",
      })
      return response.data["getDataExport"] as string
    },
    async deleteAccount() {
      await apolloClient.mutate({
        mutation: gql`
            mutation deleteAccount {
                deleteAccount
            }
        `,
      })
    },
    async initializeInAppPurchases() {
      const authStore = useAuthenticationStore()
      const settingStore = useSettingStore()

      if (settingStore.isAndroid) {
        await CapacitorPurchases.setDebugLogsEnabled({enabled: process.env.NODE_ENV === "development"})
        await CapacitorPurchases.setup({
          apiKey: process.env.VUE_APP_REVENUE_CAT_ANDROID_KEY as string,
          appUserID: authStore.user.email,
        })
      } else if (settingStore.isIos) {
        await CapacitorPurchases.setDebugLogsEnabled({enabled: process.env.NODE_ENV === "development"})
        await CapacitorPurchases.setup({
          apiKey: process.env.VUE_APP_REVENUE_CAT_IOS_KEY as string,
          appUserID: authStore.user.email,
        })
      }
    },
  },
})
