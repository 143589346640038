
import {IonButton, IonButtons, IonContent, IonHeader, IonModal, IonTitle, IonToolbar, useBackButton} from "@ionic/vue"
import {defineComponent} from "vue"
import {BarcodeScanner, CameraDirection, SupportedFormat} from "@capacitor-community/barcode-scanner"
import * as Sentry from "@sentry/capacitor"
import {BarcodeFormat, ParsedBarcode} from "@/gql/types"
import {getEnumByValue} from "@/shared/helpers"

export default defineComponent({
  components: {IonModal, IonHeader, IonToolbar, IonButtons, IonButton, IonTitle, IonContent},
  emits: ["scanned-barcode"],
  data() {
    return {
      scannerActive: false,
    }
  },
  methods: {
    async startScan() {
      const options = {
        cameraDirection: CameraDirection.BACK,
        targetedFormats: this.targetedBarcodeFormats,
      }

      try {
        await BarcodeScanner.prepare(options)
        await BarcodeScanner.checkPermission({force: true})
        await this.hideBackground()
      } catch (e) {
        console.error(e)
        Sentry.captureException(e)
        await this.showBackground()
        return
      }

      const result = await BarcodeScanner.startScan(options)
      await this.stopScan()

      if (result.hasContent) {
        const parsedBarcode: ParsedBarcode = {
          content: result.content!, // eslint-disable-line @typescript-eslint/no-non-null-assertion
          format: result.format! as BarcodeFormat, // eslint-disable-line @typescript-eslint/no-non-null-assertion
        }
        this.$emit("scanned-barcode", parsedBarcode)
        console.log(`Successfully scanned barcode: ${parsedBarcode}`)
      } else {
        this.$emit("scanned-barcode", null)
        console.warn("Failed to scan a barcode")
      }
    },
    async stopScan() {
      await this.showBackground()
      await BarcodeScanner.stopScan()
    },
    async hideBackground() {
      await BarcodeScanner.hideBackground()
      document.body.classList.add(this.scannerActiveBodyClass)
      this.scannerActive = true
    },
    async showBackground() {
      await BarcodeScanner.showBackground()
      document.body.classList.remove(this.scannerActiveBodyClass)
      this.scannerActive = false
    },
  },
  created() {
    useBackButton(999, async (next) => {
      if (this.scannerActive) {
        await this.stopScan()
      } else {
        next()
      }
    })
  },
  async deactivated() {
    await this.stopScan()
  },
  async beforeUnmount() {
    await this.stopScan()
  },
  setup() {
    return {
      targetedBarcodeFormats: Object.values(BarcodeFormat).map(bf => getEnumByValue(SupportedFormat, bf)) as unknown as SupportedFormat[],
      scannerActiveBodyClass: "scanner-active",
    }
  },
})
