
import {actionSheetController, IonButton} from "@ionic/vue"
import {defineComponent} from "vue"
import {useAccountStore} from "@/stores/account"
import {useAuthenticationStore} from "@/stores/authentication"

export default defineComponent({
  components: {
    IonButton,
  },
  methods: {
    async exportData() {
      if (!(await this.confirm())) return
      this.initiateFileDownload(await this.accountStore.getDataExport())
      console.log("Finished data export as file download")
    },
    initiateFileDownload(fileContent: string) {
      const blob = new Blob([fileContent], {type: "text/tab-separated-values"})
      const url = URL.createObjectURL(blob)

      const link = document.createElement("a")
      link.href = url
      link.download = "bringorder2chaos-export.tsv"
      link.click()

      URL.revokeObjectURL(url)
    },
    async confirm() {
      const actionSheet = await actionSheetController.create({
        header: this.$t("prompt.confirmDataExport"),
        buttons: [
          {
            text: this.$t("button.confirm"),
            role: "destructive",
          },
          {
            text: this.$t("button.cancel"),
            role: "cancel",
          },
        ],
      })
      await actionSheet.present()
      const result = await actionSheet.onDidDismiss()
      return result.role === "destructive"
    },
  },
  setup() {
    return {
      accountStore: useAccountStore(),
      authStore: useAuthenticationStore(),
    }
  },
})
