
import {
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonCol,
  IonGrid,
  IonRow,
  toastController,
} from "@ionic/vue"
import {defineComponent} from "vue"
import {useAuthenticationStore} from "@/stores/authentication"
import {useSettingStore} from "@/stores/setting"
import {useCategoryStore} from "@/stores/category"
import CategorySelect from "@/components/CategorySelect.vue"
import CreateCategoryAction from "@/components/CreateCategoryAction.vue"
import DeleteCategoryAction from "@/components/DeleteCategoryAction.vue"
import EditCategoryAction from "@/components/EditCategoryAction.vue"
import {Category} from "@/gql/types"
import {ResponseInfo} from "@/shared/helpers"

export default defineComponent({
  components: {
    EditCategoryAction,
    DeleteCategoryAction,
    CreateCategoryAction,
    CategorySelect,
    IonCard,
    IonCardHeader,
    IonCardTitle,
    IonCardContent,
    IonGrid,
    IonRow,
    IonCol,
  },
  data() {
    return {
      selectedCategory: null as Category | null,
    }
  },
  methods: {
    selectDefaultCategory() {
      this.selectedCategory = this.categoryStore.categories[0]
    },
    async presentResponseInfoToast(responseInfo: ResponseInfo) {
      const toast = await toastController.create({
        message: `${this.$t(responseInfo.messageKey)}!`,
        duration: 5560,
        position: "top",
        buttons: [
          {
            text: this.$t("button.ok"),
            role: "cancel",
          },
        ],
        color: responseInfo.isError ? "danger" : "",
      })
      await toast.present()
    },
    async deletedCategory(responseInfo: ResponseInfo) {
      await this.presentResponseInfoToast(responseInfo)
      this.selectDefaultCategory()
    },
  },
  created() {
    this.selectDefaultCategory()
  },
  setup() {
    return {
      authStore: useAuthenticationStore(),
      settingStore: useSettingStore(),
      categoryStore: useCategoryStore(),
    }
  },
})
