
import {IonButton, IonItem, IonLabel, IonList, IonTextarea} from "@ionic/vue"
import {defineComponent, PropType} from "vue"
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome"
import EditAction from "@/components/EditAction.vue"
import CategorySelect from "@/components/CategorySelect.vue"
import BarcodeEditor from "@/components/BarcodeEditor.vue"
import SuggestionsTextInput from "@/components/SuggestionsTextInput.vue"
import ImageCapture from "@/components/ImageCapture.vue"
import {useCategoryStore} from "@/stores/category"
import {useItemStore} from "@/stores/item"
import {useBarcodeStore} from "@/stores/barcode"
import {convertGraphQlErrorToResponseInfo, createSuccessfulResponseInfo} from "@/shared/helpers"
import {Item} from "@/gql/types"

export default defineComponent({
  components: {
    ImageCapture,
    SuggestionsTextInput,
    BarcodeEditor,
    EditAction,
    CategorySelect,
    IonList,
    IonItem,
    IonLabel,
    IonTextarea,
    IonButton,
    FontAwesomeIcon,
  },
  emits: ["edited-item"],
  props: {
    item: {
      required: true,
      type: Object as PropType<Item>,
    },
  },
  data() {
    return {
      modalActive: false,
    }
  },
  methods: {
    openModal() {
      this.modalActive = true
    },
    closeModal() {
      this.modalActive = false
    },
    async confirmItemEdit(editedItem: Item) {
      try {
        await this.itemStore.editItem(editedItem)
        return createSuccessfulResponseInfo("success.item.edit")
      } catch (e) {
        return convertGraphQlErrorToResponseInfo(e)
      } finally {
        this.closeModal()
      }
    },
    suggestedKeywords(active: any): string[] {
      return [...(active.barcode?.labels ?? []), ...(active.image?.labels ?? [])]
    },
  },
  setup() {
    return {
      itemStore: useItemStore(),
      categoryStore: useCategoryStore(),
      barcodeStore: useBarcodeStore(),
    }
  },
})
