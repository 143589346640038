
import {IonSelect, IonSelectOption} from "@ionic/vue"
import {defineComponent, PropType} from "vue"
import {useInventoryStore} from "@/stores/inventory"
import {useSettingStore} from "@/stores/setting"
import {Inventory} from "@/gql/types"

export default defineComponent({
  components: {IonSelect, IonSelectOption},
  emits: ["selected-inventory"],
  props: {
    inventories: {
      required: true,
      type: Array as PropType<Inventory[]>,
    },
    preSelectedInventory: {
      required: true,
      type: Object as PropType<Inventory>,
    },
  },
  data() {
    return {
      selectedInventoryId: this.preSelectedInventory.id,
    }
  },
  methods: {
    selectInventory(inventory: Inventory) {
      this.$emit("selected-inventory", inventory)
    },
  },
  watch: {
    selectedInventoryId: {
      handler(inventoryId) {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        this.selectInventory(this.inventoryStore.inventories.allAccessible.find(i => i.id === inventoryId)!)
      },
    },
  },
  setup() {
    return {
      inventoryStore: useInventoryStore(),
      settingStore: useSettingStore(),
    }
  },
})
