
import {IonButton, IonItem, IonLabel, IonList} from "@ionic/vue"
import {defineComponent, PropType} from "vue"
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome"
import LocationSelect from "@/components/LocationSelect.vue"
import ModalAction from "@/components/ModalAction.vue"
import {useLocationStore} from "@/stores/location"
import {Item} from "@/gql/types"
import {convertGraphQlErrorToResponseInfo, createSuccessfulResponseInfo} from "@/shared/helpers"
import {useItemStore} from "@/stores/item"

export default defineComponent({
  components: {
    IonButton,
    IonList,
    IonItem,
    IonLabel,
    ModalAction,
    LocationSelect,
    FontAwesomeIcon,
  },
  emits: ["moved-item"],
  props: {
    item: {
      required: true,
      type: Object as PropType<Item>,
    },
  },
  data() {
    return {
      modalActive: false,
      selectedLocation: this.item.location,
    }
  },
  methods: {
    openModal() {
      this.modalActive = true
    },
    closeModal() {
      this.modalActive = false
    },
    async confirmItemMove() {
      try {
        await this.itemStore.moveItem(this.item, this.selectedLocation)
        return createSuccessfulResponseInfo("success.item.move")
      } catch (e) {
        return convertGraphQlErrorToResponseInfo(e)
      } finally {
        this.closeModal()
      }
    },
  },
  setup() {
    return {
      itemStore: useItemStore(),
      locationStore: useLocationStore(),
    }
  },
})
