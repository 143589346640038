
import {IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonItem, IonList, toastController} from "@ionic/vue"
import {defineComponent} from "vue"
import FilterSortToggle from "@/components/FilterSortToggle.vue"
import InventorySelect from "@/components/InventorySelect.vue"
import LanguageSelect from "@/components/LanguageSelect.vue"
import {useInventoryStore} from "@/stores/inventory"
import {useSettingStore} from "@/stores/setting"
import {Inventory} from "@/gql/types"

export default defineComponent({
  components: {
    LanguageSelect,
    InventorySelect,
    FilterSortToggle,
    IonCard,
    IonCardHeader,
    IonCardTitle,
    IonCardContent,
    IonList,
    IonItem,
  },
  methods: {
    async selectedInventory(inventory: Inventory) {
      this.settingStore.resetInventorySpecificSettings()
      await this.$apollo.getClient().clearStore()
      await this.inventoryStore.selectActiveInventory(inventory)
      await this.presentToast(`${this.$t("success.inventory.changeActive")}!`)
    },
    async presentToast(message: string) {
      const toast = await toastController.create({
        message: message,
        duration: 5560,
        position: "top",
        buttons: [
          {
            text: this.$t("button.ok"),
            role: "cancel",
          },
        ],
      })
      await toast.present()
    },
  },
  setup() {
    return {
      inventoryStore: useInventoryStore(),
      settingStore: useSettingStore(),
    }
  },
})
