import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4b685784"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "container"
}
const _hoisted_2 = { slot: "content" }
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = { class: "button-container" }
const _hoisted_5 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_segment_button = _resolveComponent("ion-segment-button")!
  const _component_ion_segment = _resolveComponent("ion-segment")!
  const _component_ion_text = _resolveComponent("ion-text")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_list = _resolveComponent("ion-list")!
  const _component_ion_spinner = _resolveComponent("ion-spinner")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_accordion = _resolveComponent("ion-accordion")!
  const _component_ion_accordion_group = _resolveComponent("ion-accordion-group")!
  const _component_ion_loading = _resolveComponent("ion-loading")!

  return (_ctx.orderedOfferings)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (!_ctx.settingStore.isAndroid)
          ? (_openBlock(), _createBlock(_component_ion_segment, {
              key: 0,
              modelValue: _ctx.period,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.period) = $event))
            }, {
              default: _withCtx(() => [
                _createVNode(_component_ion_segment_button, { value: "monthly" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_label, null, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t('general.monthly')), 1)
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_ion_segment_button, { value: "annual" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_label, null, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t('general.annual')), 1)
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }, 8, ["modelValue"]))
          : _createCommentVNode("", true),
        _createVNode(_component_ion_accordion_group, {
          multiple: true,
          expand: "inset"
        }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.orderedOfferings, (offering) => {
              return (_openBlock(), _createBlock(_component_ion_accordion, {
                key: offering.identifier,
                value: offering.identifier
              }, {
                default: _withCtx(() => [
                  _createTextVNode("> "),
                  _createVNode(_component_ion_item, { slot: "header" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_label, { class: "offering-header-text" }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_text, { class: "title" }, {
                            default: _withCtx(() => [
                              _createElementVNode("h1", null, _toDisplayString(_ctx.$t(`subscription.${offering.identifier}.title`)), 1)
                            ]),
                            _: 2
                          }, 1024),
                          _createVNode(_component_ion_text, { class: "price" }, {
                            default: _withCtx(() => [
                              _createElementVNode("h2", null, _toDisplayString(offering[_ctx.period]!.product.priceString) + " / " + _toDisplayString(_ctx.$t(`general.${_ctx.period}`)), 1)
                            ]),
                            _: 2
                          }, 1024)
                        ]),
                        _: 2
                      }, 1024)
                    ]),
                    _: 2
                  }, 1024),
                  _createElementVNode("div", _hoisted_2, [
                    _createVNode(_component_ion_list, {
                      class: "feature-list",
                      lines: "full"
                    }, {
                      default: _withCtx(() => [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.buildi18nArray(`subscription.${offering.identifier}.features`), (feature) => {
                          return (_openBlock(), _createBlock(_component_ion_item, { key: feature }, {
                            default: _withCtx(() => [
                              _createVNode(_component_ion_label, { class: "feature" }, {
                                default: _withCtx(() => [
                                  _createElementVNode("span", { innerHTML: feature }, null, 8, _hoisted_3)
                                ]),
                                _: 2
                              }, 1024)
                            ]),
                            _: 2
                          }, 1024))
                        }), 128))
                      ]),
                      _: 2
                    }, 1024),
                    _createElementVNode("div", _hoisted_4, [
                      _createVNode(_component_ion_button, {
                        disabled: _ctx.isLoading,
                        class: "select-button",
                        color: "primary",
                        onClick: ($event: any) => (_ctx.purchasePackage(offering[_ctx.period]!))
                      }, {
                        default: _withCtx(() => [
                          (!_ctx.isLoading)
                            ? (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(_ctx.$t('button.select')), 1))
                            : (_openBlock(), _createBlock(_component_ion_spinner, { key: 1 }))
                        ]),
                        _: 2
                      }, 1032, ["disabled", "onClick"])
                    ])
                  ])
                ]),
                _: 2
              }, 1032, ["value"]))
            }), 128))
          ]),
          _: 1
        })
      ]))
    : (_openBlock(), _createBlock(_component_ion_loading, { key: 1 }))
}