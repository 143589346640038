import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_select_option = _resolveComponent("ion-select-option")!
  const _component_ion_select = _resolveComponent("ion-select")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_ion_label, {
      position: _ctx.stackedLabel ? 'stacked' : null
    }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.$t('general.language')), 1)
      ]),
      _: 1
    }, 8, ["position"]),
    _createVNode(_component_ion_select, {
      modelValue: _ctx.settingStore.persistable.preferences.language,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.settingStore.persistable.preferences.language) = $event)),
      "cancel-text": _ctx.$t('button.cancel'),
      interface: _ctx.settingStore.selectOptionInterfaceType,
      "ok-text": _ctx.$t('button.ok')
    }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.$i18n.availableLocales, (translation) => {
          return (_openBlock(), _createBlock(_component_ion_select_option, {
            key: translation,
            value: translation
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.languageNameMapping[translation]), 1)
            ]),
            _: 2
          }, 1032, ["value"]))
        }), 128))
      ]),
      _: 1
    }, 8, ["modelValue", "cancel-text", "interface", "ok-text"])
  ], 64))
}