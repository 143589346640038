import {defineStore} from "pinia"
import {markRaw, watch} from "vue"
import apolloClient from "@/shared/clients/apollo-client"
import {ObservableQuery} from "@apollo/client/core"
import {useInventoryStore} from "@/stores/inventory"
import {Location, NewLocation, ParsedBarcode} from "@/gql/types"
import gql from "graphql-tag"
import {convertGraphQlErrorToResponseInfo, getRotatingElement} from "@/shared/helpers"

export const useLocationStore = defineStore("location", {
  state: () => ({
    allLocations: {id: "", name: "*"} as Location,
    locations: undefined as unknown as Location[],
    _observableQuery: undefined as unknown as ObservableQuery<any, any>,
    _inventoryStore: useInventoryStore(),
  }),
  getters: {
    isReady: (state): boolean => state.locations !== undefined,
    augmentedLocations: (state): Location[] => [state.allLocations, ...state.locations],
    rootLocation: (state): Location => state.locations.find(l => l.name === "~")!, // eslint-disable-line @typescript-eslint/no-non-null-assertion
    lostLocation: (state): Location => state.locations.find(l => l.name === "?")!, // eslint-disable-line @typescript-eslint/no-non-null-assertion
  },
  actions: {
    isModifiable(location: Location) {
      return this.isUserDefined(location)
    },
    allowsNested(location: Location) {
      return location.id !== this.lostLocation.id
    },
    isMovable(location: Location) {
      return this.isUserDefined(location)
    },
    isUserDefined(location: Location) {
      return location.id !== this.rootLocation.id && location.id !== this.lostLocation.id
    },
    async createLocation(newLocation: NewLocation) {
      await apolloClient.mutate({
        mutation: gql`
            mutation createLocation($inventoryId: ID!, $newLocation: NewLocation!) {
                createLocation(inventoryId: $inventoryId, newLocation: $newLocation) {
                    id
                    name
                    barcode {
                        content
                        format
                    }
                    location {
                        id
                        name
                        location {
                            id
                            name
                            location {
                                id
                                name
                            }
                        }
                    }
                }
            }
        `,
        variables: {
          inventoryId: this._inventoryStore.inventories.active.id,
          newLocation: newLocation,
        },
      })
      await this.refetchLocations()
    },
    async editLocation(editedLocation: Location, assignBarcode: boolean) {
      await apolloClient.mutate({
        mutation: gql`
            mutation editLocation($locationId: ID!, $newName: String!) {
                editLocation(locationId: $locationId, newName: $newName)
            }
        `,
        variables: {
          locationId: editedLocation.id,
          newName: editedLocation.name,
        },
      })

      if (assignBarcode) {
        await apolloClient.mutate({
          mutation: gql`
              mutation assignLocationBarcode($locationId: ID!, $newBarcode: ParsedBarcode!) {
                  assignLocationBarcode(locationId: $locationId, newBarcode: $newBarcode)
              }
          `,
          variables: {
            locationId: editedLocation.id,
            newBarcode: editedLocation.barcode ? {
              content: editedLocation.barcode.content,
              format: editedLocation.barcode.format,
            } : null,
          },
        })
      }

      await this.refetchLocations()
    },
    async moveLocation(location: Location, targetLocation: Location) {
      await apolloClient.mutate({
        mutation: gql`
            mutation moveLocation($locationId: ID!, $newLocationId: ID!) {
                moveLocation(locationId: $locationId, newLocationId: $newLocationId)
            }
        `,
        variables: {
          locationId: location.id,
          newLocationId: targetLocation.id,
        },
      })
      await this.refetchLocations()
    },
    async deleteLocation(location: Location) {
      await apolloClient.mutate({
        mutation: gql`
            mutation deleteLocation($locationId: ID!) {
                deleteLocation(locationId: $locationId)
            }
        `,
        variables: {
          locationId: location.id,
        },
      })
      setTimeout(async () => await this.refetchLocations(), 1500)
    },
    async refetchLocations() {
      await this._observableQuery?.refetch()
    },
    watchLocations() {
      const observableQuery = apolloClient.watchQuery({
        query: gql`
            query getLocations($inventoryId: ID!) {
                getLocations(inventoryId: $inventoryId) {
                    id
                    name
                    barcode {
                        content
                        format
                    }
                    location {
                        id
                        name
                        location {
                            id
                            name
                            location {
                                id
                                name
                            }
                        }
                    }
                }
            }
        `,
        variables: {
          inventoryId: this._inventoryStore.inventories.active.id,
        },
        pollInterval: (1000 * 60),
      })
      observableQuery.subscribe({
        next: ({data}) => this.locations = data["getLocations"],
        error: (e) => convertGraphQlErrorToResponseInfo(e),
      })
      watch(
        this._inventoryStore.inventories,
        ({active}) => observableQuery.setVariables({inventoryId: active.id}),
      )
      this._observableQuery = markRaw(observableQuery)
    },
    async findByBarcode(barcode: ParsedBarcode) {
      const response = await apolloClient.query({
        query: gql`
            query findLocationByBarcode($inventoryId: ID!, $barcode: ParsedBarcode!) {
                findLocationByBarcode(inventoryId: $inventoryId, barcode: $barcode) {
                    id
                    name
                    barcode {
                        content
                        format
                    }
                    location {
                        id
                        name
                        location {
                            id
                            name
                            location {
                                id
                                name
                            }
                        }
                    }
                }
            }
        `,
        variables: {
          inventoryId: this._inventoryStore.inventories.active.id,
          barcode: barcode,
        },
        fetchPolicy: "network-only",
      })
      return response.data["findLocationByBarcode"] as Location | null
    },
    /**
     * Returns an array of locations including the given one according to its hierarchy.
     * Note that the parent locations have to be loaded to be returned.
     */
    getLocationHierarchy(location: Location): Location[] {
      let currentLocation: Location = location
      const locationsHierarchy: Location[] = [currentLocation]
      while (currentLocation.location && currentLocation.location.id !== this.rootLocation.id) {
        currentLocation = currentLocation.location
        locationsHierarchy.push(currentLocation)
      }
      return locationsHierarchy
    },
    /**
     * Returns a different location roughly every 17 minutes.
     */
    rotatingLocation(): Location {
      return getRotatingElement(this.locations)
    },
  },
})
