
import {alertController, IonApp, IonLoading, IonRouterOutlet, useBackButton, useIonRouter} from "@ionic/vue"
import {defineComponent, watch} from "vue"
import {App as CapApp} from "@capacitor/app"
import {Browser} from "@capacitor/browser"
import {SplashScreen} from "@capacitor/splash-screen"
import * as Sentry from "@sentry/capacitor"
import {useLocationStore} from "@/stores/location"
import {useCategoryStore} from "@/stores/category"
import {useAuthenticationStore} from "@/stores/authentication"
import {useInventoryStore} from "@/stores/inventory"
import {useAccountStore} from "@/stores/account"
import {useItemStore} from "@/stores/item"
import {useSettingStore} from "@/stores/setting"
import {auth0Domain} from "@/shared/clients/auth0-client"
import {convertGraphQlErrorToResponseInfo} from "@/shared/helpers"

export default defineComponent({
  components: {IonApp, IonRouterOutlet, IonLoading},
  data() {
    return {
      errorOccurred: false,
      backendIsUnavailable: false,
    }
  },
  computed: {
    dataIsReady() {
      return this.accountStore.isReady && this.inventoryStore.isReady && this.categoryStore.isReady && this.locationStore.isReady && this.itemStore.isReady
    },
  },
  methods: {
    async checkForRerouting() {
      if (this.$route.path !== this.settingStore.persistable.miscellaneous.requestedPath) {
        this.router.replace(this.settingStore.persistable.miscellaneous.requestedPath)
        this.settingStore.persistable.miscellaneous.requestedPath = null
        return
      } else {
        this.settingStore.persistable.miscellaneous.requestedPath = null
      }

      if (!this.settingStore.persistable.miscellaneous.onboardingPresented && !this.$route.path.includes("onboarding")) {
        await this.router.replace("/onboarding")
        return
      }

      if (!this.settingStore.persistable.miscellaneous.subscriptionsPresented && this.accountStore.isAllowedToUpgrade && !this.$route.path.includes("subscribe")) {
        await this.presentSubscriptionAlert()
        return
      }
    },
    async presentUnavailabilityAlert(message: string) {
      const buttons = [
        {
          text: this.$t("button.retry"),
          role: "retry",
        },
        {
          text: this.$t("button.logout"),
          role: "logout",
        },
      ]
      if (this.settingStore.isNative) {
        buttons.push({
          text: this.$t("button.quit"),
          role: "destructive",
        })
      }
      const alert = await alertController.create({
        header: this.$t("failure.general.error"),
        message: message,
        buttons: buttons,
        backdropDismiss: false,
        keyboardClose: false,
      })
      await alert.present()
      const result = await alert.onDidDismiss()
      switch (result.role) {
        case "retry":
          await this.reload()
          break
        case "logout":
          await this.authStore.logout()
          break
        case "destructive":
          await CapApp.exitApp()
          break
      }
    },
    async presentSubscriptionAlert() {
      const alert = await alertController.create({
        header: this.$t("general.subscription"),
        message: this.$t("prompt.subscriptionSelectionConfirm"),
        buttons: [
          {
            text: this.$t("button.ok"),
            role: "confirm",
          },
          {
            text: this.$t("button.cancel"),
            role: "cancel",
          },
        ],
      })
      await alert.present()
      const result = await alert.onDidDismiss()
      if (result.role === "confirm") this.router.push("/settings/subscribe")
      this.settingStore.persistable.miscellaneous.subscriptionsPresented = true
    },
    reload() {
      window.location.reload()
    },
  },
  watch: {
    backendIsUnavailable: {
      async handler(backendIsUnavailable) {
        if (backendIsUnavailable) await this.presentUnavailabilityAlert(`${this.$t("failure.general.serverNotAvailable")}!`)
      },
    },
  },
  async created() {
    if (!this.settingStore.persistable.miscellaneous.requestedPath) this.settingStore.persistable.miscellaneous.requestedPath = this.$route.path

    CapApp.addListener("appUrlOpen", async ({url}) => {
      console.log(`Handling 'appUrlOpen' event for URL: ${url}`)

      try {
        await Browser.removeAllListeners()
        await Browser.close()
      } catch (e) {
        // On some platforms this is a no-op that throws an exception
      }

      if (url.includes(auth0Domain)) {
        if (url.includes("state") && (url.includes("code") || url.includes("error"))) {
          console.log("Handling Auth0 login callback")
          try {
            await this.authStore._auth0.handleRedirectCallback(url)
          } catch (e) {
            console.error("Error handling Auth0 login callback", e)
          }
        } else {
          console.log("Handling Auth0 logout callback")
          await this.authStore.login()
        }
      }

      await this.reload()
    })

    useBackButton(-1, () => {
      if (!this.router.canGoBack()) CapApp.exitApp()
    })

    const unavailableTimeout = setTimeout(async () => {
      clearInterval(loginCheckInterval)
      if (!this.authStore.isReady) {
        console.log("Authentication connection is unavailable")
        await this.authStore.login()
      } else if (!this.dataIsReady) {
        console.log("Backend connection is unavailable")
        this.backendIsUnavailable = true
      } else {
        console.log("Authentication and backend connections are available")
      }
    }, 10000)

    const loginCheckInterval = setInterval(async () => {
      if (this.authStore.requiresLogin) {
        clearInterval(loginCheckInterval)
        clearTimeout(unavailableTimeout)
        console.log("Attempting login")
        await this.authStore.login()
      }
    }, 1000)

    watch(
      this.$auth0.idTokenClaims,
      async (token) => {
        if (token) {
          try {
            if (!this.accountStore.isReady) await this.accountStore.watchSubscription()
            if (!this.inventoryStore.isReady) await this.inventoryStore.watchInventories()
            if (!this.categoryStore.isReady) this.categoryStore.watchCategories()
            if (!this.locationStore.isReady) this.locationStore.watchLocations()
            if (!this.itemStore.isReady) this.itemStore.watchItems()

            Sentry.setUser({id: this.accountStore.accountId, email: this.authStore.user.email})
          } catch (e) {
            clearTimeout(unavailableTimeout)
            console.error("Failed to fetch the available inventories and initialize the required queries", e)
            this.errorOccurred = true

            const responseInfo = convertGraphQlErrorToResponseInfo(e)

            if (this.settingStore.persistable.miscellaneous.retryCount <= 1) {
              this.settingStore.persistable.miscellaneous.retryCount += 1
              await this.reload()
            } else {
              this.settingStore.persistable.miscellaneous.retryCount = 0
            }

            setTimeout(async () => {
              await this.presentUnavailabilityAlert(`${this.$t(responseInfo.messageKey, {emailAddress: this.authStore.user.email})}!`)
            }, 2500)
          }

          await this.checkForRerouting()
        }
      },
      {immediate: true},
    )

    watch(
      this.settingStore.persistable.preferences,
      (preferences) => this.$i18n.locale = preferences.language.toLowerCase(),
      {immediate: true},
    )

    await SplashScreen.hide()
  },
  setup() {
    return {
      authStore: useAuthenticationStore(),
      inventoryStore: useInventoryStore(),
      accountStore: useAccountStore(),
      itemStore: useItemStore(),
      categoryStore: useCategoryStore(),
      locationStore: useLocationStore(),
      settingStore: useSettingStore(),
      router: useIonRouter(),
    }
  },
})
