
import {IonButton} from "@ionic/vue"
import {defineComponent, PropType} from "vue"
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome"
import CreateLocationModal from "@/components/CreateLocationModal.vue"
import {Location, NewLocation, ParsedBarcode} from "@/gql/types"
import {useInventoryStore} from "@/stores/inventory"
import {useLocationStore} from "@/stores/location"
import {useSettingStore} from "@/stores/setting"
import {convertGraphQlErrorToResponseInfo, createSuccessfulResponseInfo, ResponseInfo} from "@/shared/helpers"

export default defineComponent({
  components: {
    CreateLocationModal,
    IonButton,
    FontAwesomeIcon,
  },
  emits: ["created-location"],
  props: {
    parentLocation: {
      required: true,
      type: Object as PropType<Location>,
    },
    disabled: {
      required: false,
      default: false,
      type: Boolean,
    },
  },
  computed: {
    buttonSize() {
      return window.innerWidth < 1280 ? "default" : "large"
    },
  },
  data() {
    return {
      modalActive: false,
      newLocation: {
        name: "",
        locationId: this.parentLocation.id,
        barcode: null as ParsedBarcode | null,
      } as NewLocation,
    }
  },
  methods: {
    openModal() {
      this.modalActive = true
    },
    closeModal() {
      this.modalActive = false
    },
    async confirmLocationCreate(newLocation: NewLocation): Promise<ResponseInfo> {
      try {
        await this.locationStore.createLocation(newLocation)
        return createSuccessfulResponseInfo("success.location.create")
      } catch (e) {
        return convertGraphQlErrorToResponseInfo(e)
      } finally {
        this.closeModal()
      }
    },
    handleLocationCreated(event: any) {
      this.closeModal()
      this.$emit("created-location", event)
    },
  },
  watch: {
    parentLocation: {
      immediate: true,
      handler(parentLocation) {
        this.newLocation.locationId = parentLocation.id
      },
    },
  },
  setup() {
    return {
      inventoryStore: useInventoryStore(),
      locationStore: useLocationStore(),
      settingStore: useSettingStore(),
    }
  },
})
