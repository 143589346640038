
import {IonButton, IonInput, IonItem, IonLabel, IonList} from "@ionic/vue"
import {defineComponent, PropType} from "vue"
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome"
import EditAction from "@/components/EditAction.vue"
import {Inventory} from "@/gql/types"
import {useInventoryStore} from "@/stores/inventory"
import {useSettingStore} from "@/stores/setting"
import {convertGraphQlErrorToResponseInfo, createSuccessfulResponseInfo} from "@/shared/helpers"

export default defineComponent({
  components: {
    EditAction,
    IonList,
    IonItem,
    IonLabel,
    IonInput,
    IonButton,
    FontAwesomeIcon,
  },
  emits: ["created-share"],
  props: {
    inventory: {
      required: true,
      type: Object as PropType<Inventory>,
    },
  },
  data() {
    return {
      modalActive: false,
      newShare: this.generateEmptyNewShare(),
    }
  },
  computed: {
    buttonSize() {
      return window.innerWidth < 1280 ? "default" : "large"
    },
  },
  methods: {
    openModal() {
      this.modalActive = true
    },
    closeModal() {
      this.modalActive = false
    },
    async confirmShareCreate(newShare: NewShare) {
      try {
        await this.inventoryStore.createInventoryShareWithAccount(this.inventory, newShare.accountEmail)
        this.newShare = this.generateEmptyNewShare()
        return createSuccessfulResponseInfo("success.share.create")
      } catch (e) {
        return convertGraphQlErrorToResponseInfo(e)
      } finally {
        this.closeModal()
      }
    },
    generateEmptyNewShare(): NewShare {
      return {
        accountEmail: "",
      } as NewShare
    },
  },
  setup() {
    return {
      inventoryStore: useInventoryStore(),
      settingStore: useSettingStore(),
    }
  },
})

export interface NewShare {
  accountEmail: string
}
