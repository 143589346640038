import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_LocationSelect = _resolveComponent("LocationSelect")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_list = _resolveComponent("ion-list")!
  const _component_ModalAction = _resolveComponent("ModalAction")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_ion_button, {
      color: "sunset-2",
      onClick: _ctx.openModal
    }, {
      default: _withCtx(() => [
        _createVNode(_component_font_awesome_icon, { icon: "fa-arrow-up-right-from-square" })
      ]),
      _: 1
    }, 8, ["onClick"]),
    _createVNode(_component_ModalAction, {
      "is-active": _ctx.modalActive,
      "on-confirm": _ctx.confirmItemMove,
      "top-bar-title": _ctx.$t('action.move'),
      onCanceled: _ctx.closeModal,
      onConfirmed: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('moved-item', $event)))
    }, {
      default: _withCtx(() => [
        _createVNode(_component_ion_list, null, {
          default: _withCtx(() => [
            _createVNode(_component_ion_item, null, {
              default: _withCtx(() => [
                _createVNode(_component_ion_label, { position: "stacked" }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('entity.location.parent')), 1)
                  ]),
                  _: 1
                }),
                _createVNode(_component_LocationSelect, {
                  locations: _ctx.locationStore.locations.filter(l => l.id !== _ctx.locationStore.lostLocation.id),
                  "pre-selected-location": _ctx.selectedLocation,
                  onSelectedLocation: _cache[0] || (_cache[0] = ($event: any) => (_ctx.selectedLocation = $event))
                }, null, 8, ["locations", "pre-selected-location"])
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["is-active", "on-confirm", "top-bar-title", "onCanceled"])
  ], 64))
}