import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_img = _resolveComponent("ion-img")!
  const _component_ion_col = _resolveComponent("ion-col")!
  const _component_ion_row = _resolveComponent("ion-row")!
  const _component_ion_grid = _resolveComponent("ion-grid")!
  const _component_ion_title = _resolveComponent("ion-title")!

  return (_openBlock(), _createBlock(_component_ion_title, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_grid, { class: "ion-no-padding" }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_row, { class: "ion-align-items-center" }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_col, { class: "ion-text-center" }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_img, {
                    alt: "Logo",
                    src: "../img/icons/icon-small.png"
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}