import {createI18n} from "vue-i18n"
import translations from "@/shared/i18n/translations"
import {useSettingStore} from "@/stores/setting"

const createi18n = () => createI18n({
  locale: useSettingStore().persistable.preferences.language,
  fallbackLocale: "en",
  globalInjection: true,
  messages: translations,
})

export default createi18n
