
import {defineComponent, PropType} from "vue"
import ItemNamePopup from "@/components/ItemNamePopup.vue"
import ItemNotePopup from "@/components/ItemNotePopup.vue"
import ItemImagePopup from "@/components/ItemImagePopup.vue"
import {Item} from "@/gql/types"

export default defineComponent({
  components: {ItemNamePopup, ItemNotePopup, ItemImagePopup},
  emits: ["opened"],
  props: {
    item: {
      required: true,
      type: Object as PropType<Item>,
    },
  },
  data() {
    return {
      isActive: false,
    }
  },
  methods: {
    open() {
      this.isActive = true
    },
    close() {
      this.isActive = false
    },
  },
})
