import {defineStore} from "pinia"
import apolloClient from "@/shared/clients/apollo-client"
import {useInventoryStore} from "@/stores/inventory"
import gql from "graphql-tag"
import {Image} from "@/gql/types"

export const useImageStore = defineStore("image", {
  state: () => ({
    _inventoryStore: useInventoryStore(),
  }),
  actions: {
    async uploadImage(dataUri: string): Promise<Image> {
      const response = await apolloClient.mutate({
        mutation: gql`
            mutation createImage($inventoryId: ID!, $dataUri: String!) {
                createImage(inventoryId: $inventoryId, dataUri: $dataUri) {
                    id
                    createdAt
                    url
                    labels
                }
            }
        `,
        variables: {
          inventoryId: this._inventoryStore.inventories.active.id,
          dataUri: dataUri,
        },
      })
      return response.data["createImage"]
    },
    async getImage(imageId: number): Promise<Image> {
      const response = await apolloClient.query({
        query: gql`
            query getImage($imageId: ID!) {
                getImage(imageId: $imageId) {
                    id
                    createdAt
                    url
                    labels
                }
            }
        `,
        variables: {
          imageId: imageId,
        },
      })
      return response.data["getImage"]
    },
  },
})
