import {defineStore} from "pinia"
import {markRaw, watch} from "vue"
import apolloClient from "@/shared/clients/apollo-client"
import {ObservableQuery} from "@apollo/client/core"
import {useInventoryStore} from "@/stores/inventory"
import {Category, NewCategory} from "@/gql/types"
import gql from "graphql-tag"
import {convertGraphQlErrorToResponseInfo, getRotatingElement} from "@/shared/helpers"

export const useCategoryStore = defineStore("category", {
  state: () => ({
    allCategories: {id: "", name: "*"} as Category,
    categories: undefined as unknown as Category[],
    _observableQuery: undefined as unknown as ObservableQuery<any, any>,
    _inventoryStore: useInventoryStore(),
  }),
  getters: {
    isReady: (state) => state.categories !== undefined,
    augmentedCategories: (state) => [state.allCategories, ...state.categories],
    notAvailableCategory: (state) => state.categories.find(c => c.name === "?"),
  },
  actions: {
    isModifiable(category: Category) {
      return this.isUserDefined(category)
    },
    isUserDefined(category: Category) {
      return category !== this.notAvailableCategory
    },
    async createCategory(newCategory: NewCategory) {
      await apolloClient.mutate({
        mutation: gql`
            mutation createCategory($inventoryId: ID!, $newCategory: NewCategory!) {
                createCategory(inventoryId: $inventoryId, newCategory: $newCategory) {
                    id
                    name
                }
            }
        `,
        variables: {
          inventoryId: this._inventoryStore.inventories.active.id,
          newCategory: newCategory,
        },
      })
      await this.refetchCategories()
    },
    async editCategory(category: Category) {
      await apolloClient.mutate({
        mutation: gql`
            mutation editCategory($categoryId: ID!, $newName: String!) {
                editCategory(categoryId: $categoryId, newName: $newName)
            }
        `,
        variables: {
          categoryId: category.id,
          newName: category.name,
        },
      })
      await this.refetchCategories()
    },
    async deleteCategory(category: Category) {
      await apolloClient.mutate({
        mutation: gql`
            mutation deleteCategory($categoryId: ID!) {
                deleteCategory(categoryId: $categoryId)
            }
        `,
        variables: {
          categoryId: category.id,
        },
      })
      setTimeout(async () => await this.refetchCategories(), 1500)
    },
    async refetchCategories() {
      await this._observableQuery?.refetch()
    },
    watchCategories() {
      const observableQuery = apolloClient.watchQuery({
        query: gql`
            query getCategories($inventoryId: ID!) {
                getCategories(inventoryId: $inventoryId) {
                    id
                    name
                }
            }
        `,
        variables: {
          inventoryId: this._inventoryStore.inventories.active.id,
        },
        pollInterval: (1000 * 60),
      })
      observableQuery.subscribe({
        next: ({data}) => this.categories = data["getCategories"],
        error: (e) => convertGraphQlErrorToResponseInfo(e),
      })
      watch(
        this._inventoryStore.inventories,
        ({active}) => observableQuery.setVariables({inventoryId: active.id}),
      )
      this._observableQuery = markRaw(observableQuery)
    },
    /**
     * Returns a different category roughly every 17 minutes.
     */
    rotatingCategory(): Category {
      return getRotatingElement(this.categories)
    },
  },
})
