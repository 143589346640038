
import {IonButton, IonInput, IonItem, IonLabel, IonList} from "@ionic/vue"
import {defineComponent} from "vue"
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome"
import EditAction from "@/components/EditAction.vue"
import {NewInventory} from "@/gql/types"
import {useInventoryStore} from "@/stores/inventory"
import {useSettingStore} from "@/stores/setting"
import {convertGraphQlErrorToResponseInfo, createSuccessfulResponseInfo} from "@/shared/helpers"

export default defineComponent({
  components: {
    EditAction,
    IonList,
    IonItem,
    IonLabel,
    IonInput,
    IonButton,
    FontAwesomeIcon,
  },
  emits: ["created-inventory"],
  data() {
    return {
      modalActive: false,
      newInventory: this.generateEmptyNewInventory(),
    }
  },
  computed: {
    buttonSize() {
      return window.innerWidth < 1280 ? "default" : "large"
    },
  },
  methods: {
    openModal() {
      this.modalActive = true
    },
    closeModal() {
      this.modalActive = false
    },
    async confirmInventoryCreate(newInventory: NewInventory) {
      try {
        await this.inventoryStore.createInventory(newInventory)
        this.newInventory = this.generateEmptyNewInventory()
        return createSuccessfulResponseInfo("success.inventory.create")
      } catch (e) {
        return convertGraphQlErrorToResponseInfo(e)
      } finally {
        this.closeModal()
      }
    },
    generateEmptyNewInventory(): NewInventory {
      return {
        name: "",
      } as NewInventory
    },
  },
  setup() {
    return {
      inventoryStore: useInventoryStore(),
      settingStore: useSettingStore(),
    }
  },
})
