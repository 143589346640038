
import {IonCol, IonGrid, IonRow, toastController} from "@ionic/vue"
import {defineComponent, PropType} from "vue"
import {useLocationStore} from "@/stores/location"
import EditItemAction from "@/components/EditItemAction.vue"
import MoveItemAction from "@/components/MoveItemAction.vue"
import LostItemAction from "@/components/LostItemAction.vue"
import DeleteItemAction from "@/components/DeleteItemAction.vue"
import {ResponseInfo} from "@/shared/helpers"
import {Item} from "@/gql/types"

export default defineComponent({
  components: {DeleteItemAction, LostItemAction, MoveItemAction, EditItemAction, IonGrid, IonRow, IonCol},
  emits: ["reload"],
  props: {
    item: {
      required: true,
      type: Object as PropType<Item>,
    },
    noPadding: {
      required: false,
      default: false,
      type: Boolean,
    },
    centerIcons: {
      required: false,
      default: true,
      type: Boolean,
    },
  },
  computed: {
    isItemLost() {
      return this.item.location.id === this.locationStore.lostLocation.id
    },
  },
  methods: {
    async updatedItem(responseInfo: ResponseInfo) {
      await this.presentResponseInfoToast(responseInfo)
      this.$emit("reload")
    },
    async presentResponseInfoToast(responseInfo: ResponseInfo) {
      const toast = await toastController.create({
        message: `${this.$t(responseInfo.messageKey)}!`,
        duration: 5560,
        position: "top",
        buttons: [
          {
            text: this.$t("button.ok"),
            role: "cancel",
          },
        ],
        color: responseInfo.isError ? "danger" : "",
      })
      await toast.present()
    },
  },
  setup() {
    return {
      locationStore: useLocationStore(),
    }
  },
})
