import {defineStore} from "pinia"
import {markRaw} from "vue"
import apolloClient from "@/shared/clients/apollo-client"
import {ObservableQuery} from "@apollo/client/core"
import {useAccountStore} from "@/stores/account"
import {useSettingStore} from "@/stores/setting"
import {Inventory, NewInventory} from "@/gql/types"
import gql from "graphql-tag"
import {convertGraphQlErrorToResponseInfo} from "@/shared/helpers"

export const useInventoryStore = defineStore("inventory", {
  state: () => ({
    inventories: {
      active: undefined as unknown as Inventory,
      allAccessible: undefined as unknown as Inventory[],
    },
    _observableQuery: undefined as unknown as ObservableQuery<any, any>,
    _accountStore: useAccountStore(),
  }),
  getters: {
    isReady: (state) => state.inventories.active !== undefined && state.inventories.allAccessible !== undefined,
    ownedInventories: (state) => state.inventories.allAccessible.filter(i => i.ownedByAccountId === state._accountStore.accountId),
  },
  actions: {
    async createInventory(newInventory: NewInventory) {
      await apolloClient.mutate({
        mutation: gql`
            mutation createInventory($newInventory: NewInventory!) {
                createInventory(newInventory: $newInventory) {
                    id
                    name
                    ownedByAccountId
                }
            }
        `,
        variables: {
          newInventory: newInventory,
        },
      })
      await this.refetchInventories()
    },
    async editInventory(inventory: Inventory) {
      await apolloClient.mutate({
        mutation: gql`
            mutation editInventory($inventoryId: ID!, $newName: String!) {
                editInventory(inventoryId: $inventoryId, newName: $newName)
            }
        `,
        variables: {
          inventoryId: inventory.id,
          newName: inventory.name,
        },
      })
      await this.refetchInventories()
    },
    async deleteInventory(inventory: Inventory) {
      const settingStore = useSettingStore()
      if (settingStore.persistable.miscellaneous.activeInventoryId === inventory.id) settingStore.persistable.miscellaneous.activeInventoryId = null

      await apolloClient.mutate({
        mutation: gql`
            mutation deleteInventory($inventoryId: ID!) {
                deleteInventory(inventoryId: $inventoryId)
            }
        `,
        variables: {
          inventoryId: inventory.id,
        },
      })
      await this.refetchInventories()
    },
    async getSharedWithAccounts(inventory: Inventory) {
      const response = await apolloClient.query({
        query: gql`
            query getSharedWithAccounts($inventoryId: ID!) {
                getSharedWithAccounts(inventoryId: $inventoryId)
            }
        `,
        variables: {
          inventoryId: inventory.id,
        },
        fetchPolicy: "network-only",
      })
      return response.data["getSharedWithAccounts"] as string[]
    },
    async createInventoryShareWithAccount(inventory: Inventory, sharedWith: string) {
      await apolloClient.mutate({
        mutation: gql`
            mutation createInventoryShareWithAccount($inventoryId: ID!, $accountEmail: String!) {
                createInventoryShareWithAccount(inventoryId: $inventoryId, accountEmail: $accountEmail)
            }
        `,
        variables: {
          inventoryId: inventory.id,
          accountEmail: sharedWith.trim(),
        },
      })
    },
    async deleteInventoryShareWithAccount(inventory: Inventory, sharedWith: string) {
      await apolloClient.mutate({
        mutation: gql`
            mutation deleteInventoryShareWithAccount($inventoryId: ID!, $accountEmail: String!) {
                deleteInventoryShareWithAccount(inventoryId: $inventoryId, accountEmail: $accountEmail)
            }
        `,
        variables: {
          inventoryId: inventory.id,
          accountEmail: sharedWith,
        },
      })
    },
    async refetchInventories() {
      await this._observableQuery?.refetch()
    },
    async watchInventories() {
      // Required to do this since `watchQuery` cannot properly be awaited
      const settingStore = useSettingStore()
      const inventories: Inventory[] = (await apolloClient.query({
        query: gql`
            query getInventories {
                getInventories {
                    id
                    name
                    ownedByAccountId
                }
            }
        `,
        fetchPolicy: "network-only",
      })).data["getInventories"] as Inventory[]
      this.inventories.allAccessible = inventories
      this.selectActiveInventory(inventories.find(i => i.id === settingStore.persistable.miscellaneous.activeInventoryId) ?? inventories[0])

      const observableQuery = apolloClient.watchQuery({
        query: gql`
            query getInventories {
                getInventories {
                    id
                    name
                    ownedByAccountId
                }
            }
        `,
        fetchPolicy: "network-only",
        pollInterval: (1000 * 60 * 5),
      })
      observableQuery.subscribe({
        next: ({data}) => {
          const settingStore = useSettingStore()
          this.inventories.allAccessible = data["getInventories"]
          this.selectActiveInventory(this.inventories.allAccessible.find(i => i.id === settingStore.persistable.miscellaneous.activeInventoryId) ?? this.inventories.allAccessible[0])
        },
        error: (e) => convertGraphQlErrorToResponseInfo(e),
      })
      this._observableQuery = markRaw(observableQuery)
    },
    selectActiveInventory(inventory: Inventory) {
      const settingStore = useSettingStore()
      this.inventories.active = inventory
      settingStore.persistable.miscellaneous.activeInventoryId = inventory.id
    },
  },
})
