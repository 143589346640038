import {isPlatform} from "@ionic/vue"
import {createAuth0} from "@auth0/auth0-vue"
import {useSettingStore} from "@/stores/setting"
import capacitorConfig from "@/../capacitor.config"

export const auth0Domain = process.env.VUE_APP_AUTH0_DOMAIN as string

function configureAuth0() {
  if (isPlatform("capacitor")) {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const callbackUri = `${capacitorConfig.appId!}://${auth0Domain}/capacitor/${capacitorConfig.appId!}/callback`
    return {
      createAuth0Client: () => createAuth0({
        domain: auth0Domain,
        clientId: process.env.VUE_APP_AUTH0_CLIENT_ID_NATIVE as string,
        cacheLocation: "localstorage",
        useRefreshTokens: true,
        useRefreshTokensFallback: false,
        authorizationParams: {
          redirect_uri: callbackUri,
          ui_locales: `${useSettingStore().persistable.preferences.language} en`,
        },
      }),
      callbackUri: callbackUri,
    }
  } else {
    const callbackUri = window.location.origin
    return {
      createAuth0Client: () => createAuth0({
        domain: auth0Domain,
        clientId: process.env.VUE_APP_AUTH0_CLIENT_ID_WEB as string,
        useRefreshTokens: true,
        useRefreshTokensFallback: true,
        authorizationParams: {
          redirect_uri: callbackUri,
          ui_locales: `${useSettingStore().persistable.preferences.language} en`,
        },
      }),
      callbackUri: callbackUri,
    }
  }
}

export const {createAuth0Client, callbackUri} = configureAuth0()
