import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "desktop-width-sm" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_card_title = _resolveComponent("ion-card-title")!
  const _component_ion_card_header = _resolveComponent("ion-card-header")!
  const _component_LocationSelect = _resolveComponent("LocationSelect")!
  const _component_ion_card_content = _resolveComponent("ion-card-content")!
  const _component_ion_card = _resolveComponent("ion-card")!
  const _component_CreateLocationAction = _resolveComponent("CreateLocationAction")!
  const _component_ion_col = _resolveComponent("ion-col")!
  const _component_EditLocationAction = _resolveComponent("EditLocationAction")!
  const _component_MoveLocationAction = _resolveComponent("MoveLocationAction")!
  const _component_DeleteLocationAction = _resolveComponent("DeleteLocationAction")!
  const _component_ion_row = _resolveComponent("ion-row")!
  const _component_ion_grid = _resolveComponent("ion-grid")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_ion_card, null, {
      default: _withCtx(() => [
        _createVNode(_component_ion_card_header, null, {
          default: _withCtx(() => [
            _createVNode(_component_ion_card_title, null, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('general.selection')), 1)
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_component_ion_card_content, null, {
          default: _withCtx(() => [
            _createVNode(_component_LocationSelect, {
              locations: _ctx.locationStore.locations,
              "pre-selected-location": _ctx.selectedLocation,
              "show-root-location": true,
              onSelectedLocation: _cache[0] || (_cache[0] = ($event: any) => (_ctx.selectedLocation = $event))
            }, null, 8, ["locations", "pre-selected-location"])
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_ion_card, null, {
      default: _withCtx(() => [
        _createVNode(_component_ion_card_header, null, {
          default: _withCtx(() => [
            _createVNode(_component_ion_card_title, null, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('action.general')), 1)
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_component_ion_card_content, null, {
          default: _withCtx(() => [
            _createVNode(_component_ion_grid, null, {
              default: _withCtx(() => [
                _createVNode(_component_ion_row, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_col, { class: "ion-text-center" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_CreateLocationAction, {
                          disabled: !_ctx.locationStore.allowsNested(_ctx.selectedLocation),
                          "parent-location": _ctx.selectedLocation,
                          onCreatedLocation: _ctx.presentResponseInfoToast
                        }, null, 8, ["disabled", "parent-location", "onCreatedLocation"])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_ion_col, { class: "ion-text-center" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_EditLocationAction, {
                          disabled: !_ctx.locationStore.isModifiable(_ctx.selectedLocation),
                          location: _ctx.selectedLocation,
                          onEditedLocation: _ctx.presentResponseInfoToast
                        }, null, 8, ["disabled", "location", "onEditedLocation"])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_ion_col, { class: "ion-text-center" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_MoveLocationAction, {
                          disabled: !_ctx.locationStore.isMovable(_ctx.selectedLocation),
                          location: _ctx.selectedLocation,
                          onMovedLocation: _ctx.presentResponseInfoToast
                        }, null, 8, ["disabled", "location", "onMovedLocation"])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_ion_col, { class: "ion-text-center" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_DeleteLocationAction, {
                          disabled: !_ctx.locationStore.isModifiable(_ctx.selectedLocation),
                          location: _ctx.selectedLocation,
                          onDeletedLocation: _ctx.deletedLocation
                        }, null, 8, ["disabled", "location", "onDeletedLocation"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ]))
}