import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_ion_fab_button = _resolveComponent("ion-fab-button")!
  const _component_ion_fab = _resolveComponent("ion-fab")!
  const _component_CreateItemModal = _resolveComponent("CreateItemModal")!
  const _component_CreateLocationModal = _resolveComponent("CreateLocationModal")!
  const _component_ScannerContainer = _resolveComponent("ScannerContainer")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_ion_fab, {
      slot: "fixed",
      class: "desktop-width-sm-absolute-right",
      horizontal: "end",
      vertical: "bottom"
    }, {
      default: _withCtx(() => [
        (_ctx.settingStore.supportsScanning)
          ? (_openBlock(), _createBlock(_component_ion_fab_button, {
              key: 0,
              color: "tertiary",
              onClick: _ctx.startScan
            }, {
              default: _withCtx(() => [
                _createVNode(_component_font_awesome_icon, {
                  icon: "fa-barcode",
                  size: "lg"
                })
              ]),
              _: 1
            }, 8, ["onClick"]))
          : _createCommentVNode("", true),
        _createVNode(_component_ion_fab_button, {
          color: "success",
          onClick: _ctx.startCreateItem
        }, {
          default: _withCtx(() => [
            _createVNode(_component_font_awesome_icon, {
              icon: "fa-square-plus",
              size: "xl"
            })
          ]),
          _: 1
        }, 8, ["onClick"])
      ]),
      _: 1
    }),
    _createVNode(_component_CreateItemModal, {
      barcode: _ctx.barcode,
      category: _ctx.settingStore.persistable.itemCreation.lastSelection.category ?? _ctx.categoryStore.notAvailableCategory,
      "modal-active": _ctx.createItemModalIsActive,
      "parent-location": _ctx.settingStore.persistable.itemCreation.lastSelection.location ?? _ctx.locationStore.rootLocation,
      onCanceled: _ctx.stopCreateItem,
      onCreatedItem: _ctx.handleCreatedItem
    }, null, 8, ["barcode", "category", "modal-active", "parent-location", "onCanceled", "onCreatedItem"]),
    _createVNode(_component_CreateLocationModal, {
      barcode: _ctx.barcode,
      "modal-active": _ctx.createLocationModalIsActive,
      "parent-location": _ctx.locationStore.rootLocation,
      onCanceled: _ctx.stopCreateLocation,
      onCreatedItem: _ctx.handleCreatedLocation
    }, null, 8, ["barcode", "modal-active", "parent-location", "onCanceled", "onCreatedItem"]),
    (_ctx.settingStore.supportsScanning)
      ? (_openBlock(), _createBlock(_component_ScannerContainer, {
          key: 0,
          ref: "scannerContainer",
          onScannedBarcode: _ctx.handleScannedBarcode
        }, null, 8, ["onScannedBarcode"]))
      : _createCommentVNode("", true)
  ], 64))
}