import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_AlertAction = _resolveComponent("AlertAction")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_ion_label, null, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.sharedWith), 1)
      ]),
      _: 1
    }),
    _createVNode(_component_AlertAction, {
      slot: "end",
      "alert-header-text": _ctx.$t('prompt.deleteShareConfirm'),
      "on-confirm": _ctx.deleteShare,
      "icon-color": "sunset-4",
      "icon-type": "fa-trash",
      onConfirmed: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('deleted-share', $event)))
    }, null, 8, ["alert-header-text", "on-confirm"])
  ], 64))
}