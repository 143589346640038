
import {IonAccordion, IonAccordionGroup, IonItem, IonLabel, IonSkeletonText} from "@ionic/vue"
import {defineComponent, PropType} from "vue"
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome"
import LocationHierarchy from "@/components/LocationHierarchy.vue"
import CategoryBadge from "@/components/CategoryBadge.vue"
import BarcodeBadge from "@/components/BarcodeBadge.vue"
import ItemActions from "@/components/ItemActions.vue"
import BasicItemInformation from "@/components/BasicItemInformation.vue"
import {useSettingStore} from "@/stores/setting"
import {Item} from "@/gql/types"

export default defineComponent({
  components: {
    BasicItemInformation,
    ItemActions,
    BarcodeBadge,
    CategoryBadge,
    FontAwesomeIcon,
    LocationHierarchy,
    IonItem,
    IonLabel,
    IonSkeletonText,
    IonAccordion,
    IonAccordionGroup,
  },
  emits: ["reload"],
  props: {
    items: {
      required: true,
      type: Array as PropType<Array<Item>>,
    },
    isLoading: {
      required: false,
      default: false,
      type: Boolean,
    },
  },
  methods: {
    reload() {
      this.$emit("reload")
    },
    /**
     * Stops the propagation of the click on the accordion item if the item's note popup was clicked.
     */
    onAccordionItemClick(event: any) {
      const basicItemInformationClicked = this.isChildOfElementWithClass(event.target, "basic-item-information")
      if (basicItemInformationClicked) event.stopImmediatePropagation()
    },
    isChildOfElementWithClass(target: any, className: string): boolean {
      while (target) {
        if (target instanceof HTMLElement && target.classList.contains(className)) return true
        target = target.parentNode
      }
      return false
    },
  },
  setup() {
    return {
      settingStore: useSettingStore(),
    }
  },
})
