
import {defineComponent, PropType} from "vue"
import TooltipBadge from "@/components/TooltipBadge.vue"
import {Barcode} from "@/gql/types"

export default defineComponent({
  components: {TooltipBadge},
  props: {
    barcode: {
      required: true,
      type: Object as PropType<Barcode>,
    },
  },
})
