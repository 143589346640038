
import {IonContent, IonHeader, IonPage, IonTitle, IonToolbar} from "@ionic/vue"
import {defineComponent} from "vue"
import LogoContainer from "@/components/LogoContainer.vue"
import ManagementContainer from "@/components/ManagementContainer.vue"

export default defineComponent({
  components: {
    ManagementContainer,
    LogoContainer,
    IonPage,
    IonHeader,
    IonToolbar,
    IonTitle,
    IonContent,
  },
})
