
import {IonButton, IonInput, IonItem, IonLabel, IonList} from "@ionic/vue"
import {defineComponent} from "vue"
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome"
import EditAction from "@/components/EditAction.vue"
import {NewCategory} from "@/gql/types"
import {useInventoryStore} from "@/stores/inventory"
import {useSettingStore} from "@/stores/setting"
import {useCategoryStore} from "@/stores/category"
import {convertGraphQlErrorToResponseInfo, createSuccessfulResponseInfo} from "@/shared/helpers"

export default defineComponent({
  components: {
    EditAction,
    IonList,
    IonItem,
    IonLabel,
    IonInput,
    IonButton,
    FontAwesomeIcon,
  },
  emits: ["created-category"],
  data() {
    return {
      modalActive: false,
      newCategory: this.generateEmptyNewCategory(),
    }
  },
  computed: {
    buttonSize() {
      return window.innerWidth < 1280 ? "default" : "large"
    },
  },
  methods: {
    openModal() {
      this.modalActive = true
    },
    closeModal() {
      this.modalActive = false
    },
    async confirmCategoryCreate(newCategory: NewCategory) {
      try {
        await this.categoryStore.createCategory(newCategory)
        this.newCategory = this.generateEmptyNewCategory()
        return createSuccessfulResponseInfo("success.category.create")
      } catch (e) {
        return convertGraphQlErrorToResponseInfo(e)
      } finally {
        this.closeModal()
      }
    },
    generateEmptyNewCategory(): NewCategory {
      return {
        name: "",
      } as NewCategory
    },
  },
  setup() {
    return {
      inventoryStore: useInventoryStore(),
      categoryStore: useCategoryStore(),
      settingStore: useSettingStore(),
    }
  },
})
