
import {defineComponent, PropType} from "vue"
import AlertAction from "@/components/AlertAction.vue"
import {useCategoryStore} from "@/stores/category"
import {Category} from "@/gql/types"
import {convertGraphQlErrorToResponseInfo, createSuccessfulResponseInfo} from "@/shared/helpers"

export default defineComponent({
  components: {AlertAction},
  emits: ["deleted-category"],
  props: {
    category: {
      required: true,
      type: Object as PropType<Category>,
    },
    disabled: {
      required: false,
      default: false,
      type: Boolean,
    },
  },
  computed: {
    buttonSize() {
      return window.innerWidth < 1280 ? "default" : "large"
    },
  },
  methods: {
    async deleteCategory() {
      try {
        await this.categoryStore.deleteCategory(this.category)
        return createSuccessfulResponseInfo("success.category.delete")
      } catch (e) {
        return convertGraphQlErrorToResponseInfo(e)
      }
    },
  },
  setup() {
    return {
      categoryStore: useCategoryStore(),
    }
  },
})
