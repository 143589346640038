import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_LocationSelect = _resolveComponent("LocationSelect")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_input = _resolveComponent("ion-input")!
  const _component_ion_list = _resolveComponent("ion-list")!
  const _component_BarcodeEditor = _resolveComponent("BarcodeEditor")!
  const _component_EditAction = _resolveComponent("EditAction")!

  return (_openBlock(), _createBlock(_component_EditAction, {
    "modal-active": _ctx.modalActive,
    "on-confirm": _ctx.confirmLocationCreate,
    "to-render": _ctx.newLocation,
    "top-bar-title": _ctx.$t('action.create'),
    onEditConfirmed: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('created-location', $event))),
    onEditCanceled: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('canceled')))
  }, {
    default: _withCtx(({active, patchActive}) => [
      _createVNode(_component_ion_list, { lines: "full" }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_item, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_label, { position: "stacked" }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('entity.location.parent')), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_LocationSelect, {
                locations: _ctx.locationStore.locations,
                "pre-selected-location": _ctx.parentLocation,
                "show-root-location": true,
                onSelectedLocation: ($event: any) => (patchActive({locationId: $event.id}))
              }, null, 8, ["locations", "pre-selected-location", "onSelectedLocation"])
            ]),
            _: 2
          }, 1024),
          _createVNode(_component_ion_item, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_label, { position: "stacked" }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('general.name')), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_ion_input, {
                value: active.name,
                onIonChange: ($event: any) => (patchActive({name: $event.detail.value}))
              }, null, 8, ["value", "onIonChange"])
            ]),
            _: 2
          }, 1024)
        ]),
        _: 2
      }, 1024),
      _createVNode(_component_BarcodeEditor, {
        barcode: _ctx.newLocation.barcode,
        onBarcodeChanged: ($event: any) => (patchActive({barcode: $event})),
        onBarcodeDisabled: ($event: any) => (patchActive({barcode: null}))
      }, null, 8, ["barcode", "onBarcodeChanged", "onBarcodeDisabled"])
    ]),
    _: 1
  }, 8, ["modal-active", "on-confirm", "to-render", "top-bar-title"]))
}