import {createRouter, createWebHistory} from "@ionic/vue-router"
import {RouteRecordRaw} from "vue-router"
import ViewContainer from "@/views/ViewContainer.vue"
import SearchView from "@/views/SearchView.vue"
import ManagementView from "@/views/ManagementView.vue"
import SettingsView from "@/views/SettingsView.vue"
import LocationManagementView from "@/views/LocationManagementView.vue"
import SubscriptionSelectionView from "@/views/SubscriptionSelectionView.vue"
import CategoryManagementView from "@/views/CategoryManagementView.vue"
import InventoryManagementView from "@/views/InventoryManagementView.vue"
import OnboardingView from "@/views/OnboardingView.vue"
import {isAuth0CallbackQuery} from "@/shared/helpers"

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    component: ViewContainer,
    children: [
      {
        path: "",
        redirect: to => {
          if (isAuth0CallbackQuery(to.query)) return {path: "/", query: {}}
          // TODO: Figure out why this does not rewrite the URL
          return {path: "/search"}
        },
      },
      {
        path: "/search",
        component: SearchView,
      },
      {
        path: "/management",
        component: ManagementView,
      },
      {
        path: "/management/location",
        component: LocationManagementView,
      },
      {
        path: "/management/category",
        component: CategoryManagementView,
      },
      {
        path: "/management/inventory",
        component: InventoryManagementView,
      },
      {
        path: "/settings",
        component: SettingsView,
      },
      {
        path: "/settings/subscribe",
        component: SubscriptionSelectionView,
      },
    ],
  },
  {
    path: "/onboarding",
    component: OnboardingView,
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

export default router
