
import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonSkeletonText,
  IonText,
  toastController,
  useIonRouter,
} from "@ionic/vue"
import {defineComponent} from "vue"
import {Browser} from "@capacitor/browser"
import {CapacitorPurchases} from "@capgo/capacitor-purchases"
import {useAuthenticationStore} from "@/stores/authentication"
import {useAccountStore} from "@/stores/account"
import {useSettingStore} from "@/stores/setting"
import {BillingProvider} from "@/gql/types"

export default defineComponent({
  components: {
    IonCard,
    IonCardHeader,
    IonCardTitle,
    IonCardSubtitle,
    IonCardContent,
    IonButton,
    IonSkeletonText,
    IonText,
  },
  computed: {
    formattedValidUntilDate(): string | null {
      const dateString = this.accountStore.subscription?.validUntil
      if (!dateString) return null
      return new Date(Date.parse(dateString)).toLocaleString(this.$i18n.locale, {
        year: "numeric",
        month: "numeric",
        day: "numeric",
      })
    },
    allowToChangeSubscription(): boolean {
      return Boolean(JSON.parse(process.env.VUE_APP_SUBSCRIPTION_CHANGE_ALLOWED as string))
    },
  },
  methods: {
    async routeToSubscribe() {
      await this.accountStore.refetchSubscription()
      if (this.accountStore.isAllowedToUpgrade) this.ionRouter.push("/settings/subscribe")
    },
    async routeToModify() {
      const billingProvider = this.accountStore.subscription.billingProvider
      const purchaseManagementUrl = (await CapacitorPurchases.getCustomerInfo()).customerInfo.managementURL
      if ((this.settingStore.isNative && billingProvider === BillingProvider.Stripe) || (this.settingStore.isAndroid && billingProvider !== BillingProvider.GooglePlayStore) || (this.settingStore.isIos && billingProvider !== BillingProvider.AppleAppStore)) {
        await this.presentToast(`${this.$t("general.checkSubscriptionProvider")}!`)
      } else if (this.settingStore.isNative && purchaseManagementUrl) {
        await Browser.open({
          url: purchaseManagementUrl,
          windowName: "_self",
        })
      } else if (billingProvider === BillingProvider.Stripe) {
        await Browser.open({
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          url: `https://billing.stripe.com/p/login/${process.env.VUE_APP_STRIPE_CUSTOMER_PORTAL_ID}?prefilled_email=${encodeURIComponent(this.authStore.user.email!)}`,
          windowName: "_self",
        })
      } else {
        await this.presentToast(`${this.$t("general.noActiveSubscription")}!`)
      }
    },
    async presentToast(message: string) {
      const toast = await toastController.create({
        message: message,
        duration: 5500,
        position: "top",
        buttons: [
          {
            text: this.$t("button.ok"),
            role: "cancel",
          },
        ],
      })
      await toast.present()
    },
  },
  setup() {
    return {
      authStore: useAuthenticationStore(),
      accountStore: useAccountStore(),
      settingStore: useSettingStore(),
      ionRouter: useIonRouter(),
    }
  },
})
