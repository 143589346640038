import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_CreateLocationModal = _resolveComponent("CreateLocationModal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_ion_button, {
      disabled: _ctx.disabled,
      size: _ctx.buttonSize,
      color: "success",
      onClick: _ctx.openModal
    }, {
      default: _withCtx(() => [
        _createVNode(_component_font_awesome_icon, { icon: "fa-square-plus" })
      ]),
      _: 1
    }, 8, ["disabled", "size", "onClick"]),
    _createVNode(_component_CreateLocationModal, {
      "modal-active": _ctx.modalActive,
      "parent-location": _ctx.parentLocation,
      onCanceled: _ctx.closeModal,
      onCreatedLocation: _ctx.handleLocationCreated
    }, null, 8, ["modal-active", "parent-location", "onCanceled", "onCreatedLocation"])
  ], 64))
}