
import {IonButton, IonButtons, IonContent, IonHeader, IonModal, IonTitle, IonToolbar} from "@ionic/vue"
import {defineComponent} from "vue"
import {ResponseInfo} from "@/shared/helpers"

export default defineComponent({
  components: {
    IonButtons,
    IonButton,
    IonModal,
    IonHeader,
    IonToolbar,
    IonContent,
    IonTitle,
  },
  emits: ["confirmed", "canceled"],
  props: {
    isActive: {
      required: true,
      default: false,
      type: Boolean,
    },
    topBarTitle: {
      required: true,
      type: String,
    },
    onConfirm: {
      required: true,
      type: Function,
    },
  },
  methods: {
    async confirm() {
      const response: ResponseInfo = await this.onConfirm()
      this.$emit("confirmed", response)
    },
    cancel() {
      this.$emit("canceled")
    },
  },
})
