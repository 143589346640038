
import {IonLabel} from "@ionic/vue"
import {defineComponent, PropType} from "vue"
import InventoryDropdown from "@/components/InventoryDropdown.vue"
import {useInventoryStore} from "@/stores/inventory"
import {Inventory} from "@/gql/types"

export default defineComponent({
  components: {
    InventoryDropdown,
    IonLabel,
  },
  emits: ["selected-inventory"],
  props: {
    label: {
      required: true,
      type: String,
    },
    stackedLabel: {
      required: false,
      default: false,
      type: Boolean,
    },
    inventories: {
      required: true,
      type: Array as PropType<Inventory[]>,
    },
    preSelectedInventory: {
      required: true,
      type: Object as PropType<Inventory>,
    },
  },
  setup() {
    return {
      inventoryStore: useInventoryStore(),
    }
  },
})
