import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_searchbar = _resolveComponent("ion-searchbar")!
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_col = _resolveComponent("ion-col")!
  const _component_ion_row = _resolveComponent("ion-row")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_LocationDropdown = _resolveComponent("LocationDropdown")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_grid = _resolveComponent("ion-grid")!
  const _component_ScannerContainer = _resolveComponent("ScannerContainer")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_ion_grid, {
      class: _normalizeClass([{'no-padding-sides': _ctx.noSidePadding}, "container"])
    }, {
      default: _withCtx(() => [
        (_ctx.settingStore.renderMobileLayout)
          ? (_openBlock(), _createBlock(_component_ion_row, { key: 0 }, {
              default: _withCtx(() => [
                _createVNode(_component_ion_col, {
                  class: _normalizeClass([{'no-padding-sides': _ctx.noSidePadding}, "searchbar-container"])
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_searchbar, {
                      modelValue: _ctx.query,
                      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.query) = $event)),
                      class: _normalizeClass({'search-focused': _ctx.searchFocused}),
                      placeholder: _ctx.searchPlaceholder,
                      "show-clear-button": "focus",
                      onIonFocus: _ctx.focusSearchbar,
                      onIonBlur: _ctx.blurSearchbar
                    }, null, 8, ["modelValue", "class", "placeholder", "onIonFocus", "onIonBlur"]),
                    (_ctx.settingStore.supportsScanning)
                      ? (_openBlock(), _createBlock(_component_ion_button, {
                          key: 0,
                          class: "searchbar-scan-button",
                          color: "tertiary",
                          onClick: _ctx.scanBarcode
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_font_awesome_icon, { icon: "fa-barcode" })
                          ]),
                          _: 1
                        }, 8, ["onClick"]))
                      : _createCommentVNode("", true)
                  ]),
                  _: 1
                }, 8, ["class"])
              ]),
              _: 1
            }))
          : _createCommentVNode("", true),
        (_ctx.settingStore.renderMobileLayout)
          ? (_openBlock(), _createBlock(_component_ion_row, { key: 1 }, {
              default: _withCtx(() => [
                _createVNode(_component_ion_col, {
                  class: _normalizeClass({'no-padding-sides': _ctx.noSidePadding})
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_item, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_label, { position: "stacked" }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.$t('entity.location.name')), 1)
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_LocationDropdown, {
                          locations: _ctx.filteredLocations,
                          "pre-selected-location": _ctx.selectedLocation,
                          onSelectedLocation: _ctx.selectLocation
                        }, null, 8, ["locations", "pre-selected-location", "onSelectedLocation"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }, 8, ["class"])
              ]),
              _: 1
            }))
          : _createCommentVNode("", true),
        (!_ctx.settingStore.renderMobileLayout)
          ? (_openBlock(), _createBlock(_component_ion_row, {
              key: 2,
              class: "ion-align-items-center"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_ion_col, {
                  class: _normalizeClass([{'no-padding-start': _ctx.noSidePadding}, "searchbar-container"])
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_searchbar, {
                      modelValue: _ctx.query,
                      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.query) = $event)),
                      class: _normalizeClass({'search-focused': _ctx.searchFocused}),
                      placeholder: _ctx.searchPlaceholder,
                      "show-clear-button": "focus",
                      onIonFocus: _ctx.focusSearchbar,
                      onIonBlur: _ctx.blurSearchbar
                    }, null, 8, ["modelValue", "class", "placeholder", "onIonFocus", "onIonBlur"]),
                    (_ctx.settingStore.supportsScanning)
                      ? (_openBlock(), _createBlock(_component_ion_button, {
                          key: 0,
                          class: "searchbar-scan-button",
                          color: "tertiary",
                          onClick: _ctx.scanBarcode
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_font_awesome_icon, { icon: "fa-barcode" })
                          ]),
                          _: 1
                        }, 8, ["onClick"]))
                      : _createCommentVNode("", true)
                  ]),
                  _: 1
                }, 8, ["class"]),
                _createVNode(_component_ion_col, {
                  class: _normalizeClass({'no-padding-end': _ctx.noSidePadding})
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_item, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_label, { position: "stacked" }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.$t('entity.location.name')), 1)
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_LocationDropdown, {
                          locations: _ctx.filteredLocations,
                          "pre-selected-location": _ctx.selectedLocation,
                          onSelectedLocation: _ctx.selectLocation
                        }, null, 8, ["locations", "pre-selected-location", "onSelectedLocation"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }, 8, ["class"])
              ]),
              _: 1
            }))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["class"]),
    (_ctx.settingStore.supportsScanning)
      ? (_openBlock(), _createBlock(_component_ScannerContainer, {
          key: 0,
          ref: "scannerContainer",
          onScannedBarcode: _ctx.handleScannedBarcode
        }, null, 8, ["onScannedBarcode"]))
      : _createCommentVNode("", true)
  ], 64))
}