import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_card_title = _resolveComponent("ion-card-title")!
  const _component_ion_text = _resolveComponent("ion-text")!
  const _component_ion_card_subtitle = _resolveComponent("ion-card-subtitle")!
  const _component_ion_skeleton_text = _resolveComponent("ion-skeleton-text")!
  const _component_ion_card_header = _resolveComponent("ion-card-header")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_card_content = _resolveComponent("ion-card-content")!
  const _component_ion_card = _resolveComponent("ion-card")!

  return (_openBlock(), _createBlock(_component_ion_card, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_card_header, null, {
        default: _withCtx(() => [
          _createVNode(_component_ion_card_title, null, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('general.subscription')), 1)
            ]),
            _: 1
          }),
          (_ctx.accountStore.subscription)
            ? (_openBlock(), _createBlock(_component_ion_card_subtitle, { key: 0 }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_text, null, {
                    default: _withCtx(() => [
                      _createElementVNode("span", null, [
                        _createElementVNode("strong", null, _toDisplayString(_ctx.accountStore.subscription?.tier), 1)
                      ]),
                      (_ctx.accountStore.subscription?.validUntil)
                        ? (_openBlock(), _createElementBlock("span", _hoisted_1, " " + _toDisplayString(_ctx.$t('general.until')) + " ", 1))
                        : _createCommentVNode("", true),
                      (_ctx.accountStore.subscription?.validUntil)
                        ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_ctx.formattedValidUntilDate), 1))
                        : _createCommentVNode("", true)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }))
            : (_openBlock(), _createBlock(_component_ion_card_subtitle, { key: 1 }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_skeleton_text, {
                    animated: true,
                    style: {"width":"20px"}
                  })
                ]),
                _: 1
              }))
        ]),
        _: 1
      }),
      (_ctx.allowToChangeSubscription)
        ? (_openBlock(), _createBlock(_component_ion_card_content, { key: 0 }, {
            default: _withCtx(() => [
              (_ctx.accountStore.isAllowedToUpgrade)
                ? (_openBlock(), _createBlock(_component_ion_button, {
                    key: 0,
                    onClick: _ctx.routeToSubscribe
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t("general.upgrade")), 1)
                    ]),
                    _: 1
                  }, 8, ["onClick"]))
                : (_openBlock(), _createBlock(_component_ion_button, {
                    key: 1,
                    onClick: _ctx.routeToModify
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t("action.modify")), 1)
                    ]),
                    _: 1
                  }, 8, ["onClick"])),
              _createVNode(_component_ion_button, {
                onClick: _ctx.accountStore.refetchSubscription
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t("button.restorePurchases")), 1)
                ]),
                _: 1
              }, 8, ["onClick"])
            ]),
            _: 1
          }))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}