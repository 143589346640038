
import {defineComponent, PropType} from "vue"
import TooltipBadge from "@/components/TooltipBadge.vue"
import {useLocationStore} from "@/stores/location"
import {Location} from "@/gql/types"

export default defineComponent({
  components: {TooltipBadge},
  props: {
    location: {
      required: true,
      type: Object as PropType<Location>,
    },
    alignInColumn: {
      required: false,
      default: false,
      type: Boolean,
    },
  },
  methods: {
    getLocationBadgeColor(index: number) {
      switch (index) {
        case 0:
          return "layer-1"
        case 1:
          return "layer-2"
        case 2:
          return "layer-3"
        default:
          return undefined
      }
    },
  },
  setup() {
    return {
      locationStore: useLocationStore(),
    }
  },
})
