
import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonicSlides,
  IonImg,
  IonItem,
  IonLabel,
  IonList,
  IonText,
  useIonRouter,
} from "@ionic/vue"
import {defineComponent} from "vue"
import {Swiper, SwiperSlide} from "swiper/vue"
import {Keyboard, Navigation, Pagination} from "swiper"
import LanguageSelectCard from "@/components/LanguageSelectCard.vue"
import {useSettingStore} from "@/stores/setting"

import "swiper/css"
import "swiper/css/keyboard"
import "swiper/css/navigation"
import "swiper/css/pagination"
import "@ionic/vue/css/ionic-swiper.css"

export default defineComponent({
  components: {
    LanguageSelectCard,
    Swiper,
    SwiperSlide,
    IonImg,
    IonButton,
    IonCard,
    IonCardContent,
    IonCardHeader,
    IonCardSubtitle,
    IonCardTitle,
    IonList,
    IonItem,
    IonLabel,
    IonText,
  },
  data() {
    return {
      reachedEnd: false,
    }
  },
  computed: {
    slides(): Slide[] {
      const slides: Slide[] = []

      let index = 1
      while (true) { // eslint-disable-line no-constant-condition
        const headingKey = `onboarding[${index}].heading`
        const currentHeading = this.$t(headingKey)
        if (headingKey === currentHeading) {
          break
        }

        const subheadingKey = `onboarding[${index}].subheading`
        const subheading = this.$t(subheadingKey)

        const instructions: string[] = []
        let instructionIndex = 0
        while (true) { // eslint-disable-line no-constant-condition
          const instructionKey = `onboarding[${index}].instructions[${instructionIndex}]`
          const currentInstruction = this.$t(instructionKey)
          if (instructionKey === currentInstruction) {
            break
          }
          instructions.push(currentInstruction)
          instructionIndex++
        }

        slides.push({
          heading: currentHeading,
          subheading: subheading,
          image: `../img/onboarding/slide-${index}.png`,
          instructions: instructions,
        })

        index++
      }

      return slides
    },
  },
  methods: {
    continueButtonClicked() {
      if (this.reachedEnd) {
        this.settingStore.persistable.miscellaneous.onboardingPresented = true
        this.router.replace("/")
      }
    },
  },
  setup() {
    return {
      settingStore: useSettingStore(),
      router: useIonRouter(),
      modules: [Keyboard, Navigation, Pagination, IonicSlides],
    }
  },
})

interface Slide {
  heading: string
  subheading: string
  image: string
  instructions: string[]
}
