
import {IonButton, IonItem, IonLabel, IonList, IonPopover, IonTextarea} from "@ionic/vue"
import {defineComponent} from "vue"
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome"

export default defineComponent({
  components: {IonButton, IonLabel, IonTextarea, IonItem, IonPopover, IonList, FontAwesomeIcon},
  emits: ["input-changed"],
  props: {
    value: {
      required: true,
      type: String,
    },
    suggestions: {
      required: true,
      type: Array,
    },
  },
  data() {
    return {
      inputText: this.value,
      brackets: ["(", ")", "[", "]"],
      isOpen: false,
      popoverEvent: null,
    }
  },
  computed: {
    options() {
      return [...this.suggestions, ...this.brackets]
    },
  },
  methods: {
    togglePopover(e: any) {
      if (!this.isOpen) this.popoverEvent = e
      this.isOpen = !this.isOpen
    },
    popoverDidDismiss() {
      this.isOpen = false
    },
    selectOption(index: number) {
      // Get the selected option
      const selectedOption = this.options[index] as string

      // Check if the last character of inputText is a space or not
      const hasSpace = this.inputText.slice(-1) === " "

      // Check if the last character of inputText is an opening bracket
      const lastCharIsOpeningBracket = this.inputText.slice(-1) === "(" || this.inputText.slice(-1) === "["

      // Check if the inputText is empty
      const isEmpty = this.inputText.length === 0

      // Check if the selected option is a bracket or not
      const isSelectedOptionBracket = this.brackets.includes(selectedOption)

      // Determine if a space should be added before the selected option
      const shouldAddSpace = !isEmpty && !hasSpace && !lastCharIsOpeningBracket

      // Add a space before the selected option if needed
      if (shouldAddSpace) this.inputText += " "
      this.inputText += selectedOption

      // If the selected option is an opening bracket, remove any space after it
      if (selectedOption === "(" || selectedOption === "[") {
        this.inputText = this.inputText.replace(/\(\s+|\[\s+/, (match) => match.trim())
      }
      // If the selected option is a closing bracket, remove any space before it
      if (selectedOption === ")" || selectedOption === "]") {
        this.inputText = this.inputText.replace(/\s+\)|\s+\]/, (match) => match.trim())
      }

      // Remove the selected option from the options array if it's not a bracket
      if (!isSelectedOptionBracket) {
        this.options.splice(index, 1)
      }

      // Without this, the watcher sometimes doesn't trigger somehow
      this.$emit("input-changed", this.inputText)
    },
  },
  watch: {
    value: {
      immediate: true,
      handler(text) {
        this.inputText = text
      },
    },
    inputText: {
      handler(text) {
        this.$emit("input-changed", text)
      },
    },
  },
})
