import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AlertAction = _resolveComponent("AlertAction")!

  return (_openBlock(), _createBlock(_component_AlertAction, {
    "alert-header-text": _ctx.$t('prompt.deleteItemConfirm'),
    "on-confirm": _ctx.deleteItem,
    "icon-color": "sunset-4",
    "icon-type": "fa-trash",
    onConfirmed: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('deleted-item', $event)))
  }, null, 8, ["alert-header-text", "on-confirm"]))
}