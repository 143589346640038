
import {IonButton, IonCol, IonGrid, IonItem, IonLabel, IonRow, IonSearchbar} from "@ionic/vue"
import {defineComponent, PropType} from "vue"
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome"
import LocationDropdown from "@/components/LocationDropdown.vue"
import ScannerContainer from "@/components/ScannerContainer.vue"
import {useLocationStore} from "@/stores/location"
import {useSettingStore} from "@/stores/setting"
import {Location, ParsedBarcode} from "@/gql/types"

export default defineComponent({
  components: {
    ScannerContainer,
    LocationDropdown,
    IonCol,
    IonGrid,
    IonRow,
    IonItem,
    IonLabel,
    IonSearchbar,
    IonButton,
    FontAwesomeIcon,
  },
  emits: ["selected-location"],
  props: {
    locations: {
      required: true,
      type: Array as PropType<Array<Location>>,
    },
    preSelectedLocation: {
      required: false,
      default: null,
      type: Object as PropType<Location>,
    },
    showRootLocation: {
      required: false,
      default: false,
      type: Boolean,
    },
    noSidePadding: {
      required: false,
      default: true,
      type: Boolean,
    },
  },
  data() {
    return {
      query: "",
      selectedLocation: null as Location | null,
      searchFocused: false,
    }
  },
  computed: {
    filteredLocations() {
      const withoutRoot = (l: Location) => this.showRootLocation || l.id !== this.locationStore.rootLocation.id
      const matchingSearchQuery = (l: Location) => l.name.toLowerCase().indexOf(this.query.toLowerCase()) >= 0 || (l.barcode?.content?.indexOf(this.query) ?? -1) >= 0
      return this.locations.filter(withoutRoot).filter(matchingSearchQuery)
    },
    searchPlaceholder() {
      const exampleLocation = this.locationStore.rotatingLocation()
      if (exampleLocation) {
        return `${this.$t("general.forExample")} ${exampleLocation.name}`
      } else {
        return ""
      }
    },
  },
  methods: {
    selectLocation(location: Location) {
      this.selectedLocation = location
      this.$emit("selected-location", location)
    },
    async scanBarcode() {
      await (this.$refs.scannerContainer as InstanceType<typeof ScannerContainer>).startScan()
    },
    handleScannedBarcode(barcode: ParsedBarcode | null) {
      if (barcode) this.query = barcode.content
    },
    focusSearchbar() {
      this.searchFocused = true
    },
    blurSearchbar() {
      this.searchFocused = false
    },
  },
  watch: {
    filteredLocations: {
      handler(locations) {
        if (locations.length > 0) {
          this.selectedLocation = locations[0]
        } else {
          this.selectedLocation = this.locationStore.rootLocation
        }
      },
    },
    preSelectedLocation: {
      immediate: true,
      handler(location: Location) {
        if (location) this.selectLocation(location)
      },
    },
  },
  setup() {
    return {
      locationStore: useLocationStore(),
      settingStore: useSettingStore(),
    }
  },
})
