
import {IonContent, IonPopover} from "@ionic/vue"
import {defineComponent, PropType} from "vue"
import {Item} from "@/gql/types"

export default defineComponent({
  components: {IonPopover, IonContent},
  props: {
    item: {
      required: true,
      type: Object as PropType<Item>,
    },
  },
  data() {
    return {
      isActive: false,
    }
  },
  methods: {
    open() {
      this.isActive = true
    },
    close() {
      this.isActive = false
    },
  },
})
