import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "desktop-width-sm" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_list = _resolveComponent("ion-list")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_ion_list, {
      inset: true,
      lines: "full"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_ion_item, {
          detail: true,
          button: "",
          "router-direction": "forward",
          "router-link": "/management/location"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_ion_label, null, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$tc('entity.location.name', 2)), 1)
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_component_ion_item, {
          detail: true,
          button: "",
          "router-direction": "forward",
          "router-link": "/management/category"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_ion_label, null, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$tc('entity.category.name', 2)), 1)
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_component_ion_item, {
          detail: true,
          button: "",
          "router-direction": "forward",
          "router-link": "/management/inventory"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_ion_label, null, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$tc('entity.inventory.name', 2)), 1)
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ]))
}