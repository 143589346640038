
import {IonItem, IonLabel, IonList, IonTextarea} from "@ionic/vue"
import {defineComponent, PropType} from "vue"
import EditAction from "@/components/EditAction.vue"
import LocationSelect from "@/components/LocationSelect.vue"
import CategorySelect from "@/components/CategorySelect.vue"
import BarcodeEditor from "@/components/BarcodeEditor.vue"
import SuggestionsTextInput from "@/components/SuggestionsTextInput.vue"
import ImageCapture from "@/components/ImageCapture.vue"
import {useInventoryStore} from "@/stores/inventory"
import {useLocationStore} from "@/stores/location"
import {useSettingStore} from "@/stores/setting"
import {useCategoryStore} from "@/stores/category"
import {useItemStore} from "@/stores/item"
import {useBarcodeStore} from "@/stores/barcode"
import {convertGraphQlErrorToResponseInfo, createSuccessfulResponseInfo, ResponseInfo} from "@/shared/helpers"
import {Barcode, Category, Location, NewItem, ParsedBarcode} from "@/gql/types"

export default defineComponent({
  components: {
    ImageCapture,
    SuggestionsTextInput,
    BarcodeEditor,
    CategorySelect,
    LocationSelect,
    EditAction,
    IonList,
    IonItem,
    IonLabel,
    IonTextarea,
  },
  emits: ["created-item", "canceled"],
  props: {
    modalActive: {
      required: true,
      default: false,
      type: Boolean,
    },
    category: {
      required: true,
      type: Object as PropType<Category>,
    },
    parentLocation: {
      required: true,
      type: Object as PropType<Location>,
    },
    barcode: {
      required: false,
      default: null,
      type: Object as PropType<ParsedBarcode>,
    },
  },
  data() {
    return {
      newItem: this.generateEmptyNewItem(),
    }
  },
  methods: {
    async confirmItemCreate(newItem: NewItem): Promise<ResponseInfo> {
      if (!this.barcodeStore.validateBarcode(newItem)) {
        return {messageKey: "failure.barcode.create.requiredFields", isError: true}
      }

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore: Type assertion needed here due to incomplete type annotations
      delete newItem.barcode
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore: Type assertion needed here due to incomplete type annotations
      delete newItem.image

      try {
        await this.itemStore.createItem(newItem)
        this.newItem = this.generateEmptyNewItem()
        return createSuccessfulResponseInfo("success.item.create")
      } catch (e) {
        return convertGraphQlErrorToResponseInfo(e)
      }
    },
    categoryChanged(category: Category) {
      this.settingStore.persistable.itemCreation.lastSelection.category = category
    },
    locationChanged(location: Location) {
      this.settingStore.persistable.itemCreation.lastSelection.location = location
    },
    suggestedKeywords(active: any): string[] {
      return [...(active.barcode?.labels ?? []), ...(active.image?.labels ?? [])]
    },
    generateEmptyNewItem(): NewItem {
      return {
        name: "",
        note: "",
        categoryId: this.category.id,
        locationId: this.parentLocation.id,
        barcodeId: "",
        imageId: "",
      } as NewItem
    },
  },
  watch: {
    barcode: {
      immediate: true,
      deep: true,
      async handler(barcode: ParsedBarcode) {
        if (this.barcodeStore.validateParsedBarcode(barcode)) {
          const persistedBarcode: Barcode = await this.barcodeStore.create(barcode)
          this.newItem.barcodeId = persistedBarcode.id
          this.newItem.barcode = persistedBarcode
        }
      },
    },
  },
  setup() {
    return {
      inventoryStore: useInventoryStore(),
      itemStore: useItemStore(),
      locationStore: useLocationStore(),
      categoryStore: useCategoryStore(),
      barcodeStore: useBarcodeStore(),
      settingStore: useSettingStore(),
    }
  },
})
