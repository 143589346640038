
import {
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonCol,
  IonGrid,
  IonItem,
  IonList,
  IonRow,
  toastController,
} from "@ionic/vue"
import {defineComponent} from "vue"
import CreateInventoryAction from "@/components/CreateInventoryAction.vue"
import CreateInventoryShareAction from "@/components/CreateInventoryShareAction.vue"
import EditInventoryAction from "@/components/EditInventoryAction.vue"
import DeleteInventoryAction from "@/components/DeleteInventoryAction.vue"
import InventorySelect from "@/components/InventorySelect.vue"
import SharedWithAction from "@/components/SharedWithAction.vue"
import {useAuthenticationStore} from "@/stores/authentication"
import {useInventoryStore} from "@/stores/inventory"
import {useSettingStore} from "@/stores/setting"
import {Inventory} from "@/gql/types"
import {ResponseInfo} from "@/shared/helpers"

export default defineComponent({
  components: {
    CreateInventoryAction,
    CreateInventoryShareAction,
    EditInventoryAction,
    DeleteInventoryAction,
    InventorySelect,
    SharedWithAction,
    IonCard,
    IonCardHeader,
    IonCardTitle,
    IonCardContent,
    IonGrid,
    IonRow,
    IonCol,
    IonList,
    IonItem,
  },
  data() {
    return {
      selectedInventory: undefined as unknown as Inventory,
      sharedWithAccounts: [] as string[],
    }
  },
  methods: {
    selectDefaultInventory() {
      this.selectedInventory = this.inventoryStore.ownedInventories[0]
    },
    async presentResponseInfoToast(responseInfo: ResponseInfo) {
      const toast = await toastController.create({
        message: `${this.$t(responseInfo.messageKey)}!`,
        duration: 5560,
        position: "top",
        buttons: [
          {
            text: this.$t("button.ok"),
            role: "cancel",
          },
        ],
        color: responseInfo.isError ? "danger" : "",
      })
      await toast.present()
    },
    async editedInventory(responseInfo: ResponseInfo) {
      await this.presentResponseInfoToast(responseInfo)
      this.selectDefaultInventory()
    },
    async createdShare(responseInfo: ResponseInfo) {
      await this.presentResponseInfoToast(responseInfo)
      await this.refreshSharedWithAccounts()
    },
    async deletedShare(responseInfo: ResponseInfo) {
      await this.presentResponseInfoToast(responseInfo)
      await this.refreshSharedWithAccounts()
    },
    async refreshSharedWithAccounts() {
      this.sharedWithAccounts = await this.inventoryStore.getSharedWithAccounts(this.selectedInventory)
    },
  },
  watch: {
    selectedInventory: {
      async handler() {
        this.refreshSharedWithAccounts()
      },
    },
  },
  created() {
    this.selectDefaultInventory()
  },
  setup() {
    return {
      authStore: useAuthenticationStore(),
      inventoryStore: useInventoryStore(),
      settingStore: useSettingStore(),
    }
  },
})
