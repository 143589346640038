
import {IonBackButton, IonButtons, IonContent, IonHeader, IonPage, IonTitle, IonToolbar} from "@ionic/vue"
import {defineComponent} from "vue"
import LogoContainer from "@/components/LogoContainer.vue"
import CategoryManagementContainer from "@/components/CategoryManagementContainer.vue"
import {useSettingStore} from "@/stores/setting"

export default defineComponent({
  components: {
    CategoryManagementContainer,
    LogoContainer,
    IonPage,
    IonHeader,
    IonToolbar,
    IonTitle,
    IonContent,
    IonButtons,
    IonBackButton,
  },
  setup() {
    return {
      settingStore: useSettingStore(),
    }
  },
})
