import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_badge = _resolveComponent("ion-badge")!

  return (_openBlock(), _createBlock(_component_ion_badge, {
    class: _normalizeClass({'tooltip-cursor': _ctx.isEllipsisActive}),
    color: _ctx.color,
    onMouseleave: _ctx.closeTooltip,
    onMouseover: _ctx.openTooltip
  }, {
    default: _withCtx(() => [
      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ]),
    _: 3
  }, 8, ["class", "color", "onMouseleave", "onMouseover"]))
}