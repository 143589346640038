
import {actionSheetController, IonButton} from "@ionic/vue"
import {defineComponent} from "vue"
import {useAccountStore} from "@/stores/account"
import {useAuthenticationStore} from "@/stores/authentication"

export default defineComponent({
  components: {
    IonButton,
  },
  methods: {
    async deleteAccount() {
      if (!(await this.confirm())) return
      await this.accountStore.deleteAccount()
      await this.authStore.logout()
    },
    async confirm() {
      const actionSheet = await actionSheetController.create({
        header: this.$t("prompt.deleteAccountConfirm"),
        buttons: [
          {
            text: this.$t("button.confirm"),
            role: "destructive",
          },
          {
            text: this.$t("button.cancel"),
            role: "cancel",
          },
        ],
      })
      await actionSheet.present()
      const result = await actionSheet.onDidDismiss()
      return result.role === "destructive"
    },
  },
  setup() {
    return {
      accountStore: useAccountStore(),
      authStore: useAuthenticationStore(),
    }
  },
})
