
import {IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle} from "@ionic/vue"
import {defineComponent} from "vue"
import LogoutButton from "@/components/LogoutButton.vue"
import DeleteAccountButton from "@/components/DeleteAccountButton.vue"
import ExportDataButton from "@/components/ExportDataButton.vue"
import {useAuthenticationStore} from "@/stores/authentication"
import {useSettingStore} from "@/stores/setting"
import {useAccountStore} from "@/stores/account"
import {SubscriptionTier} from "@/gql/types"

export default defineComponent({
  components: {
    ExportDataButton,
    DeleteAccountButton,
    LogoutButton,
    IonCard,
    IonCardHeader,
    IonCardTitle,
    IonCardSubtitle,
    IonCardContent,
  },
  setup() {
    return {
      authStore: useAuthenticationStore(),
      accountStore: useAccountStore(),
      settingStore: useSettingStore(),
      SubscriptionTier,
    }
  },
})
