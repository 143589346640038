
import {IonLabel, IonToggle} from "@ionic/vue"
import {defineComponent} from "vue"
import {useSettingStore} from "@/stores/setting"

export default defineComponent({
  components: {
    IonLabel,
    IonToggle,
  },
  methods: {
    onToggle(event: CustomEvent) {
      this.settingStore.persistable.preferences.keepFilterSortAccordionExpanded = event.detail.checked
    },
  },
  setup() {
    return {
      settingStore: useSettingStore(),
    }
  },
})
