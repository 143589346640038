export default {
  de: {
    "action": {
      "create": "Erstellen",
      "delete": "Löschen",
      "edit": "Bearbeiten",
      "general": "Aktionen",
      "modify": "Anpassen",
      "move": "Bewegen",
      "scan": "Scannen",
    },
    "button": {
      "cancel": "Abbrechen",
      "confirm": "Bestätigen",
      "continue": "Weiter",
      "createItem": "Gegenstand anlegen",
      "createLocation": "Standort anlegen",
      "exportData": "Daten exportieren",
      "login": "Einloggen",
      "logout": "Ausloggen",
      "ok": "OK",
      "quit": "Beenden",
      "restorePurchases": "Wiederherstellen",
      "retry": "Erneut versuchen",
      "select": "Auswählen",
    },
    "entity": {
      "barcode": {
        "content": "Inhalt",
        "format": "Format",
        "name": "Barcode | Barcodes",
      },
      "category": {
        "name": "Kategorie | Kategorien",
      },
      "image": {
        "name": "Bild | Bilder",
      },
      "inventory": {
        "active": "Aktives Inventar",
        "name": "Inventar | Inventare",
        "owned": "Besitzte Inventare",
      },
      "item": {
        "name": "Gegenstand | Gegenstände",
        "note": "Notiz",
      },
      "location": {
        "child": "Verschachtelter Standort | Verschachtelte Standorte",
        "hierarchy": "Hierarchie",
        "name": "Standort | Standorte",
        "parent": "Übergeordneter Standort | Übergeordnete Standorte",
      },
      "share": {
        "name": "Inventarzugang | Inventarzugänge",
      },
    },
    "failure": {
      "barcode": {
        "association": "Gescannter Barcode <code>{barcodeContent}</code> ist nicht zugewiesen",
        "create": {
          "duplicate": "Konnte den Barcode nicht registrieren da bereits einer mit diesen Daten existiert",
          "forbidden": {
            "creditsDepleted": "Konnte den Barcode nicht registrieren da alle des Abonnements bereits erschöpft sind – löschen Sie welche",
          },
          "requiredFields": "Konnte den Barcode nicht registrieren da nicht alle benötigten Felder ausgefüllt wurden",
        },
        "delete": {
          "notEmpty": "Konnte den Barcode nicht löschen da noch ein Gegenstand oder Standort ihm zugewiesen ist – weisen Sie diese neu zu oder löschen Sie sie",
        },
        "query": {
          "generic": "Barcode konnte nicht gefunden werden",
        },
      },
      "category": {
        "create": {
          "duplicate": "Konnte die Kategorie nicht anlegen da bereits eine mit diesen Daten existiert",
          "forbidden": {
            "creditsDepleted": "Konnte die Kategorie nicht anlegen da alle des Abonnements bereits erschöpft sind – löschen Sie welche",
            "name": "Konnte die Kategorie nicht anlegen da der Name für eine voreingestellte reserviert ist",
          },
          "requiredFields": "Konnte die Kategorie nicht anlegen da nicht alle benötigten Felder ausgefüllt wurden",
        },
        "delete": {
          "forbidden": {
            "predefined": "Konnte die Kategorie nicht löschen da sie eine voreingestellte ist",
          },
          "notEmpty": "Konnte die Kategorie nicht löschen da noch ein Gegenstand ihr zugewiesen ist – weisen Sie diesen neu zu oder löschen Sie ihn",
        },
        "edit": {
          "duplicate": "Konnte die Kategorie nicht bearbeiten da bereits einer mit diesen Daten existiert",
          "forbidden": {
            "name": "Konnte die Kategorie nicht bearbeiten da eine voreingestellte mit demselben Namen existiert",
            "predefined": "Konnte die Kategorie nicht bearbeiten da sie eine voreingestellte ist",
          },
          "requiredFields": "Konnte die Kategorie nicht bearbeiten da nicht alle benötigten Felder ausgefüllt wurden",
        },
        "query": {
          "generic": "Kategorie konnte nicht gefunden werden",
        },
      },
      "general": {
        "emailNotVerified": "Die E-Mail Adresse <code>{emailAddress}</code> muss verifiziert werden – schauen Sie ins Postfach",
        "error": "Error",
        "serverNotAvailable": "Server ist nicht verfügbar",
        "unknownError": "Ein unbekanntes Problem ist aufgetreten",
      },
      "image": {
        "create": {
          "forbidden": {
            "creditsDepleted": "Konnte das Bild nicht hochladen da alle des Abonnements bereits erschöpft sind",
          },
        },
        "delete": {
          "notEmpty": "Konnte das Bild nicht löschen da noch ein Gegenstand mit diesem existiert – weisen Sie dieses neu zu oder löschen Sie es",
        },
      },
      "inventory": {
        "create": {
          "duplicate": "Konnte das Inventar nicht anlegen da bereits eines mit diesen Daten existiert",
          "forbidden": {
            "creditsDepleted": "Konnte das Inventar nicht anlegen da alle des Abonnements bereits erschöpft sind – löschen Sie welche",
          },
          "requiredFields": "Konnte das Inventar nicht anlegen da nicht alle benötigten Felder ausgefüllt wurden",
        },
        "delete": {
          "generic": "Konnte das Inventar nicht löschen",
          "notEmpty": "Konnte das Inventar nicht löschen da noch Gegenstände zugewiesen sind – löschen Sie diese zuerst",
        },
        "edit": {
          "duplicate": "Konnte das Inventar nicht bearbeiten da bereits eines mit diesen Daten existiert",
          "generic": "Konnte das Inventar nicht bearbeiten da nicht alle benötigten Felder ausgefüllt wurden",
          "requiredFields": "Konnte das Inventar nicht bearbeiten da nicht alle benötigten Felder ausgefüllt wurden",
        },
      },
      "item": {
        "assignBarcode": {
          "generic": "Konnte den Barcode nicht dem Gegenstand zuweisen",
          "wrongEntity": "Konnte den Barcode nicht dem Gegentand zuweisen da er bereits einem Standort zugewiesen ist",
        },
        "create": {
          "duplicate": "Konnte den Gegenstand nicht anlegen da bereits einer mit denselben Daten existiert",
          "forbidden": {
            "creditsDepleted": "Konnte den Gegenstand nicht anlegen da alle des Abonnements bereits erschöpft sind – löschen Sie welche",
          },
          "generic": "Konnte den Gegenstand nicht anlegen",
          "requiredFields": "Konnte den Gegentand nicht anlegen da nicht alle benötigten Felder ausgefüllt wurden",
        },
        "delete": {
          "generic": "Konnte den Gegenstand nicht löschen",
        },
        "edit": {
          "duplicate": "Konnte diesen Gegenstand nicht bearbeiten da bereits einer mit diesen Daten existiert",
          "requiredFields": "Konnte den Gegentand nicht bearbeiten da nicht alle benötigten Felder ausgefüllt wurden",
        },
        "move": {
          "duplicate": "Konnte diesen Gegenstand nicht bewegen da bereits ein anderer mit diesen Daten existiert",
        },
      },
      "location": {
        "assignBarcode": {
          "alreadyAssigned": "Konnte den Barcode dem Standort nicht zuweisen da dieser bereits einem anderen zugewisen ist",
          "generic": "Konnte den Barcode dem Standort nicht zuweisen",
          "wrongEntity": "Konnte den Barcode dem Standort nicht zuweisen da dieser bereits einem Gegenstand zugewiesen ist",
        },
        "create": {
          "duplicate": "Konnte den Standort nicht anlegen da bereits einer mit diesen Daten existiert",
          "forbidden": {
            "creditsDepleted": "Konnte den Standort nicht anlegen da alle des Abonnements bereits erschöpft sind – löschen Sie welche",
            "name": "Konnte den Standort nicht anlegen da der Name für einen voreingestellten reserviert ist",
          },
          "requiredFields": "Konnte den Standort nicht anlegen da nicht alle benötigten Felder ausgefüllt wurden",
        },
        "delete": {
          "forbidden": {
            "predefined": "Konnte den Standort nicht löschen da er ein voreingestellter ist",
          },
          "notEmpty": "Konnte den Standort nicht löschen da noch Gegenstände zugewiesen sind – weisen Sie diese neu zu oder löschen Sie sie",
        },
        "edit": {
          "duplicate": "Konnte den Standort nicht bearbeiten da bereits einer mit diesen Daten existiert",
          "forbidden": {
            "name": "Konnte den Standort nicht bearbeiten da ein voreingestellter mit demselben Namen existiert",
            "predefined": "Konnte den Standort nicht bearbeiten da er ein voreingestellter ist",
          },
          "requiredFields": "Konnte den Standort nicht bearbeiten da nicht alle benötigten Felder ausgefüllt wurden",
        },
        "move": {
          "duplicate": "Konnte den Standort nicht bewegen da bereits ein anderer mit diesen Daten existiert",
          "forbidden": {
            "parentchild": "Konnte den Standort nicht in sich selbst bewegen",
            "predefined": "Konnte den Standort nicht bewegen da er ein voreingestellter ist",
          },
        },
        "query": {
          "generic": "Standort konnte nicht gefunden werden",
        },
      },
      "share": {
        "create": {
          "accountEmailUnknown": "Konnte die Einladung zum Inventarzugang nicht anlegen da der eingeladene Nutzer nicht existiert",
          "forbidden": {
            "creditsDepleted": "Konnte die Einladung zum Inventarzugang nicht anlegen da alle des Abonnements bereits erschöpft sind – warten Sie bis die bisherigen abgelaufen sind",
          },
        },
        "delete": {
          "accountEmailUnknown": "Konnte die Einladung zum Inventarzugang nicht löschen da der eingeladene Nutzer nicht existiert",
          "generic": "Konnte die Einladung zum Inventarzugang nicht löschen",
        },
      },
      "subscription": {
        "create": {
          "generic": "Ein Fehler ist aufgetreten beim Versuch das Abonnement zu Erstellen",
        },
      },
    },
    "general": {
      "account": "Konto",
      "annual": "jährlich",
      "barcodeUnknown": "Barcode unbekannt",
      "camera": "Kamera",
      "checkSubscriptionProvider": "Sie haben das Abonnement ursprünglich woanders abgeschlossen; bitte suchen Sie diese Plattform auf um Ihr Abonnement zu bearbeiten",
      "emailAddress": "E-Mail Adresse",
      "feedback": "Feedback",
      "forExample": "z.B.",
      "installScannerApp": "Bitte installieren Sie eine unterstützte Scanner-App",
      "language": "Sprache",
      "links": "Links",
      "loading": "Lädt",
      "management": "Administration",
      "miscellaneous": "Weiteres",
      "monthly": "monatlich",
      "name": "Name",
      "noActiveSubscription": "Sie haben kein aktives Abonnement",
      "notAvailable": "k.A.",
      "preferences": "Präferenzen",
      "privacyPolicy": "Datenschutzerklärung",
      "search": "Suche",
      "selection": "Auswahl",
      "settings": "Einstellungen",
      "sharedWith": "Geteilt mit",
      "start": "Start",
      "stop": "Stop",
      "subscription": "Abonnement",
      "support": "Hilfe",
      "termsAndConditions": "Geschäftsbedingungen",
      "tutorial": "Tutorial",
      "unknown": "Unbekannt",
      "until": "bis",
      "upgrade": "Upgrade",
      "webApp": "Web App",
      "website": "Website",
    },
    "onboarding": [
      {
        "heading": "Sagen Sie dem Chaos auf Wiedersehen und der Ordnung Hallo mit <strong class='app-name'>bring.order2chaos</strong>",
        "subheading": "Unsere App hilft Ihnen, <strong class='app-slogan'>Ihre Habseligkeiten zu inventarisieren</strong> an einem praktischen Ort mit beeindruckenden Funktionen, und sie funktioniert, wo immer Sie sind – als mobile App und Web-App.",
      },
      {
        "heading": "Standorte",
        "instructions": [
          "Erstellen Sie jede Schublade einzeln oder einfach Raum für Raum – ganz wie Sie es möchten",
          "Verschachteln Sie Standorte ineinander, um eine Hierarchie zu erstellen – genau wie in der Realität",
          "Die voreingestellten Standorte '~' und '?' repräsentieren den übergreifenden Standort aller anderen Standorte und den Standort für alle Gegenstände, deren Aufenthaltsort unbekannt ist",
        ],
        "subheading": "Erstellen Sie Standorte, um Ihre Räume abzubilden",
      },
      {
        "heading": "Gegenstände",
        "instructions": [
          "Fügen Sie neuen Gegenständen einen Namen, eine Kategorie, einen Standort und eine optionale Notiz hinzu",
          "Bearbeiten, verschieben, als verloren erklären oder löschen Sie Gegenstände mit einem einzigen Klick",
          "Weisen Sie Gegenständen Barcodes zu, um sie schneller zu finden",
          "Verwenden Sie fortgeschrittene Bildanalyse für eine schnellere Gegenstandserstellung",
        ],
        "subheading": "Erstellen Sie Gegenstände mit unseren intelligenten Funktionen",
      },
      {
        "heading": "Barcodes",
        "instructions": [
          "Scannen Sie einen Barcode in über 10 unterstützten Formaten (nur auf Mobilgeräten)",
          "Wenn einem Gegenstand oder Standort ein Barcode zugeordnet ist, wird automatisch danach gesucht",
          "Andernfalls können Sie direkt einen neuen Gegenstand oder Standort erstellen – egal ob er bereits vorhanden war oder Sie ihn ausgedruckt haben",
          "Wenn der Barcode einem Produkt zugehörig ist, wird automatisch ein Name vorgeschlagen",
        ],
        "subheading": "Nutzen Sie Barcodes für eine schnellere Verwendung",
      },
      {
        "heading": "Bildanalyse",
        "instructions": [
          "Fügen Sie ein Bild hinzu, und der Inhalt wird analysiert, um Namensfragmente vorzuschlagen",
          "Sie können diese Namensfragmente verwenden, um den Namen des Gegenstands dynamisch zusammenzusetzen, anstatt ihn manuell einzugeben",
          "Sowohl der im Bild sichtbare Text als auch die Objekte werden verwendet, um Namen vorzuschlagen",
        ],
        "subheading": "Lassen Sie intelligente Algorithmen die Arbeit für Sie erledigen",
      },
      {
        "heading": "Inventare",
        "instructions": [
          "Erstellen Sie Inventare, um Ihre Gegenstände in Gruppen oder an verschiedenen physischen Standorten zu organisieren",
          "Laden Sie Ihre Mitbewohner oder Familienmitglieder ein, die von Ihnen gewählten Inventare gemeinsam mit Ihnen zu verwalten – beachten Sie, dass diese Funktion ein Plus-Abonnement erfordert (nur ein Konto benötigt es)",
        ],
        "subheading": "Teilen Sie Ihre Inventare mit anderen",
      },
      {
        "heading": "Suchen, filtern und sortieren",
        "instructions": [
          "Verwenden Sie Filter und Sortieroptionen, um Gegenstände so schnell wie möglich zu finden",
          "Die Suchfunktion durchsucht alle Informationen zu Gegenständen und Standorten, einschließlich Barcodes und Bildinformationen",
          "Wann immer Sie die Barcode-Schaltfläche sehen, kann ein Barcode gescannt werden, anstatt Informationen manuell einzugeben",
        ],
        "subheading": "Finden Sie Gegenstände blitzschnell",
      },
    ],
    "prompt": {
      "confirmDataExport": "Möchten Sie ihre Daten wirklich als TSV-Datei exportieren?",
      "createBarcodeConfirm": "Wollen Sie den Barcode <code>{barcodeContent}</code> registrieren?",
      "declareItemLostConfirm": "Wollen Sie diesen Gegenstand wirklich als verloren gegangen deklarieren?",
      "deleteAccountConfirm": "Wollen Sie ihr Konto wirklich permanent löschen?",
      "deleteCategoryConfirm": "Wollen Sie diese Kategorie wirklich löschen?",
      "deleteInventoryConfirm": "Wollen Sie dieses Inventar wirklich löschen?",
      "deleteItemConfirm": "Wollen Sie diesen Gegenstand wirklich löschen?",
      "deleteLocationConfirm": "Wollen Sie diesen Standort wirklich löschen?",
      "deleteShareConfirm": "Wollen Sie diese Inventarzugang wirklich löschen?",
      "invalidBarcodeEntryWarning": "Der eingegebene Barcode ist ungültig für das gewählte Format!",
      "subscriptionSelectionConfirm": "Möchten Sie unsere Abonnements sehen?",
    },
    "search": {
      "ascending": "Aufsteigend",
      "category": "Kategorie",
      "descending": "Absteigend",
      "filters": "Filter",
      "item": "Gegenstand",
      "location": "Standort",
      "query": "Suchbegriff",
      "queryTooltip": "Sucht in allen Feldern",
      "results": "Ergebnisse",
      "sortBy": "Nach",
      "sortDirection": "Richtung",
      "sorting": "Sortierung",
    },
    "setting": {
      "filterSortingPermanentlyActive": "Filter & Sortierung standardmäßig ausklappen",
      "inventorySelection": "Aktives Inventar",
      "languageSelection": "Sprachauswahl",
    },
    "subscription": {
      "plus": {
        "features": [
          "10000 Gegenstände",
          "<strong>10000 Standorte</strong>",
          "<strong>10000 Kategorien</strong>",
          "<strong>10000 Barcodes</strong>",
          "<strong>10000 Bilduploads</strong>",
          "<strong>10 Inventare</strong>",
          "<strong>10 Inventarteilungen</strong>",
          "Datenexport",
        ],
        "title": "Plus",
      },
      "starter": {
        "features": [
          "<strong>10000 Gegenstände</strong>",
          "<strong>1000 Standorte</strong>",
          "<strong>1000 Kategorien</strong>",
          "<strong>1000 Barcodes</strong>",
          "10 Bilduploads",
          "1 Inventare",
          "0 Inventarteilungen",
          "<strong>Datenexport</strong>",
        ],
        "title": "Starter",
      },
    },
    "success": {
      "barcode": {
        "association": {
          "item": "Setzte die Suchanfrage auf den gescannten Barcode",
          "location": "Setzte den Standortfilter auf den gescannten <em>{locationName}</em> Standort",
        },
      },
      "category": {
        "create": "Kategorie wurde erfolgreich angelegt",
        "delete": "Kategorie wurde erfolgreich gelöscht",
        "edit": "Kategorie wurde erfolgreich bearbeitet",
      },
      "inventory": {
        "changeActive": "Aktives Inventar wurde erfolgreich geändert",
        "create": "Inventar wurde erfolgreich angelegt",
        "delete": "Inventar wurde erfolgreich gelöscht",
        "edit": "Inventar wurde erfolgreich bearbeitet",
      },
      "item": {
        "create": "Gegenstand wurde erfolgreich angelegt",
        "declarelost": "Gegenstand wurde erfolgreich als verloren deklariert",
        "delete": "Gegenstand wurde erfolgreich gelöscht",
        "edit": "Gegenstand wurde erfolgreich bearbeitet",
        "move": "Gegenstand wurde erfolgreich bewegt",
      },
      "location": {
        "create": "Standort wurde erfolgreich angelegt",
        "delete": "Standort wurde erfolgreich gelöscht",
        "edit": "Standort wurde erfolgreich bearbeitet",
        "move": "Standort wurde erfolgreich bewegt",
      },
      "share": {
        "create": "Die Einladung zum Inventarzugang wurde erfolgreich gesendet falls dieser Nutzer existiert",
        "delete": "Inventarzugang wurde erfolgreich gelöscht",
      },
    },
  },
  en: {
    "action": {
      "create": "Create",
      "delete": "Delete",
      "edit": "Edit",
      "general": "Actions",
      "modify": "Modify",
      "move": "Move",
      "scan": "Scan",
    },
    "button": {
      "cancel": "Cancel",
      "confirm": "Confirm",
      "continue": "Continue",
      "createItem": "Create item",
      "createLocation": "Create location",
      "exportData": "Export Data",
      "login": "Log In",
      "logout": "Log Out",
      "ok": "OK",
      "quit": "Quit",
      "restorePurchases": "Restore",
      "retry": "Retry",
      "select": "Select",
    },
    "entity": {
      "barcode": {
        "content": "Content",
        "format": "Format",
        "name": "Barcode | Barcodes",
      },
      "category": {
        "name": "Category | Categories",
      },
      "image": {
        "name": "Image | Images",
      },
      "inventory": {
        "active": "Active Inventory",
        "name": "Inventory | Inventories",
        "owned": "Owned Inventories",
      },
      "item": {
        "name": "Item | Items",
        "note": "Note",
      },
      "location": {
        "child": "Nested Location | Nested Locations",
        "hierarchy": "Hierarchy",
        "name": "Location | Locations",
        "parent": "Overarching Location | Overarching Locations",
      },
      "share": {
        "name": "Inventory share | Inventory shares",
      },
    },
    "failure": {
      "barcode": {
        "association": "Scanned barcode <code>{barcodeContent}</code> is not associated to anything yet",
        "create": {
          "duplicate": "Could not create barcode as another one with the same information exists already",
          "forbidden": {
            "creditsDepleted": "Could not create barcode since all credits of the subscription are depleted – delete some",
          },
          "requiredFields": "Could not create barcode since not all required fields were set",
        },
        "delete": {
          "notEmpty": "Could not delete barcode since there's still items or locations assigned to it – reassign or delete them first",
        },
        "query": {
          "generic": "Could not find barcode",
        },
      },
      "category": {
        "create": {
          "duplicate": "Could not create category since another one with that information exists already",
          "forbidden": {
            "creditsDepleted": "Could not create category since all credits of the subscription are depleted – delete some",
            "name": "Could not create category since the name is reserved for a preset category",
          },
          "requiredFields": "Could not create category since not all required fields were set",
        },
        "delete": {
          "forbidden": {
            "predefined": "Could not delete category since it's a preset one",
          },
          "notEmpty": "Could not delete category since there's still items assigned to it – reassign or delete them first",
        },
        "edit": {
          "duplicate": "Could not edit category since another one with that information exists already",
          "forbidden": {
            "name": "Could not edit category since the name is reserved for a preset one",
            "predefined": "Could not edit category since it's a preset one",
          },
          "requiredFields": "Could not edit category since not all required fields were set",
        },
        "query": {
          "generic": "Could not find category",
        },
      },
      "general": {
        "emailNotVerified": "The email address <code>{emailAddress}</code> has to be verified – check the inbox",
        "error": "Error",
        "serverNotAvailable": "Server is not available",
        "unknownError": "An unknown error occurred",
      },
      "image": {
        "create": {
          "forbidden": {
            "creditsDepleted": "Could not create image since all credits of the subscription are depleted",
          },
        },
        "delete": {
          "notEmpty": "Could not delete image since there's still an item with it – reassign or delete it first",
        },
      },
      "inventory": {
        "create": {
          "duplicate": "Could not create inventory since another one with that information exists already",
          "forbidden": {
            "creditsDepleted": "Could not create inventory since all credits of the subscription are depleted – delete some",
          },
          "requiredFields": "Could not create inventory since not all required fields were set",
        },
        "delete": {
          "generic": "Could not delete inventory",
          "notEmpty": "Could not delete inventory since there's still items in it – delete them first",
        },
        "edit": {
          "duplicate": "Could not edit inventory since another one with that information exists already",
          "generic": "Could not edit inventory",
          "requiredFields": "Could not edit inventory since not all required fields were set",
        },
      },
      "item": {
        "assignBarcode": {
          "generic": "Could not assign the barcode to the item",
          "wrongEntity": "Could not assign the barcode to the item since it's already assigned to a location",
        },
        "create": {
          "duplicate": "Could not create item since another one with that information exists already",
          "forbidden": {
            "creditsDepleted": "Could not create item since all credits of the subscription are depleted – delete some",
          },
          "generic": "Could not create item",
          "requiredFields": "Could not create item since not all required fields were set",
        },
        "delete": {
          "generic": "Could not delete the item",
        },
        "edit": {
          "duplicate": "Could not edit item since another one already exists with that information",
          "requiredFields": "Could not edit item since not all required fields were set",
        },
        "move": {
          "duplicate": "Could not edit item since another one already exists with that information",
        },
      },
      "location": {
        "assignBarcode": {
          "alreadyAssigned": "Could not assign the barcode to the location since it's already assigned to another location",
          "generic": "Could not assign the barcode to the location",
          "wrongEntity": "Could not assign the barcode to the location since it's already assigned to an item",
        },
        "create": {
          "duplicate": "Could not create location since another one with that information exists already",
          "forbidden": {
            "creditsDepleted": "Could not create location since all credits of the subscription are depleted – delete some",
            "name": "Could not create location since the name is reserved for a preset location",
          },
          "requiredFields": "Could not create location since not all required fields were set",
        },
        "delete": {
          "forbidden": {
            "predefined": "Could not delete location since it's a preset location",
          },
          "notEmpty": "Could not delete location since there's still items assigned to it or locations in it – reassign or delete them first",
        },
        "edit": {
          "duplicate": "Could not edit location since another one with that information exists already",
          "forbidden": {
            "name": "Could not edit location since the name is reserved for a preset location",
            "predefined": "Could not edit location since it's a preset one",
          },
          "requiredFields": "Could not edit location since not all required fields were set",
        },
        "move": {
          "duplicate": "Could not move location since another one with that information exists already",
          "forbidden": {
            "parentchild": "Could not move location into itself",
            "predefined": "Could not move location since it's a preset location",
          },
        },
        "query": {
          "generic": "Could not find location",
        },
      },
      "share": {
        "create": {
          "accountEmailUnknown": "Could not create inventory invitation since the invitationd account does not exist",
          "forbidden": {
            "creditsDepleted": "Could not create inventory invitation since all credits of the subscription are depleted – wait for others to expire",
          },
        },
        "delete": {
          "accountEmailUnknown": "Could not delete inventory invitation since the invitationd account does not exist",
          "generic": "Could not delete inventory invitation",
        },
      },
      "subscription": {
        "create": {
          "generic": "An error occurred while attempting to create the subscription",
        },
      },
    },
    "general": {
      "account": "Account",
      "annual": "yearly",
      "barcodeUnknown": "Unknown Barcode",
      "camera": "Camera",
      "checkSubscriptionProvider": "You originally entered the subscription somewhere else; Please visit that platform to modify your subscription",
      "emailAddress": "Email address",
      "feedback": "Feedback",
      "forExample": "e.g.,",
      "installScannerApp": "Please install the supported scanner app",
      "language": "Language",
      "links": "Links",
      "loading": "Loading",
      "management": "Management",
      "miscellaneous": "Miscellaneous",
      "monthly": "monthly",
      "name": "Name",
      "noActiveSubscription": "You do not have an active subscription",
      "notAvailable": "N/A",
      "preferences": "Preferences",
      "privacyPolicy": "Privacy Policy",
      "search": "Search",
      "selection": "Selection",
      "settings": "Settings",
      "sharedWith": "Shared with",
      "start": "Start",
      "stop": "Stop",
      "subscription": "Subscription",
      "support": "Support",
      "termsAndConditions": "Terms & Conditions",
      "tutorial": "Tutorial",
      "unknown": "Unknown",
      "until": "until",
      "upgrade": "Upgrade",
      "webApp": "Web App",
      "website": "Website",
    },
    "onboarding": [
      {
        "heading": "Say goodbye to chaos and hello to order with <strong class='app-name'>bring.order2chaos</strong>",
        "subheading": "Our app helps you to <strong class='app-slogan'>keep track of your stuff</strong> in one convenient place with stunning features, and it works wherever you are – as a mobile and web app.",
      },
      {
        "heading": "Locations",
        "instructions": [
          "Create every drawer by itself or just room by room – however you like it",
          "Nest locations into each other to create a hierarchy – just like they are in reality",
          "The preset locations '~' and '?' represent the overarching location of all other locations and the location for all items whose whereabouts are unknown",
        ],
        "subheading": "Create locations to model your space",
      },
      {
        "heading": "Items",
        "instructions": [
          "Add a name, category, location, and an optional note to new items",
          "Edit, move, declare as lost, or delete items with a single click",
          "Assign barcodes to items to find them faster",
          "Use advanced image analysis for quicker item creation",
        ],
        "subheading": "Create items using our smart features",
      },
      {
        "heading": "Barcodes",
        "instructions": [
          "Scan a barcode in over 10 supported formats (only on mobile)",
          "If an item or location is assigned to it, it'll be searched for automatically",
          "Otherwise, you can create a new item or location on the spot – either it was on there already or you printed it",
          "If the barcode is of a product, its name will be suggested to you",
        ],
        "subheading": "Leverage barcodes for faster use",
      },
      {
        "heading": "Image analysis",
        "instructions": [
          "Attach an image and its contents will be analyzed to suggest name fragments",
          "You can use those name fragments to dynamically build the item's name instead of typing it manually",
          "Both the text visible in the image as well as the objects are used to suggest names",
        ],
        "subheading": "Let smart algorithms do the work for you",
      },
      {
        "heading": "Inventories",
        "instructions": [
          "Create inventories to organize your items into groups or at different physical locations",
          "Invite your flatmates or family members to maintain the inventories of your choice together with you – be aware that this feature requires a Plus subscription (only one account requires it)",
        ],
        "subheading": "Share your inventories with others",
      },
      {
        "heading": "Search, filter, and sort",
        "instructions": [
          "Use filters and sorting options to find items as fast as possible",
          "The search function searches through all item and location information, including barcodes and image information",
          "Whenever you see the barcode button, a barcode can be scanned instead of manually entering information",
        ],
        "subheading": "Find items blazingly fast",
      },
    ],
    "prompt": {
      "confirmDataExport": "Do you really want to export your data as a TSV file?",
      "createBarcodeConfirm": "Do you want to register the barcode <code>{barcodeContent}</code>?",
      "declareItemLostConfirm": "Do you really want to declare this item as being lost?",
      "deleteAccountConfirm": "Do you really want to delete your account permanently?",
      "deleteCategoryConfirm": "Do you really want to delete this category?",
      "deleteInventoryConfirm": "Do you really want to delete this inventory?",
      "deleteItemConfirm": "Do you really want to delete this item?",
      "deleteLocationConfirm": "Do you really want to delete this location?",
      "deleteShareConfirm": "Do you really want to delete this inventory share?",
      "manualBarcodeEntryWarning": "The entered barcode is invalid regarding the chosen format!",
      "subscriptionSelectionConfirm": "Would you like to see our subscription plans?",
    },
    "search": {
      "ascending": "Ascending",
      "category": "Category",
      "descending": "Descending",
      "filters": "Filters",
      "item": "Item",
      "location": "Location",
      "query": "Query",
      "queryTooltip": "Searches in all the fields",
      "results": "Results",
      "sortBy": "By",
      "sortDirection": "Direction",
      "sorting": "Sorting",
    },
    "setting": {
      "filterSortingPermanentlyActive": "Filters & Sorting expanded by default",
      "inventorySelection": "Active Inventory",
      "languageSelection": "Language Selection",
    },
    "subscription": {
      "plus": {
        "features": [
          "10000 items",
          "<strong>10000 locations</strong>",
          "<strong>10000 categories</strong>",
          "<strong>10000 barcodes</strong>",
          "<strong>10000 image uploads</strong>",
          "<strong>10 inventories</strong>",
          "<strong>10 inventory shares</strong>",
          "Data export",
        ],
        "title": "Plus",
      },
      "starter": {
        "features": [
          "<strong>10000 items</strong>",
          "<strong>1000 locations</strong>",
          "<strong>1000 categories</strong>",
          "<strong>1000 barcodes</strong>",
          "10 image uploads",
          "1 inventory",
          "0 inventory shares",
          "<strong>Data export</strong>",
        ],
        "title": "Starter",
      },
    },
    "success": {
      "barcode": {
        "association": {
          "item": "Set item search query to the scanned item barcode",
          "location": "Set location filter to the scanned <em>{locationName}</em> location",
        },
      },
      "category": {
        "create": "Successfully created the category",
        "delete": "Successfully deleted the category",
        "edit": "Successfully edited the category",
      },
      "inventory": {
        "changeActive": "Successfully changed the active inventory",
        "create": "Successfully created the inventory",
        "delete": "Successfully deleted the inventory",
        "edit": "Successfully edited the inventory",
      },
      "item": {
        "create": "Successfully created the item",
        "declarelost": "Successfully declared the item as lost",
        "delete": "Successfully deleted the item",
        "edit": "Successfully edited the item",
        "move": "Successfully moved the item",
      },
      "location": {
        "create": "Successfully created the location",
        "delete": "Successfully deleted the location",
        "edit": "Successfully edited the location",
        "move": "Successfully moved the location",
      },
      "share": {
        "create": "Successfully sent the invitation to the inventory in case this user exists",
        "delete": "Successfully deleted the inventory share",
      },
    },
  },
}

export const UNKNOWN_ERROR_KEY = "failure.general.unknownError"

export const languageNameMapping = {
  ar: "العربية الفصحى",
  bn: "বাংলা",
  ca: "Català",
  de: "Deutsch",
  en: "English",
  eo: "Esperanto",
  es: "Español",
  fr: "Français",
  he: "עברית",
  hi: "हिन्दी",
  id: "Bahasa Indonesia",
  ja: "日本語",
  nl: "Nederlands",
  pt: "Português",
  ru: "Русский язык",
  sw: "Kiswahili",
  uk: "українська мова",
  zh: "普通话",
}
