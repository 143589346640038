import * as Sentry from "@sentry/capacitor"
import * as SentryVue from "@sentry/vue"
import {BrowserTracing} from "@sentry/tracing"
import router from "@/router"
import {app} from "@/main"

export const initSentry = () => {
  if (JSON.parse(process.env.VUE_APP_SENTRY_ENABLED as string)) {
    Sentry.init({
        app: app,
        dsn: process.env.VUE_APP_SENTRY_DSN,
        tracesSampleRate: 0.5,
        integrations: [
          new BrowserTracing({
            routingInstrumentation: SentryVue.vueRouterInstrumentation(router),
            tracePropagationTargets: ["localhost", "api.order2chaos.app"],
          }),
        ],
      },
      SentryVue.init,
    )
  }
}
