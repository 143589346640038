
import {
  IonAccordion,
  IonAccordionGroup,
  IonButton,
  IonItem,
  IonLabel,
  IonList,
  IonLoading,
  IonSegment,
  IonSegmentButton,
  IonSpinner,
  IonText,
  toastController,
  useIonRouter,
} from "@ionic/vue"
import {defineComponent} from "vue"
import {CapacitorPurchases, Offering, Package} from "@capgo/capacitor-purchases"
import * as Sentry from "@sentry/capacitor"
import {useAuthenticationStore} from "@/stores/authentication"
import {useSettingStore} from "@/stores/setting"

export default defineComponent({
  components: {
    IonAccordion,
    IonAccordionGroup,
    IonText,
    IonList,
    IonItem,
    IonLabel,
    IonButton,
    IonSegment,
    IonSegmentButton,
    IonLoading,
    IonSpinner,
  },
  data() {
    return {
      period: "monthly",
      offerings: null as { [key: string]: Offering } | null,
      isLoading: false,
    }
  },
  computed: {
    orderedOfferings(): { [key: string]: Offering } | null {
      if (this.offerings) {
        return {
          "starter": this.offerings["starter"],
          "plus": this.offerings["plus"],
        }
      } else {
        return null
      }
    },
  },
  methods: {
    async purchasePackage(offeringPackage: Package) {
      try {
        const offeringPackageJson: string = JSON.stringify(offeringPackage)
        console.log("Attempting to purchase package", offeringPackageJson)
        this.isLoading = true
        await CapacitorPurchases.purchasePackage(JSON.parse(offeringPackageJson) as Package)
        this.isLoading = false
        this.routeAway()
      } catch (e) {
        this.isLoading = false
        console.error("An error occured while attempting to purchase a package", e)
        Sentry.captureException(e)
        await this.presentErrorToast(`${this.$t("failure.subscription.create.generic")}!`)
      }
    },
    async fetchOfferings() {
      try {
        this.offerings = (await CapacitorPurchases.getOfferings()).offerings.all
      } catch (e) {
        console.error("An error occured while attempting to fetch the offerings", e)
        Sentry.captureException(e)
        this.routeAway()
      }
    },
    async presentErrorToast(message: string) {
      const toast = await toastController.create({
        message: message,
        duration: 5560,
        position: "top",
        buttons: [
          {
            text: this.$t("button.ok"),
            role: "cancel",
          },
        ],
        color: "danger",
      })
      await toast.present()
    },
    buildi18nArray(key: string) {
      let index = 0
      let value = this.$t(`${key}[${index}]`)
      const array = []
      while (!value.includes(key)) {
        array.push(value)
        value = this.$t(`${key}[${++index}]`)
      }
      return array
    },
    routeAway() {
      this.ionRouter.replace("/settings")
    },
  },
  async created() {
    if (this.settingStore.isNative) {
      await this.fetchOfferings()
    } else {
      this.routeAway()
    }
  },
  setup() {
    return {
      authStore: useAuthenticationStore(),
      settingStore: useSettingStore(),
      ionRouter: useIonRouter(),
    }
  },
})
