
import {IonCol, IonGrid, IonItem, IonLabel, IonRow, IonSearchbar} from "@ionic/vue"
import {defineComponent, PropType} from "vue"
import CategoryDropdown from "@/components/CategoryDropdown.vue"
import {useSettingStore} from "@/stores/setting"
import {Category} from "@/gql/types"
import {useCategoryStore} from "@/stores/category"

export default defineComponent({
  components: {CategoryDropdown, IonGrid, IonRow, IonCol, IonLabel, IonItem, IonSearchbar},
  emits: ["selected-category"],
  props: {
    categories: {
      required: true,
      type: Array as PropType<Array<Category>>,
    },
    preSelectedCategory: {
      required: false,
      default: null,
      type: Object as PropType<Category>,
    },
    noSidePadding: {
      required: false,
      default: true,
      type: Boolean,
    },
  },
  data() {
    return {
      query: "",
      selectedCategory: null as Category | null,
      searchFocused: false,
    }
  },
  computed: {
    filteredCategories() {
      return this.categories.filter(c => c.name.toLowerCase().indexOf(this.query.toLowerCase()) >= 0)
    },
    searchPlaceholder() {
      const exampleCategory = this.categoryStore.rotatingCategory()
      if (exampleCategory) {
        return `${this.$t("general.forExample")} ${exampleCategory.name}`
      } else {
        return ""
      }
    },
  },
  methods: {
    selectCategory(category: Category) {
      this.selectedCategory = category
      this.$emit("selected-category", category)
    },
    focusSearchbar() {
      this.searchFocused = true
    },
    blurSearchbar() {
      this.searchFocused = false
    },
  },
  watch: {
    filteredCategories: {
      handler(categories) {
        if (categories.length > 0) this.selectedCategory = categories[0]
      },
    },
    preSelectedCategory: {
      immediate: true,
      handler(category) {
        if (category) this.selectCategory(category)
      },
    },
  },
  setup() {
    return {
      categoryStore: useCategoryStore(),
      settingStore: useSettingStore(),
    }
  },
})
