import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-a425c1ae"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_popover = _resolveComponent("ion-popover")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_ion_button, {
      class: "button",
      onClick: _ctx.open
    }, {
      default: _withCtx(() => [
        _createVNode(_component_font_awesome_icon, { icon: "fa-sticky-note" })
      ]),
      _: 1
    }, 8, ["onClick"]),
    _createVNode(_component_ion_popover, {
      "is-open": _ctx.isActive,
      onDidDismiss: _ctx.close
    }, {
      default: _withCtx(() => [
        _createVNode(_component_ion_content, { class: "ion-padding" }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.item.note), 1)
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["is-open", "onDidDismiss"])
  ]))
}