import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_InventoryDropdown = _resolveComponent("InventoryDropdown")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_ion_label, {
      position: _ctx.stackedLabel ? 'stacked' : null
    }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.label), 1)
      ]),
      _: 1
    }, 8, ["position"]),
    _createVNode(_component_InventoryDropdown, {
      inventories: _ctx.inventories,
      "pre-selected-inventory": _ctx.preSelectedInventory,
      onSelectedInventory: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('selected-inventory', $event)))
    }, null, 8, ["inventories", "pre-selected-inventory"])
  ], 64))
}