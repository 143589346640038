import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-13022118"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_img = _resolveComponent("ion-img")!
  const _component_ion_spinner = _resolveComponent("ion-spinner")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_popover = _resolveComponent("ion-popover")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_ion_button, {
      class: "button",
      onClick: _ctx.open
    }, {
      default: _withCtx(() => [
        _createVNode(_component_font_awesome_icon, { icon: "fa-camera" })
      ]),
      _: 1
    }, 8, ["onClick"]),
    _createVNode(_component_ion_popover, {
      "is-open": _ctx.isActive,
      onDidDismiss: _ctx.close
    }, {
      default: _withCtx(() => [
        _createVNode(_component_ion_content, { class: "ion-padding" }, {
          default: _withCtx(() => [
            _createVNode(_component_ion_img, {
              src: _ctx.item.image?.url,
              class: "image",
              onIonImgDidLoad: _cache[0] || (_cache[0] = ($event: any) => (_ctx.isLoading = false))
            }, null, 8, ["src"]),
            (_ctx.isLoading)
              ? (_openBlock(), _createBlock(_component_ion_spinner, { key: 0 }))
              : _createCommentVNode("", true)
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["is-open", "onDidDismiss"])
  ]))
}