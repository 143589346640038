import {defineStore} from "pinia"
import {markRaw, watch} from "vue"
import apolloClient from "@/shared/clients/apollo-client"
import {NetworkStatus, ObservableQuery} from "@apollo/client/core"
import {useInventoryStore} from "@/stores/inventory"
import {useSettingStore} from "@/stores/setting"
import {useLocationStore} from "@/stores/location"
import {Item, Location, NewItem, ParsedBarcode} from "@/gql/types"
import gql from "graphql-tag"
import {convertGraphQlErrorToResponseInfo, getRotatingElement} from "@/shared/helpers"

export const useItemStore = defineStore("item", {
  state: () => ({
    items: undefined as unknown as Item[],
    count: undefined as unknown as number,
    isLoading: false,
    isError: false,
    _observableQuery: undefined as unknown as ObservableQuery<any, any>,
    _inventoryStore: useInventoryStore(),
    _locationStore: useLocationStore(),
    _settingStore: useSettingStore(),
  }),
  getters: {
    isReady: (state) => state.items !== undefined,
  },
  actions: {
    async createItem(newItem: NewItem) {
      await apolloClient.mutate({
        mutation: gql`
            mutation createItem($inventoryId: ID!, $newItem: NewItem!) {
                createItem(inventoryId: $inventoryId, newItem: $newItem) {
                    id
                    name
                    category {
                        id
                        name
                    }
                    location {
                        id
                        name
                        location {
                            id
                            name
                            location {
                                id
                                name
                            }
                        }
                    }
                    barcode {
                        id
                        content
                        format
                        labels
                    }
                    image {
                        id
                        url
                        labels
                    }
                }
            }
        `,
        variables: {
          inventoryId: this._inventoryStore.inventories.active.id,
          newItem: newItem,
        },
      })
      await this.refetchItems()
    },
    async editItem(editedItem: Item) {
      await apolloClient.mutate({
        mutation: gql`
            mutation editItem($itemId: ID!, $newName: String!, $newNote: String, $newCategoryId: ID!, $newBarcodeId: ID, $newImageId: ID) {
                editItem(itemId: $itemId, newName: $newName, newNote: $newNote, newCategoryId: $newCategoryId, newBarcodeId: $newBarcodeId, newImageId: $newImageId)
            }
        `,
        variables: {
          itemId: editedItem.id,
          newName: editedItem.name,
          newNote: editedItem.note,
          newCategoryId: editedItem.category.id,
          newBarcodeId: editedItem.barcode?.id,
          newImageId: editedItem.image?.id,
        },
      })
      await this.refetchItems()
    },
    async moveItem(item: Item, targetLocation: Location) {
      await apolloClient.mutate({
        mutation: gql`
            mutation moveItem($itemId: ID!, $newLocationId: ID!) {
                moveItem(itemId: $itemId, newLocationId: $newLocationId)
            }
        `,
        variables: {
          itemId: item.id,
          newLocationId: targetLocation.id,
        },
      })
      await this.refetchItems()
    },
    async deleteItem(item: Item) {
      await apolloClient.mutate({
        mutation: gql`
            mutation deleteItem($itemId: ID!) {
                deleteItem(itemId: $itemId)
            }
        `,
        variables: {
          itemId: item.id,
        },
      })
      setTimeout(async () => await this.refetchItems(), 1500)
    },
    watchItems() {
      const observableQuery = apolloClient.watchQuery({
        query: gql`
            query getItems(
                $inventoryId: ID!,
                $locationId: ID,
                $recursiveLocation: Boolean! = true,
                $categoryId: ID,
                $query: String!,
                $sortBy: SortBy,
                $sortDirection: SortDirection,
                $offset: Int,
                $limit: Int,
            ) {
                getItems(
                    inventoryId: $inventoryId,
                    locationId: $locationId,
                    recursiveLocation: $recursiveLocation,
                    categoryId: $categoryId,
                    query: $query,
                    sortBy: $sortBy,
                    sortDirection: $sortDirection,
                    offset: $offset,
                    limit: $limit,
                ) {
                    items {
                        id
                        name
                        note
                        category {
                            id
                            name
                        }
                        location {
                            id
                            name
                            barcode {
                                content
                                format
                            }
                            location {
                                id
                                name
                                barcode {
                                    content
                                    format
                                }
                                location {
                                    id
                                    name
                                    barcode {
                                        content
                                        format
                                    }
                                }
                            }
                        }
                        barcode {
                            id
                            content
                            format
                            labels
                        }
                        image {
                            id
                            url
                            labels
                        }
                    }
                    count
                }
            }
        `,
        variables: this.getQueryVariables(),
        fetchPolicy: "cache-and-network",
      })
      observableQuery.subscribe({
        next: ({data, networkStatus}) => {
          const result = data["getItems"]
          this.items = result.items
          this.count = result.count
          switch (networkStatus) {
            case NetworkStatus.loading:
              this.isLoading = true
              break
            case NetworkStatus.ready:
              this.isLoading = false
              this.isError = true
              break
            case NetworkStatus.error:
              this.isLoading = false
              this.isError = true
              break
          }
        },
        error: (e) => convertGraphQlErrorToResponseInfo(e),
      })
      watch(
        this._inventoryStore.inventories,
        () => observableQuery.setVariables(this.getQueryVariables()),
      )
      watch(
        useSettingStore().persistable.search,
        () => observableQuery.setVariables(this.getQueryVariables()),
        {deep: true},
      )
      this._observableQuery = markRaw(observableQuery)
    },
    async findByBarcode(barcode: ParsedBarcode) {
      const response = await apolloClient.query({
        query: gql`
            query findItemsByBarcode($inventoryId: ID!, $barcode: ParsedBarcode!) {
                findItemsByBarcode(inventoryId: $inventoryId, barcode: $barcode) {
                    id
                    name
                    note
                    category {
                        id
                        name
                    }
                    location {
                        id
                        name
                        barcode {
                            content
                            format
                        }
                        location {
                            id
                            name
                            barcode {
                                content
                                format
                            }
                            location {
                                id
                                name
                                barcode {
                                    content
                                    format
                                }
                            }
                        }
                    }
                    barcode {
                        id
                        content
                        format
                        labels
                    }
                    image {
                        id
                        url
                        labels
                    }
                }
            }
        `,
        variables: {
          inventoryId: this._inventoryStore.inventories.active.id,
          barcode: barcode,
        },
        fetchPolicy: "network-only",
      })
      return response.data["findItemsByBarcode"] as Item[]
    },
    async refetchItems() {
      await this._observableQuery?.refetch()
    },
    async fetchMoreItems() {
      await this._observableQuery?.fetchMore({
        variables: {
          offset: this.items?.length ?? 0,
          limit: this._settingStore.persistable.search.limit,
        },
        updateQuery: (previousResult, {fetchMoreResult}) => {
          this.items = [...this.items, ...fetchMoreResult["getItems"].items]
        },
      })
    },
    /**
     * Returns a different item roughly every 17 minutes.
     */
    rotatingItem(): Item {
      return getRotatingElement(this.items)
    },
    getQueryVariables() {
      return {
        inventoryId: this._inventoryStore.inventories.active.id,
        locationId: this._settingStore.persistable.search.lastSelection.location.id,
        categoryId: this._settingStore.persistable.search.lastSelection.category.id,
        query: this._settingStore.persistable.search.query,
        sortBy: this._settingStore.persistable.search.sorting.by,
        sortDirection: this._settingStore.persistable.search.sorting.direction,
        limit: this._settingStore.persistable.search.limit,
      }
    },
  },
})
