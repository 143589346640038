
import {defineComponent, PropType} from "vue"
import AlertAction from "@/components/AlertAction.vue"
import {Location} from "@/gql/types"
import {useLocationStore} from "@/stores/location"
import {convertGraphQlErrorToResponseInfo, createSuccessfulResponseInfo} from "@/shared/helpers"

export default defineComponent({
  components: {AlertAction},
  emits: ["deleted-location"],
  props: {
    location: {
      required: true,
      type: Object as PropType<Location>,
    },
    disabled: {
      required: false,
      default: false,
      type: Boolean,
    },
  },
  computed: {
    buttonSize() {
      return window.innerWidth < 1280 ? "default" : "large"
    },
  },
  methods: {
    async deleteLocation() {
      try {
        await this.locationStore.deleteLocation(this.location)
        return createSuccessfulResponseInfo("success.location.delete")
      } catch (e) {
        return convertGraphQlErrorToResponseInfo(e)
      }
    },
  },
  setup() {
    return {
      locationStore: useLocationStore(),
    }
  },
})
