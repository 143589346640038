
import {IonButton, IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonList} from "@ionic/vue"
import {defineComponent} from "vue"
import {useSettingStore} from "@/stores/setting"

export default defineComponent({
  components: {
    IonCard,
    IonCardHeader,
    IonCardTitle,
    IonCardContent,
    IonList,
    IonButton,
  },
  setup() {
    return {
      settingStore: useSettingStore(),
    }
  },
})
