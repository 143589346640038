import {watch} from "vue"
import {LocationQuery, RouteLocationNormalized} from "vue-router"
import {GetResult, Preferences} from "@capacitor/preferences"
import {ApolloError, ServerError} from "@apollo/client"
import * as Sentry from "@sentry/capacitor"
import {UNKNOWN_ERROR_KEY} from "@/shared/i18n/translations"
import {useSettingStore} from "@/stores/setting"
import {useAuthenticationStore} from "@/stores/authentication"

/**
 * Returns true if the route transition is caused by the Auth0 callback.
 */
export function isAuth0Callback(from: RouteLocationNormalized, to: RouteLocationNormalized): boolean {
  return from.path === to.path && isAuth0CallbackQuery(from.query)
}

export function isAuth0CallbackQuery(locationQuery: LocationQuery): boolean {
  const queryKeys: string[] = Object.keys(locationQuery)
  return queryKeys.includes("state") && (queryKeys.includes("code") || queryKeys.includes("error"))
}

export function getPreferredLanguages(): string[] {
  return navigator.languages.map(l => l.split("-")[0])
}

export function isGraphQlDataException(apolloError: ApolloError): boolean {
  const classification = apolloError?.graphQLErrors?.at(0)?.extensions?.classification
  return classification == "DataFetchingException"
}

export function convertGraphQlErrorToResponseInfo(error: any): ResponseInfo {
  // If it's not an ApolloError
  if (!(error && "graphQLErrors" in error)) {
    console.error("Unknown error occured during a GraphQL request", error)
    Sentry.captureException(error)
    return {messageKey: UNKNOWN_ERROR_KEY, isError: true} as ResponseInfo
  }

  const apolloError = error as ApolloError
  const errorMessageKey = apolloError?.message
  const serverError = apolloError?.networkError as ServerError
  const serverErrorMessageKey = serverError?.result?.error

  if (apolloError?.networkError) {
    Sentry.captureException(apolloError.networkError)
  } else if ((apolloError?.protocolErrors?.length ?? 0) > 0) {
    apolloError.protocolErrors.forEach(e => Sentry.captureException(e))
  } else if ((apolloError?.clientErrors?.length ?? 0) > 0) {
    apolloError.clientErrors.forEach(e => Sentry.captureException(e))
  }

  if (errorMessageKey?.includes("Failed to fetch")) {
    console.warn("Triggering reload since GraphQL request failed")
    window.location.reload()
  }

  if (serverError?.statusCode === 401) {
    console.warn("Triggering login since GraphQL request failed with status 401")
    ;(async () => await useAuthenticationStore().login())()
  }

  if (serverErrorMessageKey) {
    console.warn("Server Error", serverError)
    return {messageKey: serverErrorMessageKey, isError: true} as ResponseInfo
  }

  if (errorMessageKey && isGraphQlDataException(apolloError)) {
    console.warn("Data Exception", errorMessageKey)
    return {messageKey: errorMessageKey, isError: true} as ResponseInfo
  }

  console.error("Unknown ApolloError", apolloError)
  Sentry.captureException(apolloError)
  return {messageKey: UNKNOWN_ERROR_KEY, isError: true} as ResponseInfo
}

export function createSuccessfulResponseInfo(messageKey: string): ResponseInfo {
  return {messageKey: messageKey, isError: false} as ResponseInfo
}

export function getQueryParams() {
  return new URLSearchParams(window.location.search)
}

export function getQueryParam(key: string) {
  return new URLSearchParams(window.location.search).get(key)
}

export function setQueryParam(key: string, value: string) {
  if (window.history.pushState) {
    const searchParams = new URLSearchParams(window.location.search)
    searchParams.set(key, value)
    const newUrl = window.location.protocol + "//" + window.location.host + window.location.pathname + "?" + searchParams.toString()
    window.history.pushState({path: newUrl}, "", newUrl)
  }
}

export function removeQueryParam(key: string) {
  const url = new URL(window.location.href)
  url.searchParams.delete(key)
  const newUrl = url.href
  window.history.pushState({path: newUrl}, "", newUrl)
}

export async function setupSettingsPersistance() {
  const KEY = "settings"

  const stored: GetResult = await Preferences.get({key: KEY})
  if (stored.value) {
    useSettingStore().persistable = JSON.parse(stored.value)
  }

  watch(
    useSettingStore().persistable,
    async (settings) => {
      await Preferences.set({
        key: KEY,
        value: JSON.stringify(settings),
      })
    },
    {deep: true},
  )
}

/**
 * @see https://stackoverflow.com/a/27884653
 */
export function getHoveredOnElements(): Element[] {
  return Array.from(document.querySelectorAll(":hover"))
}

/**
 * @see https://stackoverflow.com/a/10017343
 */
export function isEllipsisActive(element: HTMLElement): boolean {
  return (element.offsetWidth < element.scrollWidth)
}

/**
 * @see https://stackoverflow.com/a/4550514
 */
export function getRandomElement<E>(array: E[]): E {
  return array[Math.floor(Math.random() * array.length)]
}

/**
 * Returns a different element out of the given array roughly every 17 minutes.
 */
export function getRotatingElement<E>(array: E[]): E {
  const index: number = Math.floor(Date.now() / 1000 / 1000) % array.length
  return array[index]
}

/**
 * @see https://bobbyhadz.com/blog/typescript-get-enum-key-by-value
 */
export function getEnumByValue<T extends object>(enumType: T, value: string): T | null {
  const index: number = Object.values(enumType).indexOf(value as unknown as T)
  if (index >= 0) {
    return Object.keys(enumType)[index] as unknown as T
  } else {
    return null
  }
}

export function getUnixEpoch(): number {
  return Math.round(Date.now() / 1000)
}

export interface ResponseInfo {
  messageKey: string
  isError: boolean
}
