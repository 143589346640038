
import {IonBackButton, IonButtons, IonContent, IonHeader, IonPage, IonTitle, IonToolbar} from "@ionic/vue"
import {defineComponent} from "vue"
import LogoContainer from "@/components/LogoContainer.vue"
import LocationManagementContainer from "@/components/LocationManagementContainer.vue"
import {useSettingStore} from "@/stores/setting"

export default defineComponent({
  components: {
    LocationManagementContainer,
    LogoContainer,
    IonPage,
    IonHeader,
    IonToolbar,
    IonTitle,
    IonContent,
    IonButtons,
    IonBackButton,
  },
  setup() {
    return {
      settingStore: useSettingStore(),
    }
  },
})
