import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, normalizeClass as _normalizeClass } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TooltipBadge = _resolveComponent("TooltipBadge")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([[_ctx.alignInColumn ? 'align-in-column' : 'align-in-row'], "container"])
  }, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.locationStore.getLocationHierarchy(_ctx.location).slice(0, 3), (nestedLocation, index) => {
      return (_openBlock(), _createBlock(_component_TooltipBadge, {
        key: nestedLocation.id,
        color: _ctx.getLocationBadgeColor(index),
        "tooltip-text": nestedLocation.name,
        class: "badge"
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(nestedLocation.name), 1)
        ]),
        _: 2
      }, 1032, ["color", "tooltip-text"]))
    }), 128))
  ], 2))
}