
import {defineComponent} from "vue"
import UserProfile from "@/components/UserProfile.vue"
import SubscriptionInformation from "@/components/SubscriptionInformation.vue"
import ExternalLinks from "@/components/ExternalLinks.vue"
import PreferenceSettings from "@/components/PreferenceSettings.vue"

export default defineComponent({
  components: {
    PreferenceSettings, ExternalLinks, SubscriptionInformation, UserProfile,
  },
})
