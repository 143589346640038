
import {IonButton, IonInput, IonItem, IonLabel, IonList} from "@ionic/vue"
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome"
import {defineComponent, PropType} from "vue"
import EditAction from "@/components/EditAction.vue"
import {Inventory} from "@/gql/types"
import {useInventoryStore} from "@/stores/inventory"
import {convertGraphQlErrorToResponseInfo, createSuccessfulResponseInfo} from "@/shared/helpers"

export default defineComponent({
  components: {
    EditAction,
    IonList,
    IonItem,
    IonLabel,
    IonInput,
    IonButton,
    FontAwesomeIcon,
  },
  emits: ["edited-inventory"],
  props: {
    inventory: {
      required: true,
      type: Object as PropType<Inventory>,
    },
  },
  data() {
    return {
      modalActive: false,
    }
  },
  computed: {
    buttonSize() {
      return window.innerWidth < 1280 ? "default" : "large"
    },
  },
  methods: {
    openModal() {
      this.modalActive = true
    },
    closeModal() {
      this.modalActive = false
    },
    async confirmInventoryEdit(inventory: Inventory) {
      try {
        await this.inventoryStore.editInventory(inventory)
        return createSuccessfulResponseInfo("success.inventory.edit")
      } catch (e) {
        return convertGraphQlErrorToResponseInfo(e)
      } finally {
        this.closeModal()
      }
    },
  },
  setup() {
    return {
      inventoryStore: useInventoryStore(),
    }
  },
})
