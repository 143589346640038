
import AlertAction from "@/components/AlertAction.vue"
import {defineComponent, PropType} from "vue"
import {Item} from "@/gql/types"
import {convertGraphQlErrorToResponseInfo, createSuccessfulResponseInfo} from "@/shared/helpers"
import {useItemStore} from "@/stores/item"

export default defineComponent({
  components: {AlertAction},
  emits: ["deleted-item"],
  props: {
    item: {
      required: true,
      type: Object as PropType<Item>,
    },
  },
  methods: {
    async deleteItem() {
      try {
        await this.itemStore.deleteItem(this.item)
        return createSuccessfulResponseInfo("success.item.delete")
      } catch (e) {
        return convertGraphQlErrorToResponseInfo(e)
      }
    },
  },
  setup() {
    return {
      itemStore: useItemStore(),
    }
  },
})
