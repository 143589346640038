import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-b57aae4c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "image-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_img = _resolveComponent("ion-img")!
  const _component_ion_spinner = _resolveComponent("ion-spinner")!
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_item = _resolveComponent("ion-item")!

  return (_openBlock(), _createBlock(_component_ion_item, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_label, { position: "stacked" }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('entity.image.name')), 1)
        ]),
        _: 1
      }),
      _createElementVNode("div", _hoisted_1, [
        (_ctx.imageSource && !_ctx.isLoading)
          ? (_openBlock(), _createBlock(_component_ion_img, {
              key: 0,
              src: _ctx.imageSource
            }, null, 8, ["src"]))
          : _createCommentVNode("", true),
        (_ctx.isLoading)
          ? (_openBlock(), _createBlock(_component_ion_spinner, { key: 1 }))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", {
        slot: "end",
        style: _normalizeStyle({'justify-content': _ctx.imageSource ? 'center' : 'flex-start'}),
        class: "button-container"
      }, [
        _createVNode(_component_ion_button, {
          color: "primary",
          onClick: _ctx.selectAndUploadImage
        }, {
          default: _withCtx(() => [
            _createVNode(_component_font_awesome_icon, { icon: "fa-camera" })
          ]),
          _: 1
        }, 8, ["onClick"]),
        (_ctx.imageSource)
          ? (_openBlock(), _createBlock(_component_ion_button, {
              key: 0,
              color: "danger",
              onClick: _ctx.deleteCurrentImage
            }, {
              default: _withCtx(() => [
                _createVNode(_component_font_awesome_icon, { icon: "fa-trash" })
              ]),
              _: 1
            }, 8, ["onClick"]))
          : _createCommentVNode("", true)
      ], 4)
    ]),
    _: 1
  }))
}