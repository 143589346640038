
import AlertAction from "@/components/AlertAction.vue"
import {defineComponent, PropType} from "vue"
import {useLocationStore} from "@/stores/location"
import {useItemStore} from "@/stores/item"
import {Item} from "@/gql/types"
import {convertGraphQlErrorToResponseInfo, createSuccessfulResponseInfo} from "@/shared/helpers"

export default defineComponent({
  components: {AlertAction},
  emits: ["declared-item-lost"],
  props: {
    item: {
      required: true,
      type: Object as PropType<Item>,
    },
    disabled: {
      required: false,
      default: false,
      type: Boolean,
    },
  },
  methods: {
    async declareItemAsLost() {
      try {
        await this.itemStore.moveItem(this.item, this.locationStore.lostLocation)
        return createSuccessfulResponseInfo("success.item.declarelost")
      } catch (e) {
        return convertGraphQlErrorToResponseInfo(e)
      }
    },
  },
  setup() {
    return {
      itemStore: useItemStore(),
      locationStore: useLocationStore(),
    }
  },
})
