
import {IonLabel} from "@ionic/vue"
import {defineComponent, PropType} from "vue"
import AlertAction from "@/components/AlertAction.vue"
import {useInventoryStore} from "@/stores/inventory"
import {Inventory} from "@/gql/types"
import {convertGraphQlErrorToResponseInfo, createSuccessfulResponseInfo} from "@/shared/helpers"

export default defineComponent({
  components: {AlertAction, IonLabel},
  emits: ["deleted-share"],
  props: {
    inventory: {
      required: true,
      type: Object as PropType<Inventory>,
    },
    sharedWith: {
      required: true,
      type: String,
    },
  },
  methods: {
    async deleteShare() {
      try {
        await this.inventoryStore.deleteInventoryShareWithAccount(this.inventory, this.sharedWith)
        return createSuccessfulResponseInfo("success.share.delete")
      } catch (e) {
        return convertGraphQlErrorToResponseInfo(e)
      }
    },
  },
  setup() {
    return {
      inventoryStore: useInventoryStore(),
    }
  },
})
