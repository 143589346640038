
import {
  IonBackButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  useIonRouter,
} from "@ionic/vue"
import {defineComponent} from "vue"
import {useAccountStore} from "@/stores/account"
import {useSettingStore} from "@/stores/setting"
import LogoContainer from "@/components/LogoContainer.vue"
import SubscriptionSelectionContainer from "@/components/SubscriptionSelectionContainer.vue"

export default defineComponent({
  components: {
    SubscriptionSelectionContainer,
    LogoContainer,
    IonPage,
    IonHeader,
    IonToolbar,
    IonContent,
    IonButtons,
    IonBackButton,
    IonTitle,
  },
  created() {
    if (!this.accountStore.isAllowedToUpgrade) this.router.replace("/settings")
  },
  setup() {
    return {
      accountStore: useAccountStore(),
      settingStore: useSettingStore(),
      router: useIonRouter(),
    }
  },
})
