import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_router_outlet = _resolveComponent("ion-router-outlet")!
  const _component_ion_loading = _resolveComponent("ion-loading")!
  const _component_ion_app = _resolveComponent("ion-app")!

  return (_openBlock(), _createBlock(_component_ion_app, null, {
    default: _withCtx(() => [
      (_ctx.authStore.isReady && _ctx.dataIsReady)
        ? (_openBlock(), _createBlock(_component_ion_router_outlet, { key: 0 }))
        : _createCommentVNode("", true),
      _createVNode(_component_ion_loading, {
        "is-open": (!_ctx.authStore.isReady || !_ctx.dataIsReady) && !_ctx.errorOccurred && !_ctx.backendIsUnavailable,
        message: _ctx.$t('general.loading')
      }, null, 8, ["is-open", "message"])
    ]),
    _: 1
  }))
}