export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Instant: string;
};

export type Barcode = Identifiable & {
  __typename?: "Barcode";
  content: Scalars["String"];
  createdAt: Scalars["Instant"];
  createdByAccountId: Scalars["ID"];
  format: BarcodeFormat;
  id: Scalars["ID"];
  labels: Array<Scalars["String"]>;
};

export enum BarcodeAssociation {
  Item = "ITEM",
  Location = "LOCATION"
}

export enum BarcodeFormat {
  Aztec = "AZTEC",
  Code_39 = "CODE_39",
  Code_93 = "CODE_93",
  Code_128 = "CODE_128",
  DataMatrix = "DATA_MATRIX",
  Ean_8 = "EAN_8",
  Ean_13 = "EAN_13",
  Itf = "ITF",
  Pdf_417 = "PDF_417",
  QrCode = "QR_CODE",
  UpcA = "UPC_A",
  UpcE = "UPC_E"
}

export enum BillingProvider {
  AppleAppStore = "APPLE_APP_STORE",
  GooglePlayStore = "GOOGLE_PLAY_STORE",
  Stripe = "STRIPE"
}

export type Category = Identifiable & {
  __typename?: "Category";
  createdAt: Scalars["Instant"];
  createdByAccountId: Scalars["ID"];
  id: Scalars["ID"];
  modifiedAt: Scalars["ID"];
  modifiedByAccountId: Scalars["ID"];
  name: Scalars["String"];
};

export type Identifiable = {
  createdAt: Scalars["Instant"];
  id: Scalars["ID"];
};

export type Image = Identifiable & {
  __typename?: "Image";
  createdAt: Scalars["Instant"];
  createdByAccountId: Scalars["ID"];
  id: Scalars["ID"];
  labels: Array<Scalars["String"]>;
  url: Scalars["String"];
};

export type Inventory = Identifiable & {
  __typename?: "Inventory";
  createdAt: Scalars["Instant"];
  id: Scalars["ID"];
  name: Scalars["String"];
  ownedByAccountId: Scalars["ID"];
};

export type Item = Identifiable & {
  __typename?: "Item";
  barcode?: Maybe<Barcode>;
  barcodeId?: Maybe<Scalars["ID"]>;
  category: Category;
  categoryId: Scalars["ID"];
  createdAt: Scalars["Instant"];
  createdByAccountId: Scalars["ID"];
  id: Scalars["ID"];
  image?: Maybe<Image>;
  imageId?: Maybe<Scalars["ID"]>;
  location: Location;
  locationId: Scalars["ID"];
  modifiedAt: Scalars["ID"];
  modifiedByAccountId: Scalars["ID"];
  name: Scalars["String"];
  note?: Maybe<Scalars["String"]>;
};

export type ItemQueryResult = {
  __typename?: "ItemQueryResult";
  count: Scalars["Int"];
  items: Array<Item>;
};

export type Location = Identifiable & {
  __typename?: "Location";
  barcode?: Maybe<Barcode>;
  barcodeId?: Maybe<Scalars["ID"]>;
  createdAt: Scalars["Instant"];
  createdByAccountId: Scalars["ID"];
  id: Scalars["ID"];
  location?: Maybe<Location>;
  locationId?: Maybe<Scalars["ID"]>;
  modifiedAt: Scalars["ID"];
  modifiedByAccountId: Scalars["ID"];
  name: Scalars["String"];
};

export type Mutation = {
  __typename?: "Mutation";
  assignItemBarcode: Scalars["Boolean"];
  assignLocationBarcode: Scalars["Boolean"];
  createBarcode: Barcode;
  createCategory: Category;
  createImage: Image;
  createInventory: Inventory;
  createInventoryShareWithAccount: Scalars["Boolean"];
  createItem: Item;
  createLocation: Location;
  deleteAccount: Scalars["Boolean"];
  deleteCategory: Scalars["Boolean"];
  deleteInventory: Scalars["Boolean"];
  deleteInventoryShareWithAccount: Scalars["Boolean"];
  deleteItem: Scalars["Boolean"];
  deleteLocation: Scalars["Boolean"];
  editCategory: Scalars["Boolean"];
  editInventory: Scalars["Boolean"];
  editItem: Scalars["Boolean"];
  editLocation: Scalars["Boolean"];
  moveItem: Scalars["Boolean"];
  moveLocation: Scalars["Boolean"];
};


export type MutationAssignItemBarcodeArgs = {
  itemId: Scalars["ID"];
  newBarcode: ParsedBarcode;
};


export type MutationAssignLocationBarcodeArgs = {
  locationId: Scalars["ID"];
  newBarcode: ParsedBarcode;
};


export type MutationCreateBarcodeArgs = {
  barcode: ParsedBarcode;
  inventoryId: Scalars["ID"];
};


export type MutationCreateCategoryArgs = {
  inventoryId: Scalars["ID"];
  newCategory: NewCategory;
};


export type MutationCreateImageArgs = {
  dataUri: Scalars["String"];
  inventoryId: Scalars["ID"];
};


export type MutationCreateInventoryArgs = {
  newInventory: NewInventory;
};


export type MutationCreateInventoryShareWithAccountArgs = {
  accountEmail: Scalars["String"];
  inventoryId: Scalars["ID"];
};


export type MutationCreateItemArgs = {
  inventoryId: Scalars["ID"];
  newItem: NewItem;
};


export type MutationCreateLocationArgs = {
  inventoryId: Scalars["ID"];
  newLocation: NewLocation;
};


export type MutationDeleteCategoryArgs = {
  categoryId: Scalars["ID"];
};


export type MutationDeleteInventoryArgs = {
  inventoryId: Scalars["ID"];
};


export type MutationDeleteInventoryShareWithAccountArgs = {
  accountEmail: Scalars["String"];
  inventoryId: Scalars["ID"];
};


export type MutationDeleteItemArgs = {
  itemId: Scalars["ID"];
};


export type MutationDeleteLocationArgs = {
  locationId: Scalars["ID"];
};


export type MutationEditCategoryArgs = {
  categoryId: Scalars["ID"];
  newName: Scalars["String"];
};


export type MutationEditInventoryArgs = {
  inventoryId: Scalars["ID"];
  newName: Scalars["String"];
};


export type MutationEditItemArgs = {
  itemId: Scalars["ID"];
  newBarcodeId?: InputMaybe<Scalars["ID"]>;
  newCategoryId: Scalars["ID"];
  newImageId?: InputMaybe<Scalars["ID"]>;
  newName: Scalars["String"];
  newNote?: InputMaybe<Scalars["String"]>;
};


export type MutationEditLocationArgs = {
  locationId: Scalars["ID"];
  newName: Scalars["String"];
};


export type MutationMoveItemArgs = {
  itemId: Scalars["ID"];
  newLocationId: Scalars["ID"];
};


export type MutationMoveLocationArgs = {
  locationId: Scalars["ID"];
  newLocationId: Scalars["ID"];
};

export type NewCategory = {
  name: Scalars["String"];
};

export type NewInventory = {
  name: Scalars["String"];
};

export type NewItem = {
  barcodeId?: InputMaybe<Scalars["ID"]>;
  categoryId: Scalars["ID"];
  imageId?: InputMaybe<Scalars["ID"]>;
  locationId: Scalars["ID"];
  name: Scalars["String"];
  note?: InputMaybe<Scalars["String"]>;
};

export type NewLocation = {
  barcode?: InputMaybe<ParsedBarcode>;
  locationId: Scalars["ID"];
  name: Scalars["String"];
};

export type ParsedBarcode = {
  content: Scalars["String"];
  format: BarcodeFormat;
};

export type Query = {
  __typename?: "Query";
  findBarcodeAssociation?: Maybe<BarcodeAssociation>;
  findItemsByBarcode: Array<Item>;
  findLocationByBarcode?: Maybe<Location>;
  getCategories: Array<Category>;
  getDataExport: Scalars["String"];
  getImage: Image;
  getInventories: Array<Inventory>;
  getItems?: Maybe<ItemQueryResult>;
  getLocations: Array<Location>;
  getSharedWithAccounts: Array<Scalars["String"]>;
  getSubscription: Subscription;
};


export type QueryFindBarcodeAssociationArgs = {
  barcode: ParsedBarcode;
  inventoryId: Scalars["ID"];
};


export type QueryFindItemsByBarcodeArgs = {
  barcode: ParsedBarcode;
  inventoryId: Scalars["ID"];
};


export type QueryFindLocationByBarcodeArgs = {
  barcode: ParsedBarcode;
  inventoryId: Scalars["ID"];
};


export type QueryGetCategoriesArgs = {
  inventoryId: Scalars["ID"];
};


export type QueryGetImageArgs = {
  imageId: Scalars["ID"];
};


export type QueryGetItemsArgs = {
  categoryId?: InputMaybe<Scalars["ID"]>;
  inventoryId: Scalars["ID"];
  limit?: InputMaybe<Scalars["Int"]>;
  locationId?: InputMaybe<Scalars["ID"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  query?: Scalars["String"];
  recursiveLocation?: Scalars["Boolean"];
  sortBy?: InputMaybe<SortBy>;
  sortDirection?: InputMaybe<SortDirection>;
};


export type QueryGetLocationsArgs = {
  inventoryId: Scalars["ID"];
};


export type QueryGetSharedWithAccountsArgs = {
  inventoryId: Scalars["ID"];
};

export enum SortBy {
  Category = "CATEGORY",
  Item = "ITEM",
  Location = "LOCATION"
}

export enum SortDirection {
  Ascending = "ASCENDING",
  Descending = "DESCENDING"
}

export type Subscription = {
  __typename?: "Subscription";
  accountId: Scalars["ID"];
  billingProvider?: Maybe<BillingProvider>;
  tier: SubscriptionTier;
  validUntil?: Maybe<Scalars["Instant"]>;
};

export enum SubscriptionTier {
  Free = "FREE",
  Plus = "PLUS",
  Premium = "PREMIUM",
  Starter = "STARTER"
}
