
import {IonButton, IonInput, IonItem, IonLabel, IonList} from "@ionic/vue"
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome"
import {defineComponent, PropType} from "vue"
import EditAction from "@/components/EditAction.vue"
import {Category} from "@/gql/types"
import {useCategoryStore} from "@/stores/category"
import {convertGraphQlErrorToResponseInfo, createSuccessfulResponseInfo} from "@/shared/helpers"

export default defineComponent({
  components: {
    EditAction,
    IonList,
    IonItem,
    IonLabel,
    IonInput,
    IonButton,
    FontAwesomeIcon,
  },
  emits: ["edited-category"],
  props: {
    category: {
      required: true,
      type: Object as PropType<Category>,
    },
    disabled: {
      required: false,
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {
      modalActive: false,
    }
  },
  computed: {
    buttonSize() {
      return window.innerWidth < 1280 ? "default" : "large"
    },
  },
  methods: {
    openModal() {
      this.modalActive = true
    },
    closeModal() {
      this.modalActive = false
    },
    async confirmCategoryEdit(category: Category) {
      try {
        await this.categoryStore.editCategory(category)
        return createSuccessfulResponseInfo("success.category.edit")
      } catch (e) {
        return convertGraphQlErrorToResponseInfo(e)
      } finally {
        this.closeModal()
      }
    },
  },
  setup() {
    return {
      categoryStore: useCategoryStore(),
    }
  },
})
