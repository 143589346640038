
import {IonBadge, popoverController} from "@ionic/vue"
import {defineComponent} from "vue"
import HoverTooltip from "@/components/HoverTooltip.vue"
import {getHoveredOnElements, isEllipsisActive} from "@/shared/helpers"

export default defineComponent({
  components: {IonBadge},
  props: {
    color: {
      required: false,
      type: String,
    },
    tooltipText: {
      required: true,
      type: String,
    },
  },
  data() {
    return {
      tooltip: null as any | null,
      isEllipsisActive: false,
    }
  },
  methods: {
    openTooltip(event: Event) {
      const element = event.target as HTMLElement

      if (isEllipsisActive(element)) {
        this.isEllipsisActive = true
      } else {
        return
      }

      setTimeout(async () => {
        const stillHovering = getHoveredOnElements().includes(element)
        if (stillHovering && !this.tooltip) {
          this.tooltip = await popoverController.create({
            component: HoverTooltip,
            componentProps: {content: this.tooltipText},
            event: event,
            cssClass: "tooltip-popover",
            showBackdrop: false,
          })
          await this.tooltip.present()
        }
      }, 1500)
    },
    async closeTooltip() {
      await this.tooltip?.dismiss()
      this.tooltip = null
    },
  },
})
